/* CSS Reset for body */
body {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  box-sizing: border-box; /* Make box-sizing border-box globally */
}

/* Header styling */
.header {
  position: fixed;
  top: 0; /* Stick to the top */
  left: 0;
  width: 100%;
  flex-wrap: wrap;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 37px; /* Header padding */
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  z-index: 1000;
  gap: 10px;
}

/* Adjust padding when scrolled */
.scrolled {
  padding: 12px 30px; /* Smaller padding on scroll */
  background-color: #3b5a91; /* Background color on scroll */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Shadow on scroll */
}

/* Header spacer to push down content */
.header-spacer {
  height: 80px; /* Set to the same height as the header with default padding */
}

.scrolled + .header-spacer {
  height: 60px; /* Adjust if header shrinks when scrolled */
}

/* Main content styling */
.main-content {
  padding: 20px; /* Example padding for main content */
}

/* Logo styles */
.logo {
  width: 214px;
  aspect-ratio: 4.37;
  object-fit: contain;
  transition: transform 0.2s ease, opacity 0.2s ease, filter 0.2s ease;
}

/* Shrink logo when scrolled */
.scrolled .logo {
  width: 150px;
  filter: brightness(0) invert(1);
}

/* Logo hover effect */
.logo:hover {
  transform: scale(1.02);
  opacity: 0.9;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

/* Navigation menu styles */
.navigation ul {
  display: flex;
  list-style-type: none;
  gap: 40px;
  color: #1e1e1e;
  font: 400 18px Inter, sans-serif;
  padding: 0;
  margin: 0;
  transition: color 0.3s ease;
}

.scrolled .navigation ul {
  color: #fff;
}

/* Link styles */
.navigation ul li a {
  text-decoration: none;
  color: inherit;
  position: relative;
  padding: 5px 0;
  transition: color 0.3s ease, transform 0.3s ease;
}

/* Underline effect on hover */
.navigation ul li a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #3b5a91;
  transition: width 0.3s ease;
}

/* Hover effect */
.navigation ul li a:hover {
  color: #3b5a91;
  transform: translateY(-2px);
}

.navigation ul li a:hover::after {
  width: 100%;
}

/* Change link color when scrolled */
.scrolled .navigation ul li a {
  color: #fff;
}

.scrolled .navigation ul li a:hover {
  color: #f0f0f0;
}

.scrolled .navigation ul li a::after {
  background-color: #fff;
}

/* Contact information container */
.contactInfo {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Phone number styles */
.phoneNumber {
  display: flex;
  align-items: center;
  gap: 9px;
  color: #3b5a91;
  font: 600 18px Inter, sans-serif;
  transition: color 0.3s ease;
}

.scrolled .phoneNumber {
  color: #fff;
}

/* Contact button styles */
.contactButton {
  border-radius: 64px;
  background-color: #3b5a91;
  display: flex;
  align-items: center;
  gap: 33px;
  color: #fff;
  padding: 5px 18px;
  font: 700 20px/4px "Darker Grotesque", sans-serif;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  overflow: hidden;
  position: relative;
  text-decoration: none !important;
}

.contactButton:hover {
  background-color: #2c4770;
}

.scrolled .contactButton {
  background-color: #fff;
  color: #3b5a91;
}

.buttonIcon {
  width: 39px;
  height: 39px;
  transition: transform 0.3s ease;
}

.contactButton:hover .buttonIcon {
  transform: translateX(10px);
}

.scrolled .buttonIcon {
  width: 30px;
  height: 30px;
}

.scrolled .phoneIcon{
  filter: brightness(0) invert(1);
}

/* Adjust navigation and contact info for intermediate screen sizes */
@media (max-width: 1130px) and (min-width: 991px) {
  .navigation ul {
    margin-left: 30px;
    gap: 15px; /* Adjust gap for smaller screens */
  }

  .contactInfo {
    flex: 1 1 auto; /* Adjust flexbox behavior to avoid overlap */
    justify-content: flex-end; /* Keep contact info aligned to the right */
    margin-top: 10px; /* Space between navigation and contact info */
  }

  .contactButton {
    font-size: 18px; /* Adjust font size for smaller screens */
    gap: 15px;
    padding: 5px 12px; /* Reduce padding for smaller buttons */
  }
}

/* Media queries for responsive design */
@media (max-width: 991px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px; /* Keep padding for mobile layout */
  }
  .navigation ul {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .contactInfo {
    margin-top: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 600px) {
  .header {
    padding: 0px; /* Adjust padding for smaller screens */
  }
  .navigation ul {
    gap: 10px;
  }
  .contactButton {
    font-size: 16px;
    gap: 20px;
  }
  .buttonIcon {
    width: 30px;
    height: 30px;
  }
}
