.servicePage {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .tailoredService {
    position: relative;
    color: #1e1e1e;
    text-align: center;
    font: 400 60px/72px Montserrat, sans-serif;
    margin: 80px auto;
    max-width: 68%;
    padding: 60px 40px;
    z-index: 2;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .tailoredService::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    height: 500px;
    background: url('../../Assets/Gradients/CareersGradientVector.png') no-repeat center center;
    background-size: contain;
    opacity: 0.8;
    z-index: 1;
    border-radius: 120px;
    transition: opacity 0.3s ease;
  }
  
  .tailoredService:hover {
    transform: scale(1.02);
    opacity: 0.95;
  }
  
  .tailoredService:hover::before {
    opacity: 1;
  }
  
  @media (max-width: 991px) {
    .tailoredService {
      max-width: 100%;
      margin: 40px auto;
      font-size: 40px;
      line-height: 53px;
      padding: 40px 20px;
    }
    
    .tailoredService::before {
      width: 500px;
      height: 400px;
      border-radius: 80px;
    }
  }
  
  
  
  @media (max-width: 991px) {
    .tailoredService {
      margin: 40px auto;
      font-size: 40px;
      line-height: 53px;
    }
  }