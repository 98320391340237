@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: 'Inter', sans-serif; 

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CSS Reset for body */
body {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  box-sizing: border-box; /* Make box-sizing border-box globally */
}

/* Header styling */
.Header_header__1CGKd {
  position: fixed;
  top: 0; /* Stick to the top */
  left: 0;
  width: 100%;
  flex-wrap: wrap;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 37px; /* Header padding */
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  z-index: 1000;
  grid-gap: 10px;
  gap: 10px;
}

/* Adjust padding when scrolled */
.Header_scrolled__keg9l {
  padding: 12px 30px; /* Smaller padding on scroll */
  background-color: #3b5a91; /* Background color on scroll */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Shadow on scroll */
}

/* Header spacer to push down content */
.Header_header-spacer__1gbiW {
  height: 80px; /* Set to the same height as the header with default padding */
}

.Header_scrolled__keg9l + .Header_header-spacer__1gbiW {
  height: 60px; /* Adjust if header shrinks when scrolled */
}

/* Main content styling */
.Header_main-content__Hb1z- {
  padding: 20px; /* Example padding for main content */
}

/* Logo styles */
.Header_logo__ffv_d {
  width: 214px;
  aspect-ratio: 4.37;
  object-fit: contain;
  transition: transform 0.2s ease, opacity 0.2s ease, filter 0.2s ease;
}

/* Shrink logo when scrolled */
.Header_scrolled__keg9l .Header_logo__ffv_d {
  width: 150px;
  filter: brightness(0) invert(1);
}

/* Logo hover effect */
.Header_logo__ffv_d:hover {
  transform: scale(1.02);
  opacity: 0.9;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

/* Navigation menu styles */
.Header_navigation__2zhpG ul {
  display: flex;
  list-style-type: none;
  grid-gap: 40px;
  gap: 40px;
  color: #1e1e1e;
  font: 400 18px Inter, sans-serif;
  padding: 0;
  margin: 0;
  transition: color 0.3s ease;
}

.Header_scrolled__keg9l .Header_navigation__2zhpG ul {
  color: #fff;
}

/* Link styles */
.Header_navigation__2zhpG ul li a {
  text-decoration: none;
  color: inherit;
  position: relative;
  padding: 5px 0;
  transition: color 0.3s ease, transform 0.3s ease;
}

/* Underline effect on hover */
.Header_navigation__2zhpG ul li a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #3b5a91;
  transition: width 0.3s ease;
}

/* Hover effect */
.Header_navigation__2zhpG ul li a:hover {
  color: #3b5a91;
  transform: translateY(-2px);
}

.Header_navigation__2zhpG ul li a:hover::after {
  width: 100%;
}

/* Change link color when scrolled */
.Header_scrolled__keg9l .Header_navigation__2zhpG ul li a {
  color: #fff;
}

.Header_scrolled__keg9l .Header_navigation__2zhpG ul li a:hover {
  color: #f0f0f0;
}

.Header_scrolled__keg9l .Header_navigation__2zhpG ul li a::after {
  background-color: #fff;
}

/* Contact information container */
.Header_contactInfo__O1JAv {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

/* Phone number styles */
.Header_phoneNumber__13ge- {
  display: flex;
  align-items: center;
  grid-gap: 9px;
  gap: 9px;
  color: #3b5a91;
  font: 600 18px Inter, sans-serif;
  transition: color 0.3s ease;
}

.Header_scrolled__keg9l .Header_phoneNumber__13ge- {
  color: #fff;
}

/* Contact button styles */
.Header_contactButton__3hS9s {
  border-radius: 64px;
  background-color: #3b5a91;
  display: flex;
  align-items: center;
  grid-gap: 33px;
  gap: 33px;
  color: #fff;
  padding: 5px 18px;
  font: 700 20px/4px "Darker Grotesque", sans-serif;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  overflow: hidden;
  position: relative;
  text-decoration: none !important;
}

.Header_contactButton__3hS9s:hover {
  background-color: #2c4770;
}

.Header_scrolled__keg9l .Header_contactButton__3hS9s {
  background-color: #fff;
  color: #3b5a91;
}

.Header_buttonIcon__3Pz_6 {
  width: 39px;
  height: 39px;
  transition: transform 0.3s ease;
}

.Header_contactButton__3hS9s:hover .Header_buttonIcon__3Pz_6 {
  transform: translateX(10px);
}

.Header_scrolled__keg9l .Header_buttonIcon__3Pz_6 {
  width: 30px;
  height: 30px;
}

.Header_scrolled__keg9l .Header_phoneIcon__2aVos{
  filter: brightness(0) invert(1);
}

/* Adjust navigation and contact info for intermediate screen sizes */
@media (max-width: 1130px) and (min-width: 991px) {
  .Header_navigation__2zhpG ul {
    margin-left: 30px;
    grid-gap: 15px;
    gap: 15px; /* Adjust gap for smaller screens */
  }

  .Header_contactInfo__O1JAv {
    flex: 1 1 auto; /* Adjust flexbox behavior to avoid overlap */
    justify-content: flex-end; /* Keep contact info aligned to the right */
    margin-top: 10px; /* Space between navigation and contact info */
  }

  .Header_contactButton__3hS9s {
    font-size: 18px; /* Adjust font size for smaller screens */
    grid-gap: 15px;
    gap: 15px;
    padding: 5px 12px; /* Reduce padding for smaller buttons */
  }
}

/* Media queries for responsive design */
@media (max-width: 991px) {
  .Header_header__1CGKd {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px; /* Keep padding for mobile layout */
  }
  .Header_navigation__2zhpG ul {
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    align-items: flex-start;
  }
  .Header_contactInfo__O1JAv {
    margin-top: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 600px) {
  .Header_header__1CGKd {
    padding: 0px; /* Adjust padding for smaller screens */
  }
  .Header_navigation__2zhpG ul {
    grid-gap: 10px;
    gap: 10px;
  }
  .Header_contactButton__3hS9s {
    font-size: 16px;
    grid-gap: 20px;
    gap: 20px;
  }
  .Header_buttonIcon__3Pz_6 {
    width: 30px;
    height: 30px;
  }
}

/* Mobile Header styling */
.Headermobile_header__n8Zi1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px; /* Reduced padding for better fit on smaller screens */
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Montserrat', sans-serif;
}

/* Adjust header background color on scroll */
.Headermobile_scrolled__2ejPq {
    background-color: #3b5a91;
    transition: background-color 0.3s ease;
}

body {
    padding-top: 50px; /* Adjust as needed */
}

/* Container to hold logo and hamburger icon */
.Headermobile_logoAndHamburger__1qWTx {
    display: flex;
    align-items: center;
}

.Headermobile_hamburgerIcon__3bs2p {
    font-size: 32px;
    color: #3b5a91;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.Headermobile_scrolled__2ejPq .Headermobile_hamburgerIcon__3bs2p {
    color: #fff;
}

/* Center logo */
.Headermobile_logo__2-f3l {
    width: 130px; /* Smaller size for better fit on mobile */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

/* Expanded mobile menu logo */
.Headermobile_expandedMenuLogo__1UMr_ {
    position: absolute;
    top: 20px;
    left: 15px;
    width: 140px; /* Adjusted for optimal fit */
    cursor: pointer;
    filter: brightness(0) invert(1);
}

/* Mobile full-screen menu */
.Headermobile_mobileMenu__35P_w {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #3b5a91;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 1000;
    padding-top: 40px; /* Padding to avoid overlapping */
}

/* Mobile navigation styling */
.Headermobile_mobileNav__3I7yh {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    grid-gap: 25px;
    gap: 25px;
    font-size: 2rem; /* Adjusted for smaller screens */
}

.Headermobile_mobileNav__3I7yh li {
    margin: 20px 0;
}

/* Close icon */
.Headermobile_closeIcon__3JzCR {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 45px;
    cursor: pointer;
}

/* Contact button in expanded menu */
.Headermobile_contactButton__3qgjG {
    margin-top: 30%; /* Adjusted for more compact layout */
    border-radius: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    color: #3b5a91;
    padding: 6px 15px;
    font: 700 18px/4px "Darker Grotesque", sans-serif;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    overflow: hidden;
    position: relative;
    text-decoration: none !important;
}

.Headermobile_contactButton__3qgjG:hover {
    background-color: #2e4972;
}

.Headermobile_buttonIcon__1Bimu {
    width: 16px;
    height: 16px;
    margin-left: 10px;
}

.Headermobile_phoneNumber__1ZWCT {
    margin-top: 5%; /* Reduced for more compact layout */
    font: 700 18px/4px "Darker Grotesque", sans-serif;
    text-align: center;
}

/* Horizontal line styling */
.Headermobile_horizontalLine__fp8HC {
    width: 70%;
    margin: 30px auto 15px auto;
    border: none;
    border-top: 1px solid #ffffff;
    opacity: 0.6;
}

/* Social Icons styling */
.Headermobile_socialIcons__N4sye {
    display: flex;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 15px;
}

.Headermobile_socialIcons__N4sye img {
    width: 20px; /* Smaller for compact layout */
    height: 20px;
    filter: brightness(0) invert(1);
    transition: transform 0.2s ease;
    cursor: pointer;
}

.Headermobile_socialIcons__N4sye img:hover {
    transform: scale(1.1);
}


.Headermobile_phoneLink__3W5In {
    color: inherit; /* Keep it the same color as surrounding text */
    text-decoration: none;
}

.Headermobile_phoneLink__3W5In:hover {
    text-decoration: underline; /* Optional underline effect on hover */
}

.Footer_footer__3kRR3 {
  background-color: #b7c233;
  padding: 63px 80px;
  max-width: 100vw;
  /*box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1); /* Soft shadow at the top to separate from body */
}

.Footer_footerCurve__23lQY {
  max-width: 100vw;
}

.Footer_footerContent__3w9wc {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
}

.Footer_companyInfo__1RZDU {
  max-width: 300px;
}

.Footer_footerLogo__1UZb9 {
  width: 214px;
  max-width: 100%;
}

.Footer_companyDescription__3nsA2 {
  color: #1e1e1e;
  margin-top: 34px;
  font: 400 18px Inter, sans-serif;
  line-height: 1.5; /* Set line-height to create space between lines */
}

.Footer_footerNav__vm8NG {
  display: flex;
  grid-gap: 55px;
  gap: 55px;
}

.Footer_navColumn__3iSNm h3 {
  color: #1e1e1e;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 13px;
}

.Footer_navColumn__3iSNm ul {
  list-style-type: none;
  padding: 0;
}

.Footer_navColumn__3iSNm li {
  margin-top: 7px;
}

.Footer_navColumn__3iSNm a {
  color: #1e1e1e;
  font: 400 18px Inter, sans-serif;
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth color transition */
}

/* Link hover effect */
.Footer_navColumn__3iSNm a:hover {
  color: #3b5a91; /* Change color on hover */
}

.Footer_hiringBadge__2i2js {
  border-radius: 5px;
  background-color: #1e1e1e;
  color: #c5ce5b;
  font-size: 12px;
  padding: 3px 6px;
  animation: Footer_blink__1ojou 1s infinite; /* Apply blinking animation */
}

/* Keyframes for blinking effect */
@keyframes Footer_blink__1ojou {
  0%, 100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Fully transparent */
  }
}
.Footer_socialConnect__10xYr {
  margin-top: 40px;
}

.Footer_socialTitle__2OxUg {
  color: #1e1e1e;
  font: 400 18px Inter, sans-serif;
  margin-bottom: 16px;
}

.Footer_socialIcons__1YHOT {
  display: flex;
  grid-gap: 28px;
  gap: 28px;
}

.Footer_socialIcon__pSam4 {
  width: 28px;
  height: 28px;
  transition: transform 0.3s ease; /* Smooth transition for scaling */
}

/* Hover effect for social icons */
.Footer_socialIcon__pSam4:hover {
  transform: scale(1.1); /* Slightly increase size on hover */
}


.Footer_copyright__2AbKt {
  color: #1e1e1e;
  margin-top: 23px;
  font: 400 14px Inter, sans-serif;
  text-align: right;
}

@media (max-width: 991px) {
  .Footer_horizontalLine__28T_S {
    width: 100%; /* Full width on smaller screens */
    margin-left: 0;
  }

  .Footer_footerNav__vm8NG {
    
    grid-gap: 10px;
    
    gap: 10px;
  }

}

@media (max-width: 601px) {
  .Footer_footer__3kRR3 {
    padding: 40px 20px;
  }

  .Footer_footerContent__3w9wc {
    flex-direction: column;
  }

  .Footer_footerNav__vm8NG {
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }

  .Footer_socialConnect__10xYr {
    margin-top: 20px;
  }
}

/* Horizontal line above copyright */
.Footer_horizontalLine__28T_S {
  margin-top: 20px;
  width: calc(100% - 50px); /* Adjusts line width to align with "Services" section */
  height: 1px;
  background-color: #1e1e1e;
  margin-left: 10px;
  margin-right: 0px;
}


.HeroSection_heroSection__e_oUR {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  margin-top: 65px;
  flex-wrap: wrap;
  max-width: 100%;
  background-image: url(/static/media/Frame334.b861397f.png); /* Replace with your frame image path */
  background-size: cover; /* Cover the whole container */
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

.HeroSection_textContainer__2uM-8 {
  position: relative;
  z-index: 2;
  max-width: 500px;
  padding: 60px;
}

.HeroSection_imageContainer__3UWSX {
  position: relative;
  z-index: 1;
}

.HeroSection_heroImage__3p5Ah {
  position: relative;
  z-index: 2;
  width: 600px;
  height: auto;
}

.HeroSection_gradientOverlay__3AZMf {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: -40px; /* Adjusts position further to the right */
  margin-bottom: -30px; /* Moves it slightly below */
  width: 300px; /* Adjust to control size */
  height: 300px; /* Match width for a circular shape */
  background-image: url(/static/media/HomepageHerosectionVector.9760e431.png);
  background-size: cover;
  background-position: center;
  border-radius: 50%; /* Makes the shape circular */
  z-index: 1;
}



.HeroSection_textContainer__2uM-8 {
  position: relative;
  z-index: 4;
  max-width: 500px;
  padding: 60px 60px;
  min-height: 50px;
  background-image: url(/static/media/HomePageHeroEllipse6.b0b73d97.png);
  background-size: 80% 80%; /* Adjusts the width and height of the image */
  background-repeat: no-repeat;
  background-position: -30px right; /* Moves the image 50px to the left */
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


/* Separator Styling */
.HeroSection_separatorWrapper__mx2CQ {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.HeroSection_separator__3fZob {
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: cover;
}



.HeroSection_buttonIcon__2kvws{
  transition: background-color 0.3s ease;
}

/* Title */
.HeroSection_title__3vZa- {
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  font-size: 2.5rem;
  color: #1E1E1E;
  margin-bottom: 20px;
  margin-left: 25px;
  text-align: left; /* Left-aligns text */
}

.HeroSection_description__1NvxH {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  color: #1E1E1E;
  line-height: 1.6;
  margin-left: 25px;
  margin-bottom: 30px;
  text-align: left; /* Left-aligns text */
}

.HeroSection_contactButtonContainer__1g2Uj {
  margin-top: 55px;
  margin-left: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Aligns button to the left */
  padding: 5px 20px;
  width: -moz-fit-content;
  width: fit-content; /* Makes button wrap content */
  height: 49px;
  background-color: #3B5A91;
  border-radius: 64px;
  color: #FFFFFF;
  font-family: 'Darker Grotesque', sans-serif;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}

.HeroSection_contactButtonContainer__1g2Uj:hover {
  background-color: #2a4a78;
}

.HeroSection_contactText__205wu {
  color: #FFFFFF;
  margin-right: 10px; /* Space between text and arrow */
}


.HeroSection_contactButtonContainer__1g2Uj:hover .HeroSection_buttonIcon__2kvws {
  transform: translateX(10px);
}


/* Arrow Circle */
.HeroSection_arrowCircle__1NdxX {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39px;
  background: #FFFFFF;
  border-radius: 50%;
  position: relative;
  overflow: hidden; /* Ensures content stays inside the circle */
  transition: transform 0.3s ease; /* Smooth transition for the whole circle */
}

.HeroSection_arrow__3ijof {
  width: 10px;
  height: 10px;
  border: 2px solid #3B5A91;
  border-top: none;
  border-left: none;
  transform: rotate(45deg); /* Keeps arrow pointing right */
  transition: transform 0.3s ease, margin-left 0.3s ease; /* Added margin-left transition */
}

.HeroSection_contactButtonContainer__1g2Uj:hover .HeroSection_arrow__3ijof {
  margin-left: 5px; /* Adds horizontal movement to the right */
  transform: rotate(45deg); /* Keeps rotation the same */
}

.HeroSection_contactButtonContainer__1g2Uj:hover .HeroSection_arrowCircle__1NdxX {
  transform: translateX(5px); /* Moves the circle right on hover */
}



/* Name Tag */
.HeroSection_nameTag__1IdNk {
  position: absolute;
  bottom: 20px; /* Position it below the hero image */
  left: 700px; /* Adjust as necessary */
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.375rem;
  color: #FFFFFF;
  display: flex;
  align-items: flex-end;
  z-index: 5;
}

/* Responsive Design */
@media (max-width: 991px) {
  .HeroSection_heroSection__e_oUR {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    padding-top: -20px;
    grid-gap: 20px;
    gap: 20px; /* Adds consistent spacing */
    background-size: cover; /* Ensures the background scales */
    background-position: center;
  }

  .HeroSection_textContainer__2uM-8 {
    max-width: 90%; /* Adjusted width for tablet */
    padding: 20px;
    
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    text-align: center; /* Center-align text */
  }

  .HeroSection_title__3vZa- {
    font-size: 2rem; /* Adjust font size for better readability */
    line-height: 2.5rem;
    margin-bottom: 15px; /* Reduce bottom margin */
    max-width: 80%;
  }

  .HeroSection_description__1NvxH {
    font-size: 1.125rem; /* Slightly smaller text */
    line-height: 1.5rem;
    margin-bottom: 25px; /* Reduce margin */
    text-align: left; /* Center the description */
  }

  .HeroSection_contactButtonContainer__1g2Uj {
    margin-top: 20px;
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 50px;
    align-self: center; /* Centers the button */
    justify-content: center; /* Centers the content inside */
  }

  .HeroSection_arrowCircle__1NdxX {
    width: 32px;
    height: 32px;
  }

  .HeroSection_arrow__3ijof {
    width: 8px;
    height: 8px;
  }

  .HeroSection_imageContainer__3UWSX {
    max-width: 80%; /* Scale down the image for tablets */
    margin-top: 20px;
  }

  .HeroSection_heroImage__3p5Ah {
    width: 100%; /* Full width */
    height: auto; /* Maintain aspect ratio */
    margin-top: 15px; /* Add margin */
    border-radius: 50px 0 50px 0; /* Adjust border-radius */
  }

  .HeroSection_gradientOverlay__3AZMf {
    width: 550px; /* Slightly reduce size for tablets */
    height: 550px;
    bottom: -20px; /* Adjust position */
    right: -20px;
  }

  .HeroSection_separatorWrapper__mx2CQ {
    margin-top: 20px;
  }

  .HeroSection_separator__3fZob {
    width: 100%; /* Full width */
    height: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Prevent overflow */
    object-fit: cover;
  }
}

/* Additional styles for smaller screens */
@media (max-width: 600px) {
  .HeroSection_heroSection__e_oUR {
    padding: 20px;
    margin-top: 50px;
  }

  .HeroSection_textContainer__2uM-8 {
    position: relative;
    z-index: 2;
    max-width: 100%; /* Adjust width for mobile */
    padding: 20px; /* Reduced padding */
    background-image: url(/static/media/HomePageHeroEllipse6.b0b73d97.png); /* Background image path */
    background-size: 100% auto; /* Ensure the background covers width */
    background-repeat: no-repeat;
    background-position: center -1px; /* Moves the background image up */
    border-radius: 6px; /* Keep the rounded corners */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; /* Align text for mobile */
  }

  .HeroSection_title__3vZa- {
    font-size: 1.8rem; /* Adjust text size for mobile */
    line-height: 2.2rem;
    margin-bottom: 10px;
  }

  .HeroSection_description__1NvxH {
    font-size: 1rem; /* Adjust text size for readability */
    line-height: 1.5rem;
    margin-bottom: 20px;
  }

  .HeroSection_contactButtonContainer__1g2Uj {
    padding: 6px 12px;
    font-size: 16px;
  }

  .HeroSection_arrowCircle__1NdxX {
    width: 28px;
    height: 28px;
  }

  .HeroSection_arrow__3ijof {
    width: 6px;
    height: 6px;
  }

  .HeroSection_heroImage__3p5Ah {
    width: 100%;
    height: auto;
    margin-top: 15px;
    border-radius: 50px 0 50px 0;
  }

  .HeroSection_gradientOverlay__3AZMf {
    width: 180px;
    height: 180px;
    bottom: -20px;
    right: -10px;
    margin-bottom: 10px;
  }

 
}

.AboutSection_aboutSection__3NZny {
  display: flex;
  flex-direction: row;
  width: 90%;
  max-width: 1024px;
  margin: 60px auto;
  grid-gap: 30px;
  gap: 30px;
}

.AboutSection_imageContainer__1D9bn {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  position: relative;
  min-height: 450px;
  overflow: hidden;
  transition: transform 0.3s; /* Smooth hover effect */
}

.AboutSection_imageContainer__1D9bn:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.AboutSection_supervisorImage__3dJN1 {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.AboutSection_supervisorInfo__3ltwa {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(197, 206, 91, 0.6); 
  color: #fff;
  border-radius: 8px;
  padding: 10px 20px;
}

.AboutSection_supervisorName__HeuS8 {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  font-size: 18px;
  font-weight: 600;
}

.AboutSection_supervisorRole__19HF- {
  font-size: 12px;
  font-weight: 300;
  margin-top: 4px;
}

.AboutSection_aboutContent__3lZoL {
  flex: 1 1;
  background-color: #f9f9f9;
  background-image: url(/static/media/AboutusGradient.6aec8d2a.png); /* Add gradient PNG */
  background-repeat: no-repeat; /* Prevents repeating of the gradient */
  background-position: top; /* Positions the gradient at the top */
  border-radius: 10px 10px 90px 10px; /* Rounds only the bottom-right corner */
  padding: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative; /* Ensures proper stacking with other elements */
  overflow: hidden; /* Keeps content within the rounded border */
}

.AboutSection_aboutContent__3lZoL:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2); /* Shadow increases on hover */
}


.AboutSection_aboutTitle__xhDEx {
  font-family: 'Montserrat', sans-serif;
  position: relative;
  font-size: 32px;
  font-weight: 500;
  color: #1e1e1e;
  margin-bottom: 20px;
  background-size: cover;
  background-position: center;
}

.AboutSection_aboutTitle__xhDEx::after {
  content: ' Maintenance';
  display: block; /* Forces a line break after "About" */
  font-size: inherit;
  font-weight: inherit;
}


.AboutSection_aboutDescription__QIh85 {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #4a4a4a;
  margin-bottom: 25px;
}

.AboutSection_exploreButton__2pG1X {
  background-color: #3b5a91;
  color: #fff;
  border-radius: 25px;
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  grid-gap: 8px;
  gap: 8px;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s; /* Smooth hover effects */
}

.AboutSection_exploreButton__2pG1X:hover {
  background-color: #2c4770;
  transform: translateY(-3px); /* Slight upward movement on hover */
}

.AboutSection_buttonIcon__2Dmrz {
  width: 20px;
  transition: transform 0.3s; /* Smooth hover effect */
}

.AboutSection_exploreButton__2pG1X:hover .AboutSection_buttonIcon__2Dmrz {
    transform: translateX(10px);
}

@media (max-width: 991px) {
  /* For Tablets */
  .AboutSection_aboutSection__3NZny {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align items */
    grid-gap: 30px;
    gap: 30px; /* Increase spacing between the sections */
    margin: 40px 20px; /* Reduce horizontal margins for better fit */
  }

  .AboutSection_imageContainer__1D9bn,
  .AboutSection_aboutContent__3lZoL {
    width: 100%; /* Full width for both sections */
    max-width: 600px; /* Limit the width for better readability */
  }

  .AboutSection_imageContainer__1D9bn {
    min-height: 300px; /* Reduce the height for smaller screens */
  }

  .AboutSection_supervisorInfo__3ltwa {
    bottom: 20px; /* Adjust positioning for better visibility */
    right: 20px;
    padding: 8px 16px; /* Slightly reduce padding */
    font-size: 14px; /* Adjust text size */
  }

  .AboutSection_supervisorName__HeuS8 {
    grid-gap: 6px;
    gap: 6px; /* Reduce spacing between icon and text */
    font-size: 16px; /* Smaller font for name */
  }

  .AboutSection_supervisorRole__19HF- {
    font-size: 12px; /* Keep role text compact */
  }

  .AboutSection_aboutContent__3lZoL {
    padding: 20px; /* Reduce padding */
    background-position: top center; /* Center the gradient */
    background-size: 120%; /* Adjust gradient size for better fit */
    border-radius: 20px; /* Simplify the border-radius for cleaner look */
  }

  .AboutSection_aboutTitle__xhDEx {
    font-size: 28px; /* Slightly reduce font size */
    margin-bottom: 15px;
  }

  .AboutSection_aboutDescription__QIh85 {
    font-size: 14px; /* Keep font size readable */
    line-height: 1.5;
    margin-bottom: 20px;
  }

  .AboutSection_exploreButton__2pG1X {
    padding: 10px 20px; /* Adjust button size */
    font-size: 14px; /* Reduce font size for button text */
    grid-gap: 6px;
    gap: 6px; /* Reduce space between text and icon */
  }

  .AboutSection_buttonIcon__2Dmrz {
    width: 16px; /* Reduce icon size */
  }
}

@media (max-width: 600px) {
  /* For Mobile */
  .AboutSection_aboutSection__3NZny {
    margin: 20px 15px; /* Reduce overall margins */
    grid-gap: 20px;
    gap: 20px; /* Maintain spacing between sections */
  }

  .AboutSection_imageContainer__1D9bn {
    min-height: 250px; /* Further reduce image height */
  }

  .AboutSection_supervisorInfo__3ltwa {
    bottom: 10px; /* Adjust for smaller screen */
    right: 10px;
    padding: 6px 12px; /* Reduce padding */
    font-size: 12px; /* Make text smaller */
  }

  .AboutSection_supervisorName__HeuS8 {
    font-size: 14px; /* Adjust name size */
    grid-gap: 4px;
    gap: 4px; /* Reduce gap */
  }

  .AboutSection_supervisorRole__19HF- {
    font-size: 10px; /* Reduce role text size */
  }

  .AboutSection_aboutContent__3lZoL {
    padding: 5px; /* Reduce padding for smaller screens */
    padding-bottom: 20px;
    background-size: cover; /* Stretch gradient to cover area */
    border-radius: 10px; /* Simplify border-radius */
  }

  .AboutSection_aboutTitle__xhDEx {
    font-size: 22px; /* Further reduce font size */
    margin-bottom: 10px; /* Reduce bottom margin */
  }

  .AboutSection_aboutDescription__QIh85 {
    font-size: 12px; /* Adjust text size */
    line-height: 1.4; /* Slightly reduce line height */
  }

  .AboutSection_exploreButton__2pG1X {
    padding: 8px 16px; /* Adjust button padding */
    font-size: 12px; /* Reduce text size */
    grid-gap: 4px;
    gap: 4px; /* Adjust gap */
  }

  .AboutSection_buttonIcon__2Dmrz {
    width: 14px; /* Make icon smaller */
  }
}

.TrustedBySection_trustedBySection__3_xeK {
  text-align: center;
  margin-top: 90px;
  margin-bottom: 70px;
}

.TrustedBySection_trustedBySection__3_xeK h2 {
  color: #1e1e1e;
  font: 500 57px/1 Montserrat, sans-serif;
  margin-bottom: 30px; /* Increased space below the heading */
}

.TrustedBySection_trustedLogo__29Cc0 {
  max-width: 150px;
  height: 100px; /* Set a consistent height for all logos */
  object-fit: contain; /* Ensures the image scales properly */
  margin: 0 auto;
}

.TrustedBySection_logoContainer__1wFA_ {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 120px; /* Ensures consistent vertical alignment */
  padding: 10px; /* Adds spacing around logos for better layout */
}

.TrustedBySection_slick-slide__3Nzvh {
  display: flex !important;
  justify-content: center;
  align-items: center;
}


.TrustedBySection_slick-dots__w56yg {
  margin-top: 10px; /* Reduced space between the slider and dots */
}

.TrustedBySection_slick-dots__w56yg li button:before {
  font-size: 10px;
  color: #1e90ff; /* Alliance logo blue */
}

.TrustedBySection_slick-dots__w56yg li.TrustedBySection_slick-active__1oNJH button:before {
  color: #32cd32; /* Alliance logo green */
}

@media (max-width: 991px) {
  .TrustedBySection_trustedBySection__3_xeK {
    margin-top: 80px;
  }

  .TrustedBySection_trustedBySection__3_xeK h2 {
    font-size: 40px;
  }
}

.TestimonialSection_testimonialSection__2MMuO {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1247px;
  margin: 144px auto 0;
  position: relative;
  grid-gap: 100px;
  gap: 100px; /* Adds space between content and card */
  transition: padding 0.3s ease; /* Adds smooth padding transition */
  padding: 0 20px; /* Adds side padding */
}

.TestimonialSection_testimonialContent__1FJ6s {
  width: 50%;
  position: relative;
  z-index: 1;
  padding-right: 20px; /* Adds right padding */
}

.TestimonialSection_testimonialTitle__22o5Z{
  max-width: 80%;
}

.TestimonialSection_ellipseBackground__3Z9iN {
  position: absolute;
  width: 425px;
  height: 425px;
  left: calc(60% - 350.5px); 
  top: -30px; 
  background: #ECFF9E;
  filter: blur(47.5px);
  border-radius: 50%;
  z-index: -1;
  transition: padding 0.3s ease; /* Adds smooth padding transition */
}

.TestimonialSection_testimonialContent__1FJ6s h2 {
  color: #1e1e1e;
  font: 500 50px/1.2 'Inter', sans-serif; /* Slightly smaller font */
}

.TestimonialSection_testimonialContent__1FJ6s p {
  color: #1e1e1e;
  font: 400 20px/1.5 'Inter', sans-serif; /* Slightly smaller font */
  margin-top: 32px; /* Reduced top margin */
}

.TestimonialSection_testimonialCard__NuzDF {
  width: 50%;
  border-radius: 10px;
  background: linear-gradient(135deg, #e5ff9b, #d4e77e);
  padding: 46px 50px 19px;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, padding 0.3s ease;
}

.TestimonialSection_testimonialCard__NuzDF:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
}

.TestimonialSection_quoteOpenIcon__3t-z5,
.TestimonialSection_quoteCloseIcon__25ftk {
  width: 52px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.TestimonialSection_quoteOpenIcon__3t-z5:hover,
.TestimonialSection_quoteCloseIcon__25ftk:hover {
  opacity: 1;
}

.TestimonialSection_quoteCloseIcon__25ftk {
  position: absolute;
  right: 20px;
  bottom: 100px;
}

blockquote {
  color: #1e1e1e;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 22px; /* Adjusted font size for better readability */
  line-height: 1.6;
  margin: 11px 0 0;
}

.TestimonialSection_testimonialCard__NuzDF footer {
  margin-top: 69px;
}

.TestimonialSection_testimonialCard__NuzDF cite {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #333;
  font-size: 20px;
}

.TestimonialSection_testimonialCard__NuzDF footer p {
  margin-top: 9px;
  font-family: 'Inter', sans-serif;
  color: #777;
  font-size: 18px;
}

.TestimonialSection_testimonialNav__1yWgH {
  display: flex;
  grid-gap: 14px;
  gap: 14px;
  margin-top: 35px;
}

.TestimonialSection_navDot__2lBIh {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #1e1e1e;
  background-color: transparent;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.TestimonialSection_navDot__2lBIh:hover {
  background-color: #c5ce5b;
  transform: scale(1.2);
}

.TestimonialSection_navDot__2lBIh.TestimonialSection_active__38HRv {
  background-color: #1e1e1e;
}

@media (max-width: 991px) {
  .TestimonialSection_testimonialSection__2MMuO {
    flex-direction: column; /* Stack elements vertically */
    margin-top: 20px;
    padding: 30px; /* Adds consistent padding */
    grid-gap: 100px;
    gap: 100px; /* Adds more space between elements */

  }

  .TestimonialSection_testimonialContent__1FJ6s,
  .TestimonialSection_testimonialCard__NuzDF {
    width: 80%; /* Full width for mobile */
  }

  .TestimonialSection_testimonialContent__1FJ6s {
    padding: 0 15px; /* Adds side padding for better spacing */
    margin-left: 30px;
    max-width: 70%;
  }

  .TestimonialSection_testimonialContent__1FJ6s h2 {
    font-size: 32px; /* Reduced font size for mobile */
    line-height: 1.3;
    text-align: center;
    padding: 20px  0px;
    margin-left: 180px;
  }

  .TestimonialSection_testimonialContent__1FJ6s p {
    font-size: 16px; /* Reduced font size for mobile */
    margin-top: -20px; /* Adjusts spacing */
    padding: 10px  0px;
    margin-left: 10px;
    text-align: center;
  }

  .TestimonialSection_ellipseBackground__3Z9iN {
    width: 300px; /* Adjusted size for mobile */
    height: 300px; 
    left: 60%; /* Centers background */
    top: 0; /* Aligns with content */
    transform: translateX(-50%);
  }

  .TestimonialSection_testimonialCard__NuzDF {
    margin-top: 20px; /* Adds spacing between content and card */
    padding: 25px; /* Adjust padding for a more compact layout */
  }

  blockquote {
    font-size: 18px; /* Further reduced font size for better fit */
    line-height: 1.4;
  }

  .TestimonialSection_testimonialCard__NuzDF footer {
    margin-top: 40px; /* Adjusts spacing for mobile */
  }

  .TestimonialSection_testimonialCard__NuzDF cite {
    font-size: 18px; /* Adjusted font size for mobile */
  }

  .TestimonialSection_testimonialCard__NuzDF footer p {
    font-size: 16px; /* Adjusted font size for mobile */
    margin-top: 8px;
  }

  .TestimonialSection_navDot__2lBIh {
    width: 10px; /* Smaller dot size for mobile */
    height: 10px;
    margin-top: 20px; /* Adds more spacing */
  }
}

@media (max-width: 750px) {
  .TestimonialSection_testimonialSection__2MMuO {
    padding: 15px; /* Reduces padding for smaller screens */

  }

  .TestimonialSection_testimonialContent__1FJ6s h2 {
    font-size: 28px; /* Further reduced font size */
margin-left: -10px;

  }

  .TestimonialSection_testimonialContent__1FJ6s p {
    font-size: 14px; /* Smaller font for better fit */
  }

  .TestimonialSection_testimonialCard__NuzDF {
    padding: 20px; /* Adjusted padding */
  }

  blockquote {
    font-size: 16px; /* Further reduced font size */
  }

  .TestimonialSection_navDot__2lBIh {
    width: 8px; /* Smaller dot size */
   
   
    .TestimonialSection_ellipseBackground__3Z9iN {
      width: 300px; /* Adjusted size for mobile */
      height: 300px; 
      left: 50%; /* Centers background */
      top: 0; /* Aligns with content */
      transform: translateX(-50%);
    } height: 8px;
  }
}

.ContactForm_contactSection__2CIk7 {
  border-radius: 50px;
  background-color: #e3ecf6;
  width: 100%;
  max-width: 1360px;
  margin: 160px auto 60px;
  padding: 40px 40px 60px;
  display: flex;
  grid-gap: 40px;
  gap: 40px; /* Increased for better spacing */
  position: relative;
  overflow: hidden;
}

.ContactForm_contactSection__2CIk7::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px; /* Adjust height as necessary */
  background-image: url(/static/media/clippathgroup.88e2e984.png); /* Update this with your actual gradient path */
  background-size: cover; /* Cover the entire area */
  background-position: center; /* Center the gradient */
}

.ContactForm_contactContent__p1HeK {
  width: 55%;
  z-index: 1;
}

.ContactForm_contactTitle__1i_FS {
  color: #1e1e1e;
  font: 500 50px/1.1 'Montserrat', sans-serif;
  margin-bottom: 10px;
  margin-left: 60px; /* Center alignment adjustment */
  max-width: 50%;
  transition: color 0.3s ease;
}

.ContactForm_contactDescription__2xUzd {
  color: #1e1e1e;
  font: 400 20px/1.5 'Inter', sans-serif;
  margin-left: 60px; 
  margin-top: 30px;
  max-width: 65%;
}

.ContactForm_contactForm__2TNdq {
  width: 45%;
  max-width: 440px;
  z-index: 1;
}

.ContactForm_formLabel__1DlwK {
  color: #1e1e1e;
  font: 600 16px/1.4 'Inter', sans-serif;
  display: block;
  margin-bottom: 8px;
}

.ContactForm_formInput__36clW,
.ContactForm_formSelect__39O3P {
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 16px 18px; /* Adjusted padding for consistency */
  border: none;
  font: 400 16px/1.4 'Inter', sans-serif;
  color: #1e1e1e;
  margin-bottom: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;
  appearance: none;
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
}

.ContactForm_formInput__36clW:focus,
.ContactForm_formSelect__39O3P:focus {
  outline: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.ContactForm_selectWrapper__3tQ6H {
  position: relative;
}

.ContactForm_selectWrapper__3tQ6H::after {
  content: '';
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-image: url('https://cdn.builder.io/api/v1/image/assets/TEMP/775bfc1ecbd4e144dc32e1756029be15acf3f1949a56490effb2388a38922369?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f');
  background-size: contain;
  pointer-events: none;
}

.ContactForm_submitButton__229KG {
  border-radius: 64px;
  background-color: #3b5a91;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  color: #ffffff;
  padding: 12px 20px; /* Adjusted padding for better fit */
  font: 700 18px/1 'Darker Grotesque', sans-serif;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.ContactForm_submitButton__229KG:hover {
  background-color: #2d4a74;
  transform: translateY(-2px);
}

.ContactForm_submitButton__229KG:active {
  transform: translateY(0);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}

.ContactForm_buttonIcon__34MYZ {
  width: 20px; /* Reduced for better alignment */
  height: auto;
  transition: transform 0.3s ease;
}

.ContactForm_submitButton__229KG:hover .ContactForm_buttonIcon__34MYZ {
  transform: translateX(4px);
}

.ContactForm_loader__2rKij {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.ContactForm_spinner__12av4 {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #4caf50;
  animation: ContactForm_spin__1VC3t 1s ease infinite;
}

@keyframes ContactForm_spin__1VC3t {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Mobile CSS Improvements */
/* Mobile CSS Fix */



@media (max-width: 991px) and (min-width: 601px) {
  .ContactForm_contactSection__2CIk7 {
    flex-direction: column; /* Stack content and form vertically */
    padding: 40px 20px; /* Equal padding on both sides */
    margin: 40px auto; /* Center the section */
    max-width: 90%; /* Restrict width for better alignment */
    align-items: center; /* Center align the section */
    box-sizing: border-box; /* Include padding in width calculations */
  }

  .ContactForm_contactContent__p1HeK {
    width: 100%; /* Full width for the content */
    text-align: center; /* Center-align text */
  }

  .ContactForm_contactTitle__1i_FS {
    font-size: 36px; /* Slightly smaller font size for mobile */
    margin: 0 auto 20px; /* Center horizontally with spacing below */
    padding: 0 10px; /* Add padding on sides for balance */
    max-width: 80%; /* Restrict width for clean alignment */
    word-wrap: break-word; /* Ensure it wraps nicely */
    text-align: center; /* Center-align title */
  }

  .ContactForm_contactDescription__2xUzd {
    font-size: 18px; /* Larger text for better readability */
    line-height: 1.6; /* Comfortable spacing between lines */
    margin: 0 auto 10px; /* Spacing below description */
    max-width: 85%; /* Restrict width for readability */
    text-align: left; /* Center-align text */
  }

  .ContactForm_contactForm__2TNdq {
    width: 100%; /* Full width for the form */
    max-width: 500px; /* Restrict maximum width */
    margin: 0 auto; /* Center the form */
  }

  .ContactForm_formInput__36clW,
  .ContactForm_formSelect__39O3P {
    width: 100%; /* Full width for inputs */
    padding: 14px 16px; /* Comfortable padding */
    margin-bottom: 16px; /* Spacing between fields */
  }

  .ContactForm_submitButton__229KG {
    width: auto; /* Dynamic width for button */
    padding: 12px 24px; /* Comfortable padding */
    font-size: 18px; /* Match desktop font size */
    margin: 20px auto 0; /* Center the button */
    display: flex; /* Flexbox for alignment */
    justify-content: center; /* Center-align content */
    align-items: center;
    border-radius: 64px; /* Rounded button */
  }

  .ContactForm_buttonIcon__34MYZ {
    width: 20px; /* Maintain icon size */
    height: auto; /* Maintain aspect ratio */
    margin-left: 10px; /* Spacing between text and icon */
  }
}

@media (max-width: 600px) {
  .ContactForm_contactSection__2CIk7 {
    flex-direction: column; /* Stack content and form vertically */
    padding: 30px 15px; /* Equal padding on both sides */
    margin: 20px auto; /* Center the section */
    max-width: 90%; /* Restrict width for better alignment */
    align-items: center; /* Center-align the section */
    box-sizing: border-box; /* Include padding in width calculations */
  }

  .ContactForm_contactContent__p1HeK {
    width: 100%; /* Full width for the content */
    text-align: center; /* Center-align text */
    margin-bottom: 20px; /* Space below the content */
  }

  .ContactForm_contactTitle__1i_FS {
    font-size: 28px; /* Slightly smaller font size for mobile */
    margin: 0 auto 20px; /* Center horizontally with spacing below */
    padding: 0 10px; /* Add padding on sides for balance */
    max-width: 90%; /* Restrict width for clean alignment */
    word-wrap: break-word; /* Ensure it wraps nicely */
    text-align: center; /* Center-align title */
  }

  .ContactForm_contactDescription__2xUzd {
    font-size: 16px; /* Slightly smaller text for readability */
    line-height: 1.5; /* Comfortable spacing between lines */
    margin: 0 auto 20px; /* Spacing below description */
    max-width: 90%; /* Restrict width for readability */
    text-align: left; /* Center-align text */
  }

  .ContactForm_contactForm__2TNdq {
    width: 100%; /* Full width for the form */
    max-width: 400px; /* Restrict maximum width */
    margin: 0 auto; /* Center the form */
  }

  .ContactForm_formInput__36clW,
  .ContactForm_formSelect__39O3P {
    width: 100%; /* Full width for inputs */
    padding: 12px 14px; /* Comfortable padding */
    margin-bottom: 14px; /* Spacing between fields */
  }

  .ContactForm_submitButton__229KG {
    width: auto; /* Dynamic width for button */
    padding: 10px 20px; /* Comfortable padding */
    font-size: 16px; /* Match mobile-friendly font size */
    margin: 20px auto 0; /* Center the button */
    display: flex; /* Flexbox for alignment */
    justify-content: center; /* Center-align content */
    align-items: center;
    border-radius: 50px; /* Rounded button */
  }

  .ContactForm_buttonIcon__34MYZ {
    width: 18px; /* Slightly smaller icon size for mobile */
    height: auto; /* Maintain aspect ratio */
    margin-left: 8px; /* Spacing between text and icon */
  }
}

/* iPad Pro Specific CSS (max-width: 1366px and min-width: 1024px) */
@media (max-width: 1366px) and (min-width: 1024px) {
  .ContactForm_contactSection__2CIk7 {
    flex-direction: row; /* Keep content and form side-by-side */
    padding: 40px 30px; /* Adjust padding for larger screens */
    margin: 40px auto; /* Center-align the section */
    max-width: 90%; /* Restrict width for better alignment */
    align-items: flex-start; /* Align content at the top */
    grid-gap: 40px;
    gap: 40px; /* Consistent spacing between content and form */
    box-sizing: border-box; /* Include padding in width calculations */
  }

  .ContactForm_contactContent__p1HeK {
    width: 50%; /* Allocate half width for content */
    text-align: left; /* Align text to the left */
    padding: 0 20px; /* Add side padding */
    margin: 0; /* Remove extra margins */
  }

  .ContactForm_contactTitle__1i_FS {
    font-size: 42px; /* Slightly larger font size for iPad Pro */
    line-height: 1.3; /* Comfortable line height */
    margin-top: 20px;
    margin: 0 0 20px; /* Add bottom spacing */
    padding: 0; /* Remove additional padding */
    max-width: 90%; /* Utilize full width */
    word-wrap: break-word; /* Ensure it wraps nicely */
  }

  .ContactForm_contactDescription__2xUzd {
    font-size: 20px; /* Slightly larger font size */
    line-height: 1.6; /* Comfortable spacing between lines */
    margin: 20px 0; /* Spacing above and below description */
    max-width: 90%; /* Utilize full width */
    text-align: justify; /* Align text evenly */
  }

  .ContactForm_contactForm__2TNdq {
    width: 50%; /* Allocate half width for the form */
    max-width: 480px; /* Restrict maximum width */
    margin: 0; /* Remove extra margins */
  }

  .ContactForm_formInput__36clW,
  .ContactForm_formSelect__39O3P {
    width: 100%; /* Full width for inputs */
    padding: 16px 18px; /* Comfortable padding */
    margin-bottom: 10px; /* Spacing between fields */
  }

  .ContactForm_submitButton__229KG {
    width: auto; /* Dynamic width for button */
    padding: 14px 26px; /* Adjusted padding */
    font-size: 18px; /* Match desktop font size */
    margin: 20px 0 0; /* Spacing above button */
    display: flex; /* Flexbox for alignment */
    justify-content: center; /* Center-align content */
    align-items: center;
    border-radius: 64px; /* Rounded button */
  }

  .ContactForm_buttonIcon__34MYZ {
    width: 20px; /* Maintain icon size */
    height: auto; /* Maintain aspect ratio */
    margin-left: 10px; /* Spacing between text and icon */
  }
}

.HomePage_homepage__2fA_z {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
.ServiceCard_cardWrapper__t5t3_ {
 
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  position: relative;
  min-height: 300px; /* Reduced min-height for compact layout */
  width: 100%;
  max-width: 450px; /* Reduced max-width for better fit */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease;
  margin: 0px; /* Reduced margin for tighter layout */
}



.ServiceCard_cardWrapper__t5t3_:hover {
  transform: translateY(-5px);
}

.ServiceCard_cardImage__3soNv {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.ServiceCard_cardContent__1CJe- {
  position: relative;
  width: 50%; /* Full width for better content fit */
  border-radius: 0 0 10px 10px; /* Rounded bottom corners */
  background-color: rgba(132, 177, 227, 0.8);
  display: flex;
  flex-direction: column;
  font-family: Inter, sans-serif;
  color: #ffffff;
  padding: 20px; /* Reduced padding for a compact design */
  z-index: 1;
}

.ServiceCard_cardTitle__eAP9_ {
  font-size: 20px; /* Reduced font size */
  font-weight: 600;
  line-height: 1.2;
}

.ServiceCard_cardDescription__3v-tr {
  font-size: 14px; /* Reduced font size */
  font-weight: 400;
  line-height: 1.4;
  margin-top: 1px; /* Reduced margin */
}

.ServiceCard_learnMoreButton__1BffK {
  bottom: 20px; /* Move button 20px up from the border */
  left: 20px;
  position: relative; /* Adjusted to stay inside the card content */
  margin-top: auto; /* Pushes the button to the bottom of the card content */
  align-self: flex-start; /* Aligns button to the left */
  border-radius: 44px;
  background-color: #ffffff;
  color: #3b5a91;
  padding: 10px 20px; /* Adjusted padding for better visibility */
  font: 800 16px/1 "Darker Grotesque", -apple-system, Roboto, Helvetica, sans-serif;
  border: none;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.ServiceCard_learnMoreButton__1BffK:hover {
  background-color: #3b5a91;
  color: #ffffff;
}

.ServiceCard_learnMoreButton__1BffK:active {
  transform: translateY(1px);
}





@media (max-width: 991px) {
  .ServiceCard_cardWrapper__t5t3_ {
    max-width: 100%; /* Let cards take up full width of the column */
    margin: 0; /* Ensure no extra spacing */
    height: auto; /* Adjust height dynamically */
  }

  .ServiceCard_cardContent__1CJe- {
    width: 60%;
    border-radius: 10px;
  }

  .ServiceCard_learnMoreButton__1BffK {
    padding: 8px 10px; /* Reduced padding */
  }
}

@media (max-width: 600px) {
  .ServiceCard_cardWrapper__t5t3_ {
    max-width: 100%;
    margin: 10px 0; /* Adjust margin for mobile view */
  }

  .ServiceCard_cardTitle__eAP9_ {
    font-size: 18px; /* Reduced font size for mobile */
  }

  .ServiceCard_cardDescription__3v-tr {
    font-size: 12px; /* Reduced font size for mobile */
  }

  .ServiceCard_learnMoreButton__1BffK {
    padding: 8px 10px; /* Further reduced padding for mobile */
  }
}

@keyframes StrataCareComponent_fadeIn__14Vg8 {
  from {
    opacity: 0;
    transform: translateY(20px); /* Start a bit lower */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}

.StrataCareComponent_mainContainer__1_dwm {
  background-color: #e3ecf6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 70px 79px;
}

.StrataCareComponent_contentWrapper__323vR {
  max-width: 1278px;
  width: 100%;
}

.StrataCareComponent_headerSection__WO0-I {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  padding: 0 10px;
}

.StrataCareComponent_mainTitle__7ZzVG {
  color: #3b5a91;
  font: 500 60px/63px Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
  max-width: 45%;
  text-align: left;
  align-self: flex-start;
  animation: StrataCareComponent_fadeIn__14Vg8 1s ease forwards; /* Fade-in animation */
  transition: color 0.3s ease; /* Smooth color change on hover */
}

.StrataCareComponent_mainTitle__7ZzVG:hover {
  color: #5f85c4; /* Slight color change on hover */
}

.StrataCareComponent_mainDescription__sC4Do {
  color: #3b5a91;
  font: 400 18px/29px Inter, -apple-system, Roboto, Helvetica, sans-serif;
  max-width: 50%;
  text-align: left;
  align-self: flex-start;
  margin-top: 60px; /* Lowered as specified */
  animation: StrataCareComponent_fadeIn__14Vg8 1.5s ease forwards; /* Fade-in with a slight delay */
  transition: color 0.3s ease; /* Smooth color change on hover */
}

.StrataCareComponent_mainDescription__sC4Do:hover {
  color: #5f85c4; /* Slight color change on hover */
}

.StrataCareComponent_servicesGrid__2D_2e {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  grid-row-gap: 20px;
  row-gap: 20px; /* Space between rows */
  grid-column-gap: 20px;
  column-gap: 20px; /* Space between columns */
  max-width:  100%; /* Center within the container if needed */
  margin-top: 40px;
  justify-items: stretch; /* Make items fill their space */
}

.StrataCareComponent_serviceCard__3umur {
  background-color: #f8f9fa; /* Adjust background color as needed */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add a shadow for depth */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px; /* Adjust padding for consistent spacing */
  transition: transform 0.3s ease; /* Slight animation on hover */
}

/* Tablet View: Two Columns */
@media (max-width: 991px) and (min-width: 601px) {
  .StrataCareComponent_mainContainer__1_dwm {
    padding: 40px 15px; /* Reduce padding for better fit */
  }

  .StrataCareComponent_headerSection__WO0-I {
    flex-direction: column;
    align-items: center; /* Center align for tablet view */
    text-align: center; /* Center the text */
  }

  .StrataCareComponent_mainTitle__7ZzVG,
  .StrataCareComponent_mainDescription__sC4Do {
    max-width: 100%;
    text-align: center; /* Center the text */
    margin-top: 20px; /* Reduce space for tablet */
    margin-bottom: 40px;
  }

  .StrataCareComponent_mainTitle__7ZzVG {
    font-size: 34px; /* Adjust font size for tablet */
    line-height: 42px;
  }

  .StrataCareComponent_mainDescription__sC4Do {
    font-size: 16px; /* Adjust font size for better readability */
    line-height: 24px; /* Adjust line height for tablet */
    margin-top: -30px; 
  }

  .StrataCareComponent_servicesGrid__2D_2e {
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-gap: 20px;
    gap: 20px; /* Consistent spacing */
    max-width: 1200px; /* Center the grid */
    margin: 0 auto; /* Center the grid */
    justify-content: center; /* Ensure items align in the center */
  }

  .StrataCareComponent_serviceCard__3umur {
    max-width: 100%; /* Ensure cards fit the grid */
    margin: 0; /* Remove unnecessary margins */
  }
}

/* Mobile View: One Column */
@media (max-width: 600px) {
  .StrataCareComponent_mainContainer__1_dwm {
    padding: 30px 15px; /* Adjust padding for smaller screens */
  }

  .StrataCareComponent_headerSection__WO0-I {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .StrataCareComponent_mainTitle__7ZzVG {
    font-size: 28px; /* Smaller font size for smaller screens */
    line-height: 36px;
    max-width: 100%;
    margin-bottom: -40px;
  }

  .StrataCareComponent_mainDescription__sC4Do {
    font-size: 14px; /* Smaller font size for readability */
    line-height: 20px; /* Adjust line height for smaller screens */
    max-width: 100%;
  }

  .StrataCareComponent_servicesGrid__2D_2e {
    grid-template-columns: 1fr; /* Single column on mobile */
    grid-gap: 20px;
    gap: 20px; /* Consistent spacing */
    margin-top: 20px; /* Adjust top margin */
    width: 100%; /* Ensure full width */
  }
}


@media (max-width: 1366px) and (min-width: 1024px) {


.StrataCareComponent_mainDescription__sC4Do {
  color: #3b5a91;
  font: 400 23px/29px Inter, -apple-system, Roboto, Helvetica, sans-serif;
  max-width: 50%;
  text-align: left;
  align-self: flex-start;
  margin-top: 60px; /* Lowered as specified */
  animation: StrataCareComponent_fadeIn__14Vg8 1.5s ease forwards; /* Fade-in with a slight delay */
  transition: color 0.3s ease; /* Smooth color change on hover */
}
}

/* Hero Section Styling */
.HeroSection_heroSection__QB63D {
  background-color: #eff1f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 86px 20px;
  position: relative;
}

.HeroSection_heroTitle__162OM {
  color: #1e1e1e;
  text-align: center;
  max-width: 900px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 58px;
  line-height: 1.1;
  padding: 20px;
  z-index: 2;
}

.HeroSection_heroSection__QB63D::before {
  content: '';
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  background: url(/static/media/CareersGradientVector.25493852.png) no-repeat center center;
  background-size: contain;
  z-index: 1;
  opacity: 0.8;
}

a {
  color: inherit;
  text-decoration: none;
}

/* Explore Button Styling */
.HeroSection_exploreButton__-TMNj {
  border-radius: 64px;
  background-color: #3b5a91;
  display: inline-flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px; /* Increased gap for more space */
  color: #fff;
  padding: 12px 24px;
  font: 600 20px "Darker Grotesque", sans-serif;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  justify-content: center; /* Center text and icon in the button */
}

.HeroSection_exploreButton__-TMNj .HeroSection_buttonIcon__3ytEc {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
  margin-left: 10px; /* Pushes the icon to the end */
}

.HeroSection_exploreButton__-TMNj:hover .HeroSection_buttonIcon__3ytEc {
  transform: translateX(8px);
}


/* Image Gallery Styling */
.HeroSection_imageGallery__1Mef7 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px; /* Reduced gap for tighter layout */
  margin-top: 30px;
}

.HeroSection_galleryImage__Pn5Yr {
  width: 100%; /* Use percentage width */
  max-width: 275px; /* Maximum width */
  height: auto; /* Auto height to maintain aspect ratio */
  border-radius: 52px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  object-fit: cover;
  transition: transform 0.3s ease;
}


.HeroSection_galleryImage__Pn5Yr:first-child,
.HeroSection_galleryImage__Pn5Yr:last-child {
  width: 161px;
  height: 200px; /* Maintain aspect ratio */
}

.HeroSection_galleryImage__Pn5Yr:not(:first-child):not(:last-child) {
  width: 161px;
  height: 200px; /* Maintain aspect ratio */
}

/* Media Queries for Responsiveness */
@media (max-width: 991px) {
  .HeroSection_heroTitle__162OM {
    font-size: 48px;
    line-height: 1.2;
    padding: 15px;
  }

  .HeroSection_exploreButton__-TMNj {
    padding: 10px 20px;
    font-size: 18px;
  }

  .HeroSection_heroSection__QB63D::before {
    width: 400px;
    height: 400px;
  }

  .HeroSection_imageGallery__1Mef7 {
    grid-gap: 15px;
    gap: 15px;
  }

  .HeroSection_galleryImage__Pn5Yr {
    width: 180px; /* Adjust slightly for mobile view */
    height: 180px;
  }

  .HeroSection_galleryImage__Pn5Yr:first-child,
  .HeroSection_galleryImage__Pn5Yr:last-child {
    width: 140px;
    height: 180px;
  }

  .HeroSection_galleryImage__Pn5Yr:not(:first-child):not(:last-child) {
    width: 240px;
    height: 180px;
  }

  .HeroSection_galleryImage__Pn5Yr {
    max-width: 200px; /* Reduce max-width for smaller screens */
  }

}

@media (max-width: 767px) {
  .HeroSection_heroTitle__162OM {
    font-size: 36px;
    line-height: 1.2;
  }

  .HeroSection_exploreButton__-TMNj {
    padding: 8px 16px;
    font-size: 16px;
  }

  .HeroSection_heroSection__QB63D::before {
    width: 300px;
    height: 300px;
  }

  .HeroSection_imageGallery__1Mef7 {
    grid-template-columns: repeat(2, 1fr); /* Keep two columns */
    grid-gap: 10px;
    gap: 10px; /* Reduce gap for smaller screens */
    padding: 5px; /* Reduce padding for mobile */
  }

  .HeroSection_galleryImage__Pn5Yr {
    max-width: 160px; /* Slightly smaller for mobile */
    aspect-ratio: 1; /* Maintain square ratio */
    height: auto; /* Ensure consistent sizing */
    object-fit: cover; /* Ensures no distortion */
    border-radius: 12px; /* Keep rounded corners */
  }
}

@media (max-width: 480px) {
  .HeroSection_heroTitle__162OM {
    font-size: 28px;
    line-height: 1.2;
  }

  .HeroSection_exploreButton__-TMNj {
    padding: 6px 12px;
    font-size: 14px;
  }

  .HeroSection_heroSection__QB63D::before {
    width: 200px;
    height: 200px;
  }

  .HeroSection_galleryImage__Pn5Yr {
    width: 100px;
    height: 100px;
  }

  .HeroSection_galleryImage__Pn5Yr:first-child,
  .HeroSection_galleryImage__Pn5Yr:last-child {
    width: 80px;
    height: 100px;
  }

  .HeroSection_galleryImage__Pn5Yr:not(:first-child):not(:last-child) {
    width: 120px;
    height: 100px;
  }
}

/* General Job Listings Styling */
.JobListings_jobListings__2dnDv {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px;
  max-width: 1360px;
  margin: 0 auto;
}

.JobListings_jobListingsContent__8h16J {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns for header and description */
  grid-gap: 60px;
  gap: 60px; /* Proper gap between columns */
  align-items: start;
  width: 100%;
  margin-bottom: 40px;
}

.JobListings_jobListingsHeader__2-TcH {
  text-align: left;
  max-width: 500px; /* Aligns title and subtitle with the image */
  
}

.JobListings_jobListingsTitle__3Q1cf {
  font-size: 48px;
  line-height: 1.1;
  color: #3b5a91;
  margin-bottom: 20px;
}

.JobListings_jobListingsSubtitle__20dpM {
  font-size: 24px;
  color: #1e1e1e;
  margin-bottom: 10px;
}

.JobListings_jobListingsDescription__2TgKL {
  font-size: 18px;
  line-height: 1.6;
  text-align: justify;
  margin-top: 10px;
  color: #1e1e1e;
  max-width: 500px; /* Matches the width of job postings below */
}

.JobListings_jobPositions__3dWUs {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns for image and job positions */
  grid-gap: 60px;
  gap: 60px; /* Proper gap between image and positions list */
  align-items: start;
  width: 100%;
}

.JobListings_teamMemberContainer__2exPx {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.JobListings_teamMemberImage__H5Tun {
  width: 100%;
  max-width: 500px;
  border-radius: 0; /* Remove white background shape */ 
  margin-bottom: 40px; /* Ensures equal spacing below the image */
}

/* Positions List */
.JobListings_positionsList__aZQS_ {
  width: 100%;
  max-width: 500px; /* Ensure it aligns with the description width */
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px; /* Maintain the original gap */
  list-style-type: none;
  padding: 0;
}

.JobListings_positionItem__1Kpg1 {
  border-top: 1px solid rgba(30, 30, 30, 0.4);
  padding: 20px 0 10px; /* Consistent padding */
  transition: max-height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
  position: relative;
}

.JobListings_positionItem__1Kpg1:not(.JobListings_expanded__1Es7A) {
  padding-bottom: 0;
  border-bottom: none;
}

.JobListings_positionTitle__3ZvVj {
  font-size: 26px;
  font-weight: 600;
  color: #1e1e1e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
  max-width: 480px; /* Ensure alignment with the image width */
}

.JobListings_positionTitle__3ZvVj:hover {
  color: #3b5a91;
}

.JobListings_positionIcon__udX1g {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.JobListings_positionIcon__udX1g:hover {
  transform: rotate(180deg);
  opacity: 0.8;
}

.JobListings_positionDescription__bHA0j {
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
  color: #1e1e1e;
  margin-top: 10px;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.JobListings_positionItem__1Kpg1.JobListings_expanded__1Es7A .JobListings_positionDescription__bHA0j {
  max-height: 300px;
  opacity: 1;
}

.JobListings_readDetailsButton__3MmE_ {
  background-color: #3b5a91;
  color: #fff;
  border: none;
  border-radius: 64px;
  padding: 12px 24px;
  font-size: 18px;
  font-family: 'Darker Grotesque', sans-serif;
  font-weight: 700;
  cursor: pointer;
  display: none; /* Hidden by default */
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 20px;
}

.JobListings_positionItem__1Kpg1.JobListings_expanded__1Es7A .JobListings_readDetailsButton__3MmE_ {
  display: flex;
}

.JobListings_readDetailsButton__3MmE_:hover {
  background-color: #2c4770;
}

.JobListings_buttonIcon__3kbcS {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
}

.JobListings_readDetailsButton__3MmE_:hover .JobListings_buttonIcon__3kbcS {
  transform: translateX(5px);
}

/* Tablet View (768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .JobListings_jobListingsContent__8h16J {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    grid-gap: 30px;
    gap: 30px;
  }

  .JobListings_jobPositions__3dWUs {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
  }

  .JobListings_teamMemberImage__H5Tun {
    max-width: 450px;
    margin: 0 auto 40px; /* Ensures equal spacing above and below */
  }
}

/* iPad Pro View (980px to 1366px) - Matches Desktop */
@media (min-width: 980px) and (max-width: 1366px) {
  .JobListings_jobListingsContent__8h16J {
    grid-template-columns: 1fr 1fr; /* Desktop-like layout */
    grid-gap: 60px;
    gap: 60px;
  }

  .JobListings_jobPositions__3dWUs {
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    gap: 60px;
  }

  .JobListings_teamMemberImage__H5Tun {
    max-width: 500px;
    margin: 0;
  }

  .JobListings_jobListingsTitle__3Q1cf,
  .JobListings_jobListingsSubtitle__20dpM {
    text-align: left;
  }
}

/* Mobile View (Below 768px) */
@media (max-width: 767px) {
  .JobListings_jobListingsContent__8h16J {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    grid-gap: 20px;
    gap: 20px;
  }

  .JobListings_jobPositions__3dWUs {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
  }

  .JobListings_teamMemberImage__H5Tun {
    max-width: 300px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 40px; /* Ensures equal spacing above and below */
  }

  .JobListings_positionsList__aZQS_ {
    width: 100%;
    max-width: 90%;
    grid-gap: 16px;
    gap: 16px;
  }

  .JobListings_positionItem__1Kpg1 {
    padding: 20px 10px;
    border-top: 1px solid rgba(30, 30, 30, 0.4);
  }

  .JobListings_positionTitle__3ZvVj {
    font-size: 22px; /* Increased for better visibility */
    max-width: 100%; /* Aligns with mobile view width */
  }

  .JobListings_positionDescription__bHA0j {
    font-size: 16px;
    margin-top: 8px;
  }

  .JobListings_readDetailsButton__3MmE_ {
    margin-top: 10px;
    font-size: 14px;
    padding: 10px 16px;
  }
}
 
.MissionStatement_missionStatement__22o5U {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  position: relative;
}

.MissionStatement_missionTitle__2xXS4 {
  color: #1e1e1e;
  text-align: center;
  max-width: 60%;
  font: 400 48px/56px Montserrat, sans-serif;
  position: relative;
  z-index: 1;
  padding: 20px;
}

.MissionStatement_missionStatement__22o5U::before {
  content: "";
  position: absolute;
  top: 25;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 500px;
  height: 400px;
  background: url(/static/media/CareersGradientVector.25493852.png) no-repeat center;
  background-size: contain;
  z-index: 0;
  opacity: 0.8;
  margin-bottom: 40px;
}

.MissionStatement_missionVideoContainer__gvSqh {
  position: relative;
  min-height: 600px;
  width: 100%;
  max-width: 1200px;
  margin-top: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MissionStatement_missionVideo__1qgMZ {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
}

@media (max-width: 991px) {
  .MissionStatement_missionTitle__2xXS4 {
    font-size: 40px;
    line-height: 52px;
  }

  .MissionStatement_missionVideoContainer__gvSqh {
    min-height: 400px;
    margin-top: 40px;
  }
}

@media (max-width: 600px) {
  .MissionStatement_missionStatement__22o5U {
    padding: 40px 0; /* Reduce vertical padding */
    margin-bottom: 20px;
  }

  .MissionStatement_missionTitle__2xXS4 {
    font-size: 28px; /* Smaller font size for mobile */
    line-height: 36px;
    padding: 10px; /* Reduce padding */
    max-width: 90%; /* Use more of the screen width */
    
  }

  .MissionStatement_missionStatement__22o5U::before {
    width: 300px; /* Reduce the background size */
    height: 300px;
    top: 5%; /* Adjust positioning */
    opacity: 0.7; /* Slightly reduce opacity for mobile */
  }

  .MissionStatement_missionVideoContainer__gvSqh {
    min-height: 250px; /* Reduce video container height */
    margin-top: 50px; /* Adjust top margin */
  }

  .MissionStatement_missionVideo__1qgMZ {
    width: 100%;
    height: 100%; /* Ensure video scales properly */
    object-fit: cover; /* Maintain proper aspect ratio */
  }
}


.CareerPage_careerPage__3NW0R {
    max-width: none !important;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .CareerPage_separator__AgRGv {
    width: 100vw; /* Full width of the viewport */
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    object-fit: cover;
   
  }
.HeroSection_heroSection__25Cr_ {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 121px;
    padding: 0 30px;
  }
  
  .HeroSection_imageContainer__2gMUD {
    flex: 1 1;
    margin-top: 30px; 
    margin-left: 30px;
  }
  
  .HeroSection_heroImage__32Mvv {
    width: 90%;    
    object-fit: cover;
    transition: transform 0.3s ease; /* Smooth zoom on hover */
  }
  
  .HeroSection_heroImage__32Mvv:hover {
    transform: scale(1.02); /* Slight zoom effect */
  }
  
  .HeroSection_contentContainer__2yl4H {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -15px;
  
  }
  
  .HeroSection_title__1WOdV {
    font: 60px/60px Montserrat, sans-serif;
    color: #1e1e1e;
    margin-bottom: 19px;
    max-width: 70%;
  }

  .HeroSection_conciergePage__2oZpd .HeroSection_title__1WOdV {
    max-width: 100%;
  }
  
  .HeroSection_description__237j5 {
    font: 18px/26px Inter, sans-serif;
    color: #1e1e1e;
    margin-bottom: 33px;
    max-width: 95%;
  }
  
  /* Style for italic text within description */
  .HeroSection_description__237j5 em {
    color: #3b5a91;
  }
  
  .HeroSection_ctaButton__p3Mrx {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    background-color: #3b5a91;
    border: none;
    border-radius: 64px;
    color: #fff;
    font: 700 20px/4px 'Darker Grotesque', sans-serif;
    padding: 10px 20px;
    cursor: pointer;
    align-self: flex-start;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth hover effect */
  }
  
  .HeroSection_ctaButton__p3Mrx:hover {
    background-color: #2a4a78; /* Darker on hover */
    
  }
  
  .HeroSection_buttonIcon__36cCP {
    width: 39px;
    transition: transform 0.3s ease; /* Smooth hover effect */
  }
  
  .HeroSection_ctaButton__p3Mrx:hover .HeroSection_buttonIcon__36cCP {
    transform: translateX(5px); /* Move icon to the right on hover */
  }
  
  @media (max-width: 991px) {
    .HeroSection_heroSection__25Cr_ {
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
      padding: 0 60px;
      grid-gap: 20px;
      gap: 20px; /* Consistent gap for mobile */
    }
  
    .HeroSection_imageContainer__2gMUD {
      
      max-width: 100%;
    }
  
    .HeroSection_heroImage__32Mvv {
      width: 100%;
      height: auto; /* Preserve aspect ratio */     
    }
  
    .HeroSection_contentContainer__2yl4H {
      margin-top: 0px;
      width: 90%;
      text-align: center; /* Center align content */
    }
  
    .HeroSection_title__1WOdV {
      font-size: 25px; /* Adjusted for better readability */
      line-height: 42px;
      margin-bottom: 15px; /* Reduce bottom margin */
      max-width: 100%;
      text-align: left;
    }
  
    .HeroSection_description__237j5 {
      font-size: 16px; /* Adjusted for mobile */
      line-height: 24px;
      margin-bottom: 30px; /* Reduce bottom margin */
      text-align: left;
    }
  
    .HeroSection_ctaButton__p3Mrx {
      padding: 10px 16px;
      font-size: 16px;
      border-radius: 50px;
      align-self: center; /* Center align button */
    }
  
    .HeroSection_buttonIcon__36cCP {
      width: 30px; /* Adjusted for mobile */
    }
  }
  
  @media (max-width: 600px) {
    .HeroSection_heroSection__25Cr_ {
      padding: 0 15px;
      margin-top: 50px;
    }
  
    .HeroSection_imageContainer__2gMUD {
      max-width: 350px; /* Further adjust for smaller screens */
    }
  
    .HeroSection_title__1WOdV {
      font-size: 30px;
      line-height: 36px;
    }
  
    .HeroSection_description__237j5 {
      font-size: 14px;
      line-height: 20px;
    }
  
    .HeroSection_ctaButton__p3Mrx {
      padding: 8px 14px;
      font-size: 14px;
      border-radius: 44px; /* Slightly reduce border-radius */
    }
  
    .HeroSection_buttonIcon__36cCP {
      width: 24px; /* Smaller icon for compact view */
    }
  }
  
  @media (min-width: 1024px) and (max-width: 1366px) {
    .HeroSection_heroSection__25Cr_ {
      display: flex;
      flex-direction: row; /* Aligns like desktop */
      grid-gap: 30px;
      gap: 30px; /* Adjust gap between image and text */    
      padding: 0 40px 30px; /* Adjusted padding for better fit */
    }
  
    .HeroSection_imageContainer__2gMUD {
      flex: 1 1;
      margin: 0; /* Remove extra margins */
      max-width: 50%; /* Ensure image takes appropriate width */
      margin-top: 40px;
    }
  
    .HeroSection_heroImage__32Mvv {
      width: 100%; /* Full width for the image */
      height: auto; /* Maintain aspect ratio */     
      object-fit: cover;
    }
  
    .HeroSection_contentContainer__2yl4H {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left; /* Left align text */
    }
  
    .HeroSection_title__1WOdV {
      font: 54px/60px Montserrat, sans-serif; /* Slightly reduced font size */
      color: #1e1e1e;
      margin-bottom: 24px; /* Consistent spacing */
      max-width: 90%; /* Ensure the title doesn't overflow */
    }
  
    .HeroSection_description__237j5 {
      font: 18px/26px Inter, sans-serif; /* Adjusted font size */
      color: #1e1e1e;
      margin-bottom: 30px; /* Reduce gap below */
      max-width: 100%; /* Align width with text */
    }
  
    .HeroSection_description__237j5 em {
      color: #3b5a91; /* Highlighted text color */
    }
  
    .HeroSection_ctaButton__p3Mrx {
      display: flex;
      align-items: center;
      grid-gap: 12px;
      gap: 12px; /* Space between text and icon */
      background-color: #3b5a91;
      border: none;
      border-radius: 64px;
      color: #fff;
      font: 700 18px/24px 'Darker Grotesque', sans-serif; /* Slightly smaller font */
      padding: 12px 20px; /* Adjusted padding */
      align-self: flex-start;
      transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
    }
  
    .HeroSection_ctaButton__p3Mrx:hover {
      background-color: #2a4a78; /* Darker shade on hover */
    }
  
    .HeroSection_buttonIcon__36cCP {
      width: 30px; /* Adjusted icon size */
      transition: transform 0.3s ease; /* Smooth hover effect */
    }
  
    .HeroSection_ctaButton__p3Mrx:hover .HeroSection_buttonIcon__36cCP {
      transform: translateX(5px); /* Move icon slightly right on hover */
    }
  }
  
  
.ServicesOffered_servicesSection__7REHA {
  display: flex;
  margin-top: 146px; /* Adjust based on your layout */
  padding: 0 90px; /* Increase padding for more space on both sides */
  grid-gap:30px;
  gap:30px;
}

.ServicesOffered_sectionTitle__2e9Z7 {
  flex: 0 0 30%;
  font: 500 40px/42px Montserrat, sans-serif;
  color: #1e1e1e;
  margin-right: 20px;
  padding: 20px;
  position: relative;
  z-index: 1;
}

.ServicesOffered_sectionTitle__2e9Z7::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 80%;
  background-image: url(/static/media/bmellipse.a529d790.png); /* Replace with your gradient PNG path */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50% / 70%; /* Adjust these values to create an ellipse shape */
  z-index: -1;
  transform: scale(1.2); /* Optional: enlarge to better frame the text */
}

.ServicesOffered_servicesContent__3ao_x {
  flex: 0 0 70%;
  background-color: #e5ff9b; /* Background color to match your design */
  border-radius: 10px;
  padding: 52px 54px; /* Add padding for better spacing */
  margin-right: 20px; /* Space between sections */
}

.ServicesOffered_servicesDescription__1qe0K {
  font: 400 18px/26px Inter, sans-serif; /* Style for description text */
  color: #1e1e1e;
  margin-bottom: 20px; /* Add margin for spacing */
}

.ServicesOffered_servicesList__KH0Ta {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
  grid-gap: 20px;
  gap: 20px; /* Space between items */
  list-style-type: none; /* Remove default list styles */
  padding: 0; /* Remove default padding */
}

.ServicesOffered_serviceItem__1GWts {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; /* Space between icon and text */
  font: 400 18px/28px Inter, sans-serif; /* Style for service item text */
  color: #1e1e1e;
}

.ServicesOffered_serviceIcon__1KhR0 {
  width: 24px; /* Set icon width */
  height: 24px; /* Set icon height */
}

@media (max-width: 991px) {
  .ServicesOffered_sectionTitle__2e9Z7::before {
    width: 250px; /* Adjust width for tablets */
    height: 250px; /* Adjust height for tablets */
    transform: translate(-50%, -50%) scale(1.3); /* Slightly smaller scale */
    top: 50%; /* Keep centered vertically */
    left: 20%; /* Keep centered horizontally */
  }

  .ServicesOffered_servicesSection__7REHA {
    flex-direction: column; /* Stack elements on smaller screens */
    margin-top: 40px; /* Adjust margin for smaller screens */
    padding: 0 20px; /* Adjust padding for smaller screens */
  }

  .ServicesOffered_sectionTitle__2e9Z7 {
    margin-bottom: 20px; /* Add margin below section title */
  }

  .ServicesOffered_servicesContent__3ao_x {
    padding: 30px 20px; /* Adjust padding for content */
  }
}

@media (max-width: 767px) {
  .ServicesOffered_sectionTitle__2e9Z7 {
    text-align: center; /* Center the text */
    margin: 0 auto; /* Center the title container */
    position: relative;
  }

  .ServicesOffered_sectionTitle__2e9Z7::before {
    width: 250px; /* Adjust width for mobile */
    height: 250px; /* Adjust height for mobile */
    transform: translate(-50%, -50%) scale(1); /* Center the gradient */
    top: 50%; /* Center vertically relative to the title */
    left: 50%; /* Center horizontally */
  }

  .ServicesOffered_servicesSection__7REHA {
    flex-direction: column; /* Stack elements on smaller screens */
    margin-top: 40px; /* Adjust margin for smaller screens */
    padding: 0 20px; /* Adjust padding for smaller screens */
    margin-left: 10px;
  }

  .ServicesOffered_servicesContent__3ao_x {
    padding: 30px 20px; /* Adjust padding for content */
  }

  .ServicesOffered_servicesList__KH0Ta {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
}


.QuoteFormCommon_quoteRequestSection__2mIWQ {
    position: relative;
    border-radius: 50px;
    background-color: #e3ecf6;
    width: 100%;
    max-width: 1360px;
    margin: 160px auto 60px; /* Consistent top and bottom margin */
    padding: 40px 40px 60px; /* Consistent padding with homepage */
    display: flex;
    grid-gap: 40px;
    gap: 40px; /* Consistent gap */
    align-items: stretch;
    overflow: hidden;
  }
  
  .QuoteFormCommon_quoteRequestSection__2mIWQ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 250px; /* Adjusted for consistency */
    background-image: url(/static/media/clippathgroup.88e2e984.png);
    background-size: cover;
    background-position: center;
    z-index: 1;
  }
  
  .QuoteFormCommon_contentContainer__jytFf {
    flex: 0 0 58%;
    color: #1e1e1e;
    z-index: 2;
    max-width: 55%;
  }
  
  .QuoteFormCommon_title__eSHCV {
    font: 500 50px/1.1 'Montserrat', sans-serif;
    color: #1e1e1e;
    margin-bottom: 10px;
    margin-left: 60px;
    max-width: 58%;
  }
  
  .QuoteFormCommon_description__1Ol_X {
    font: 400 20px/1.5 'Inter', sans-serif;
    color: #1e1e1e;
    margin-left: 60px;
    margin-top: 30px;
    max-width: 65%;
  }
  
  .QuoteFormCommon_quoteForm__1LJNs {
    flex: 0 0 42%;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    max-width: 440px;
    z-index: 2;
  }
  
  .QuoteFormCommon_formGroup__RZStM {
    display: flex;
    flex-direction: column;
  }
  
  .QuoteFormCommon_label__1W7Xe {
    font: 600 16px/1.4 'Inter', sans-serif;
    color: #1e1e1e;
    margin-bottom: 4px;
  }
  
  .QuoteFormCommon_input__2GhX8,
  .QuoteFormCommon_select__2Slgk {
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 18px;
    border: none;
    font: 400 16px/1.4 'Inter', sans-serif;
    color: #1e1e1e;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease;
    -webkit-appearance: none;
            appearance: none;
  }
  
  .QuoteFormCommon_input__2GhX8:focus,
  .QuoteFormCommon_select__2Slgk:focus {
    outline: none;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .QuoteFormCommon_submitButton__T9VPd {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    background-color: #3b5a91;
    border: none;
    border-radius: 64px;
    color: #fff;
    font: 700 18px/1 'Darker Grotesque', sans-serif;
    padding: 12px 20px;
    max-width: 300px; /* Set a maximum width */
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .QuoteFormCommon_submitButton__T9VPd:hover {
    background-color: #2d4a74;
    transform: translateY(-2px);
  }
  
  .QuoteFormCommon_submitButton__T9VPd:active {
    transform: translateY(0);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  }
  
  .QuoteFormCommon_buttonIcon__2LHp0 {
    width: 20px;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .QuoteFormCommon_submitButton__T9VPd:hover .QuoteFormCommon_buttonIcon__2LHp0 {
    transform: translateX(4px);
  }
  
  .QuoteFormCommon_loader__qNkWH {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .QuoteFormCommon_spinner__4KZcq {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #4caf50;
    animation: QuoteFormCommon_spin__G-Wzd 1s ease infinite;
  }
  
  @keyframes QuoteFormCommon_spin__G-Wzd {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Tablet View: max-width 991px */
  @media (max-width: 950px) and (min-width: 601px) {
    .QuoteFormCommon_quoteRequestSection__2mIWQ {
      flex-direction: column; /* Stack content and form vertically */
      padding: 40px 20px; /* Equal padding on both sides */
      margin: 40px auto; /* Center the section */
      max-width: 90%; /* Restrict width for better alignment */
      align-items: center; /* Center-align the section */
      box-sizing: border-box; /* Include padding in width calculations */
    }
    .QuoteFormCommon_contentContainer__jytFf {
      width: 100%;
      max-width: 100%;   
      padding: 30px 70px 20px;  
      text-align: left; /* Align text to the left */
    }
  
    .QuoteFormCommon_title__eSHCV {
      font-size: 36px; /* Keeps the title readable */
      line-height: 1.3; /* Adjusted for spacing between lines */
      margin: 0 0 15px; /* No margin on sides, only bottom spacing */
      padding: 0 5px; /* 5px gap on both sides */
      max-width: 100%; /* Fully utilizes the container width */
      text-align: center; /* Aligns the text */
    }
  
    .QuoteFormCommon_description__1Ol_X {
      font-size: 18px; /* Readable font size */
      line-height: 1.6; /* Comfortable line height */
      margin: 0 auto; /* Centers the description */
      padding: 0 25px; /* 5px gap on both sides */
      max-width: 100%; /* Fully utilizes the container width */
      text-align: justify; /* Aligns text evenly on both sides */
    }
  
    .QuoteFormCommon_quoteForm__1LJNs {
      width: 100%; /* Full width for the form */
      max-width: 500px; /* Restrict maximum width */
      margin-top: -10px;
      margin: 0 auto; /* Center the form */
    }
  
    .QuoteFormCommon_formGroup__RZStM {
      width: 95%; /* Restrict width for better alignment */
      margin: 0 auto;
    }
  
    .QuoteFormCommon_input__2GhX8,
    .QuoteFormCommon_select__2Slgk {
      width: 100%; /* Full width for inputs */
      padding: 14px 16px; /* Comfortable padding */
      margin-bottom: 16px; /* Spacing between fields */
    }
  
    .QuoteFormCommon_submitButton__T9VPd {
      width: auto; /* Dynamic width for button */
      padding: 12px 24px; /* Comfortable padding */
      font-size: 18px; /* Match desktop font size */
      margin: 20px auto 0; /* Center the button */
      display: flex; /* Flexbox for alignment */
      justify-content: center; /* Center-align content */
      align-items: center;
      border-radius: 64px; /* Rounded button */
    }
  
    .QuoteFormCommon_buttonIcon__2LHp0 {
      width: 20px; /* Maintain icon size */
      height: auto; /* Maintain aspect ratio */
      margin-left: 10px; /* Spacing between text and icon */
    }
  }
  
  /* Mobile View: max-width 600px */
  @media (max-width: 600px) {
    .QuoteFormCommon_quoteRequestSection__2mIWQ {
      flex-direction: column; /* Stack content and form vertically */
      padding: 30px 15px; /* Equal padding */
      margin: 20px auto; /* Center the section */
      max-width: 90%; /* Limit width for alignment */
      align-items: center; /* Center-align the container */
      box-sizing: border-box; /* Include padding in width calculations */
    }
  
    .QuoteFormCommon_contentContainer__jytFf {
      width: 100%;
      max-width: 100%;   
      margin: 20px 0 auto;    
      text-align: left; /* Align text to the left */
    }
  
    .QuoteFormCommon_title__eSHCV {
      font-size: 28px; /* Mobile-friendly size */
      line-height: 1.3; /* Adjusted line height */
      margin: 0 0 10px; /* Only bottom spacing */
      padding: 0; /* No additional padding */
      max-width: 100%; /* Utilize full width */
      text-align: center;
    }
  
    .QuoteFormCommon_description__1Ol_X {
      font-size: 16px; /* Slightly smaller font for mobile */
      line-height: 1.5; /* Comfortable line height */
      margin: 0; /* Remove all margins */
      padding: 0; /* Remove additional padding */
      max-width: 100%; /* Utilize full width */
      text-align: left; /* Align text to the left */
    }
  
    .QuoteFormCommon_quoteForm__1LJNs {
      width: 100%; /* Full width for the form */
      max-width: 400px; /* Restrict maximum width */
      margin: 0 auto; /* Center the form */
    }
  
    .QuoteFormCommon_formGroup__RZStM {
      width: 95%; /* Restrict width for better alignment */
      margin: 0 auto;
    }
  
    .QuoteFormCommon_input__2GhX8,
    .QuoteFormCommon_select__2Slgk {
      width: 100%; /* Full width for inputs */
      padding: 12px 14px; /* Comfortable padding */
      margin-bottom: 14px; /* Spacing between fields */
    }
  
    .QuoteFormCommon_submitButton__T9VPd {
      width: auto; /* Dynamic width for button */
      padding: 10px 20px; /* Comfortable padding */
      font-size: 16px; /* Match mobile-friendly font size */
      margin: 20px auto 0; /* Center the button */
      display: flex; /* Flexbox for alignment */
      justify-content: center; /* Center-align content */
      align-items: center;
      border-radius: 50px; /* Rounded button */
    }
  
    .QuoteFormCommon_buttonIcon__2LHp0 {
      width: 18px; /* Slightly smaller icon size for mobile */
      height: auto; /* Maintain aspect ratio */
      margin-left: 8px; /* Spacing between text and icon */
    }
  }
  
  @media (min-width: 951px) and (max-width: 1400px) {
    .QuoteFormCommon_quoteRequestSection__2mIWQ {
      display: flex;
      flex-direction: row; /* Content and form side-by-side */
      padding: 30px 30px; /* Adjusted padding for alignment */
      margin: 40px auto; /* Center-align the section */
      max-width: 85%; /* Restrict section width */
      grid-gap: 40px;
      gap: 40px; /* Spacing between content and form */
      align-items: center; /* Center-align vertically */
      box-sizing: border-box; /* Include padding in width */
      border-radius: 40px; /* Slight rounding for polished design */
    }
  
    .QuoteFormCommon_contentContainer__jytFf {
      flex: 1 1; /* Equal space for content */
      text-align: left; /* Align content text to the left */
      margin: 0; /* Remove additional margins */
      margin-top: 10px;
      padding-right: 20px; /* Add spacing between content and form */
    }
  
    .QuoteFormCommon_title__eSHCV {
      font-size: 40px; /* Slightly larger font size for better readability */
      line-height: 1.3; /* Comfortable spacing between lines */
      margin-bottom: 15px; /* Add spacing below the title */
      padding: 0; /* No additional padding */
      max-width: 100%; /* Utilize full width of the container */
      word-wrap: break-word; /* Ensure the text wraps neatly */
    }
  
    .QuoteFormCommon_description__1Ol_X {
      font-size: 18px; /* Adjust font size for readability */
      line-height: 1.6; /* Comfortable line spacing */
      margin-bottom: 20px; /* Add spacing below the description */
      text-align: justify; /* Justify text for a clean layout */
      max-width: 90%; /* Utilize the available width */
    }
  
    .QuoteFormCommon_quoteForm__1LJNs {
      flex: 1 1; /* Equal space for form */
      max-width: 400px; /* Restrict form width */
      margin: 0 auto; /* Center-align the form */
      padding: 0; /* Remove extra padding */
    }
  
    .QuoteFormCommon_formGroup__RZStM {
      width: 100%; /* Full width for form inputs */
      margin-bottom: 0px; /* Spacing between inputs */
    }
  
    .QuoteFormCommon_input__2GhX8,
    .QuoteFormCommon_select__2Slgk {
      width: 100%; /* Full width for inputs */
      padding: 16px 18px; /* Comfortable padding */
      margin-bottom: 5px; /* Spacing between fields */
      font-size: 16px; /* Adjusted font size */
      border: 1px solid #ddd; /* Subtle border for inputs */
      border-radius: 8px; /* Rounded corners for consistency */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for inputs */
      box-sizing: border-box; /* Prevent overflow */
    }
  
    .QuoteFormCommon_input__2GhX8:focus,
    .QuoteFormCommon_select__2Slgk:focus {
      outline: none; /* Remove default outline */
      border-color: #3b5a91; /* Highlight input on focus */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Enhanced shadow on focus */
    }
  
    .QuoteFormCommon_submitButton__T9VPd {
      display: flex; /* Flex for alignment */
      justify-content: center; /* Center the button text */
      align-items: center; /* Center-align content */
      width: 100%; /* Full width for button */
      padding: 14px 24px; /* Adjusted padding */
      font-size: 18px; /* Match desktop font size */
      margin-top: 20px; /* Space above the button */
      border-radius: 50px; /* Rounded button */
      background-color: #3b5a91; /* Button background color */
      color: #fff; /* Button text color */
      text-align: center; /* Center-align text */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
      cursor: pointer; /* Pointer cursor */
      transition: background-color 0.3s ease, transform 0.2s ease;
    }
  
    .QuoteFormCommon_submitButton__T9VPd:hover {
      background-color: #2d4a74; /* Darker button color on hover */
      transform: translateY(-2px); /* Slight upward movement */
    }
  
    .QuoteFormCommon_buttonIcon__2LHp0 {
      width: 20px; /* Maintain icon size */
      height: auto; /* Maintain aspect ratio */
      margin-left: 10px; /* Spacing between text and icon */
    }
  }
  
.ConciergeService_servicePage__2fS7X {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .ConciergeService_decorativeImage__3Sbjm {
    aspect-ratio: 22.73;
    object-fit: contain;
    object-position: center;
    width: 100%;
    z-index: 10;
    margin-top: 120px;
  }
  
  @media (max-width: 991px) {
    .ConciergeService_decorativeImage__3Sbjm {
      max-width: 100%;
      margin-top: 40px;
    }
  }
.ServicesOffered_servicesSection__2F4n9 {
  display: flex;
  margin-top: 146px; /* Adjust based on your layout */
  padding: 0 80px; /* Increase padding for more space on both sides */
}

.ServicesOffered_sectionTitle__1IHy7 {
  flex: 0 0 30%; /* Width for the title */
  font: 500 44px/46px Montserrat, sans-serif; /* Title font */
  color: #1e1e1e; /* Title color */
  margin-right: 20px; /* Space between title and content */
  padding: 20px; /* Padding around the title */
  position: relative; /* Positioning for the pseudo-element */
  z-index: 1; /* Bring title above background */
}

.ServicesOffered_sectionTitle__1IHy7::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 80%; /* Width of the gradient background */
  height: 80%; /* Height of the gradient background */
  background-image: url(/static/media/bmellipse.a529d790.png); /* Replace with your gradient PNG path */
  background-size: cover; /* Cover the area */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-position: center; /* Center the image */
  border-radius: 50% / 70%; /* Ellipse shape */
  z-index: -1; /* Send to back */
  transform: scale(1.2); /* Optional: enlarge to better frame the text */
}

.ServicesOffered_servicesContainer__4pq9p {
  flex: 0 0 70%; /* Width for the content */
  border-radius: 10px; /* Rounded corners */
  background-color: #e5ff9b; /* Background color to match your design */
  padding: 52px 68px; /* Inner padding */
  overflow: hidden; /* Hide overflow to avoid scrollbar issues */
  max-width: calc(100% - 20px); /* Prevent overflow outside of the container */
}

.ServicesOffered_servicesIntro__38wvF {
  color: #1e1e1e; /* Intro text color */
  font: 700 22px/31px Inter, sans-serif; /* Intro text style */
  margin-bottom: 19px; /* Space below intro */
}

.ServicesOffered_servicesList__3Fb7q {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns layout */
  grid-gap: 10px;
  gap: 10px; /* Increased gap for better spacing */
}

.ServicesOffered_serviceItem__1kzLA {
  display: flex;
  align-items: first baseline;
  grid-gap: 10px;
  gap: 10px;
  font: 400 18px/28px Inter, sans-serif;
  color: #1e1e1e;
}

.ServicesOffered_serviceIcon__XC2a_ {
  width: 24px; /* Set icon width */
  height: 24px; /* Set icon height */
  object-fit: contain; /* Maintain aspect ratio */
}

@media (max-width: 991px) {
  .ServicesOffered_sectionTitle__1IHy7::before {
    width: 250px; /* Adjust width for tablets */
    height: 250px; /* Adjust height for tablets */
    transform: translate(-50%, -50%) scale(1.3); /* Slightly smaller scale */
    top: 50%; /* Keep centered vertically */
    left: 40%; /* Keep centered horizontally */
  }
  .ServicesOffered_servicesSection__2F4n9 {
    flex-direction: column;
    margin-top: 40px;
    padding: 0 20px;
  }

  .ServicesOffered_sectionTitle__1IHy7 {
    margin-bottom: 20px;
  }

  .ServicesOffered_servicesContainer__4pq9p {
    padding: 30px 20px;
  }
}

@media (max-width: 767px) { /* Adjust breakpoint as needed */

  .ServicesOffered_sectionTitle__1IHy7 {
    text-align: center; /* Center the text */
    margin: 0 auto; /* Center the title container */
    position: relative;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .ServicesOffered_sectionTitle__1IHy7::before {
    width: 400px; /* Adjust width for mobile */
    height: 400px; /* Adjust height for mobile */
    transform: translate(-50%, -50%) scale(1); /* Keep original size */
    top: 40%; /* Keep centered vertically */
    left: 50%; /* Keep centered horizontally */
  }
  .ServicesOffered_servicesList__3Fb7q {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
}

.CallToAction_ctaSection__281Gu {
  text-align: center;
  padding: 80px 20px;
  background-color: #ffffff;
}

.CallToAction_ctaTitle__hDa8h {
  position: relative;
  color: #1e1e1e;
  text-align: center;
  font: 400 60px/72px Montserrat, sans-serif;
  margin: 80px auto;
  max-width: 54%;
  padding: 60px 40px;
  z-index: 2;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.CallToAction_ctaTitle__hDa8h::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 500px;
  background: url(/static/media/CareersGradientVector.25493852.png) no-repeat center center;
  background-size: contain;
  opacity: 0.8;
  z-index: 1;
  border-radius: 120px;
  transition: opacity 0.3s ease;
}

.CallToAction_ctaTitle__hDa8h:hover {
  transform: scale(1.02);
  opacity: 0.95;
}

.CallToAction_ctaTitle__hDa8h:hover::before {
  opacity: 1;
}

@media (max-width: 991px) {
  .CallToAction_ctaTitle__hDa8h {
    margin: 40px auto;
    font-size: 40px;
    line-height: 53px;
    padding: 40px 20px;
    max-width: 78%;
  }
  
  .CallToAction_ctaTitle__hDa8h::before {
    width: 500px;
    height: 400px;
    border-radius: 80px;
  }
}



@media (max-width: 991px) {
  .CallToAction_ctaTitle__hDa8h {
    margin: 40px auto;
    font-size: 40px;
    line-height: 53px;
  }
}


@media (max-width: 991px) {
  .CallToAction_ctaSection__281Gu {
    padding: 40px 20px;
  }

  .CallToAction_ctaTitle__hDa8h {
    font-size: 40px;
    line-height: 52px;
  }
}
.StrataCaretaking_servicePage__3FS5p {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
.ServicesOffered_servicesSection__1S94q {
  display: flex;
  margin-top: 146px; /* Adjust based on your layout */
  padding: 0 80px; /* Increase padding for more space on both sides */
}

.ServicesOffered_sectionTitle__2nviD {
  flex: 0 0 30%; /* Width for the title */
  font: 500 44px/46px Montserrat, sans-serif; /* Title font */
  color: #1e1e1e; /* Title color */
  margin-right: 20px; /* Space between title and content */
  padding: 20px; /* Padding around the title */
  position: relative; /* Positioning for the pseudo-element */
  z-index: 1; /* Bring title above background */
}

.ServicesOffered_sectionTitle__2nviD::before {
  content: "";
  position: absolute;
  top: -20%;
  left: 0;
  width: 80%; /* Width of the gradient background */
  height: 80%; /* Height of the gradient background */
  background-image: url(/static/media/bmellipse.a529d790.png); /* Replace with your gradient PNG path */
  background-size: cover; /* Cover the area */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-position: center; /* Center the image */
  border-radius: 50% / 70%; /* Ellipse shape */
  z-index: -1; /* Send to back */
  transform: scale(1.2); /* Optional: enlarge to better frame the text */
}

.ServicesOffered_servicesContainer__2EDnT {
  flex: 0 0 70%; /* Width for the content */
  border-radius: 10px; /* Rounded corners */
  background-color: #e5ff9b; /* Background color to match your design */
  padding: 52px 68px; /* Inner padding */
  overflow: hidden; /* Hide overflow to avoid scrollbar issues */
  max-width: calc(100% - 20px); /* Prevent overflow outside of the container */
}

.ServicesOffered_servicesIntro__2SDD3 {
  color: #1e1e1e; /* Intro text color */
  font: 700 22px/31px Inter, sans-serif; /* Intro text style */
  margin-bottom: 19px; /* Space below intro */
}

.ServicesOffered_servicesList__1illB {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns layout */
  grid-gap: 10px;
  gap: 10px; /* Increased gap for better spacing */
}

.ServicesOffered_serviceItem__2rGLZ {
  display: flex;
  align-items: first baseline;
  grid-gap: 10px;
  gap: 10px;
  font: 400 18px/28px Inter, sans-serif;
  color: #1e1e1e;
}

.ServicesOffered_serviceIcon__36vQW {
  width: 24px; /* Set icon width */
  height: 24px; /* Set icon height */
  object-fit: contain; /* Maintain aspect ratio */
}

@media (max-width: 991px) {
  .ServicesOffered_sectionTitle__2nviD::before {
    width: 250px; /* Adjust width for tablets */
    height: 250px; /* Adjust height for tablets */
    transform: translate(-50%, -50%) scale(1.3); /* Slightly smaller scale */
    top: 50%; /* Keep centered vertically */
    left: 40%; /* Keep centered horizontally */
  }
  .ServicesOffered_servicesSection__1S94q {
    flex-direction: column;
    margin-top: 40px;
    padding: 0 20px;
  }

  .ServicesOffered_sectionTitle__2nviD {
    margin-bottom: 20px;
    max-width: 50%;
  }

  .ServicesOffered_servicesContainer__2EDnT {
    padding: 30px 20px;
  }
}

@media (max-width: 767px) { /* Adjust breakpoint as needed */

  .ServicesOffered_sectionTitle__2nviD {
    text-align: center; /* Center the text */
    margin: 0 auto; /* Center the title container */
    position: relative;
    margin-bottom: 30px;
    margin-top: 30px;
    max-width: 100%;
  }
  .ServicesOffered_sectionTitle__2nviD::before {
    width: 400px; /* Adjust width for mobile */
    height: 400px; /* Adjust height for mobile */
    transform: translate(-50%, -50%) scale(1); /* Keep original size */
    top: 40%; /* Keep centered vertically */
    left: 50%; /* Keep centered horizontally */
  }  
  .ServicesOffered_servicesList__1illB {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
}

.StrataCleaningServices_servicePage__2EcPs {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  @media (max-width: 991px) {
    .StrataCleaningServices_servicePage__2EcPs {
      max-width: 100%;
    }
  }
/* Grid Container for the Combined Section */
.Specializedcarecommonstyle_sectionGrid__2RPSh {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-gap: 30px;
  gap: 30px; /* Space between columns */
  width: 90%;
  max-width: 1200px;
  margin: 50px auto; /* Centering container */
  margin-bottom: 20px;
  align-items: stretch; /* Ensures even height */
  overflow: hidden; /* Prevents overflow */
}

/* Content Wrapper for Each Section */
.Specializedcarecommonstyle_contentWrapper__1ByEu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 30px;
  background-color: #fff;
  background-image: url(/static/media/AboutusGradient.6aec8d2a.png); /* Gradient PNG background */
  background-repeat: no-repeat;
  background-position: top; /* Align gradient to the top */
  border-radius: 10px 10px 150px 10px; /* Consistent rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  color: #1e1e1e;
  height: 400px; /* Fixed height for uniform blocks */
  box-sizing: border-box;
  overflow: hidden; /* Keeps content within the rounded border */
  transition: padding 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

/* Image Styling for Each Section */
.Specializedcarecommonstyle_sectionImage__2i79F {
  width: 100%;
 
  
  height: 400px; /* Fixed height for uniformity */
  overflow: hidden; /* Keeps rounded corners intact */
}

/* Section Title Styling */
.Specializedcarecommonstyle_sectionTitle__-oama {
  font: 500 36px/1.2 'Montserrat', sans-serif; /* Consistent font size */
  color: #1e1e1e;
  margin-bottom: 20px;
}

/* Description Styling */
.Specializedcarecommonstyle_description__2PjiF {
  font: 400 16px/1.6 'Inter', sans-serif; /* Consistent font size */
  color: #4a4a4a;
}

/* Define borders for each card */
.Specializedcarecommonstyle_sectionGrid__2RPSh .Specializedcarecommonstyle_contentWrapper__1ByEu:nth-child(1) {
  border-radius: 150px 10px 10px 10px; /* Consistent rounded corners */
}

.Specializedcarecommonstyle_sectionGrid__2RPSh .Specializedcarecommonstyle_contentWrapper__1ByEu:nth-child(2) {
  border-radius: 0px 10px 10px 150px; /* Consistent rounded corners */
}

.Specializedcarecommonstyle_sectionGrid__2RPSh .Specializedcarecommonstyle_contentWrapper__1ByEu:nth-child(3) {
  border-radius: 10px 10px 150px 10px; /* Consistent rounded corners */
}

.Specializedcarecommonstyle_sectionGrid__2RPSh .Specializedcarecommonstyle_contentWrapper__1ByEu:nth-child(4) {
  border-radius: 10px 10px 150px 10px; /* Consistent rounded corners */
}

/* Responsive Design */
/* Responsive Design for Tablet */
/* Responsive Design for Tablet */
@media (max-width: 991px) {
  .Specializedcarecommonstyle_sectionGrid__2RPSh {
    grid-template-columns: repeat(2, 1fr); /* Two columns for tablet */
    grid-gap: 20px;
    gap: 20px; /* Spacing between columns */
    padding: 20px 30px; /* Add consistent padding */
  }

  .Specializedcarecommonstyle_contentWrapper__1ByEu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px; /* Reduced padding for compact spacing */
    background-color: #fff;
    background-image: url(/static/media/AboutusGradient.6aec8d2a.png); /* Gradient path */
    background-repeat: no-repeat;
    background-size: cover; /* Ensures gradient fills container */
    background-position: center; /* Center-align gradient */
    border-radius: 10px; /* Consistent rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    color: #1e1e1e;
    height: auto; /* Adjust height to fit content */
    box-sizing: border-box;
    overflow: hidden; /* Prevent overflow issues */
    transition: padding 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  }

  .Specializedcarecommonstyle_sectionImage__2i79F {
    width: 100%; /* Full width for each column */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Match contentWrapper */
    object-fit: cover; /* Ensure images fit properly */
  }

  .Specializedcarecommonstyle_sectionTitle__-oama {
    font-size: 24px; /* Adjust font size for tablet */
    margin-bottom: 15px;
    line-height: 1.4;
    text-align: left; /* Align titles left for better readability */
  }

  .Specializedcarecommonstyle_description__2PjiF {
    font-size: 16px; /* Adjust text size */
    line-height: 1.5;
    color: #4a4a4a;
    text-align: justify; /* Improve readability */
  }

  .Specializedcarecommonstyle_gradientBackground__2_M7I {
    position: absolute;
    left: -15px; /* Adjust position for alignment */
    width: 180px; /* Resize gradient for tablet */
    height: 180px;
    z-index: -1; /* Ensure gradient stays in the background */
    filter: blur(25px); /* Adjust blur for clarity */
  }

  .Specializedcarecommonstyle_sectionGrid__2RPSh .Specializedcarecommonstyle_contentWrapper__1ByEu:nth-child(1) {
    background-size: 100% 100%; /* Stretch gradient to fill the card */
    background-position: top center; /* Proper alignment */
    border-radius: 60px 0 0 0 ; /* Uniform rounding for tablet */
  }

  .Specializedcarecommonstyle_sectionGrid__2RPSh .Specializedcarecommonstyle_contentWrapper__1ByEu:nth-child(2) {
       border-radius:  0 0  60px 0; /* Uniform rounding for tablet */
       border-style: none;
  }

  .Specializedcarecommonstyle_sectionGrid__2RPSh .Specializedcarecommonstyle_contentWrapper__1ByEu:nth-child(3) {
    border-radius:  60px 0 0 0 ; /* Uniform rounding for tablet */

}
  .Specializedcarecommonstyle_sectionGrid__2RPSh .Specializedcarecommonstyle_contentWrapper__1ByEu:nth-child(4) {
    background-size: 100% 100%; /* Stretch gradient to fill the card */
    background-position: top center; /* Proper alignment */
    border-radius:  0 0  60px 0; /* Uniform rounding for tablet */
  }
}


/* Responsive Design for Mobile */
@media (max-width: 767px) {
  .Specializedcarecommonstyle_sectionGrid__2RPSh {
    grid-template-columns: 1fr; /* Single column for mobile */
    grid-gap: 20px;
    gap: 20px; /* Add spacing between sections */
    padding: 10px 15px; /* Ensure padding for better spacing */
  }
  
  .Specializedcarecommonstyle_contentWrapper__1ByEu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 15px; /* Reduced padding for consistent spacing */
    background-color: #fff;
    background-image: url(/static/media/AboutusGradient.6aec8d2a.png); /* Ensure the gradient path is correct */
    background-repeat: no-repeat;
    background-size: cover; /* Ensures the gradient fills the entire container */
    background-position: center; /* Center-aligns the gradient */
    border-radius: 10px; /* Consistent rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    color: #1e1e1e;
    height: auto; /* Adjust height to fit the content */
    box-sizing: border-box;
    overflow: hidden; /* Ensures no overflow issues */
    transition: padding 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
  }
  
  .Specializedcarecommonstyle_sectionImage__2i79F {
    width: 100%; /* Full width for mobile */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Match contentWrapper */
    object-fit: cover; /* Ensure images fit well */
  }
  
  .Specializedcarecommonstyle_sectionTitle__-oama {
    font-size: 20px; /* Smaller font size for titles */
    margin-bottom: 10px;
    line-height: 1.3;
    text-align: center; /* Center-align text */
  }
  
  .Specializedcarecommonstyle_description__2PjiF {
    font-size: 14px; /* Adjust text size */
    line-height: 1.5;
    color: #4a4a4a;
    text-align: justify; /* Improve readability on smaller screens */
  }
  
  .Specializedcarecommonstyle_gradientBackground__2_M7I {
    position: absolute;
    
    left: -20px; /* Align properly */
    width: 150px; /* Resize gradient for mobile */
    height: 150px;
    z-index: -1; /* Ensure gradient stays in the background */
    filter: blur(20px); /* Reduce blur for clarity */
  }
  
  .Specializedcarecommonstyle_sectionGrid__2RPSh .Specializedcarecommonstyle_contentWrapper__1ByEu:nth-child(1) {
    border-radius: 10px; /* Uniform rounding for mobile */
    border-radius: 40px 0 0 0  ; /* Uniform rounding */
  }
  
  .Specializedcarecommonstyle_sectionGrid__2RPSh .Specializedcarecommonstyle_contentWrapper__1ByEu:nth-child(2) {
    border-radius: 10px; /* Uniform rounding for mobile */
  }

.Specializedcarecommonstyle_sectionGrid__2RPSh .Specializedcarecommonstyle_contentWrapper__1ByEu:nth-child(3){
  background-size: 100% 100%; /* Stretch the gradient to fill the card */
  background-position: top center; /* Ensures proper alignment */
  border-radius:  0 0 0 40px ; /* Uniform rounding */
}
.Specializedcarecommonstyle_sectionGrid__2RPSh .Specializedcarecommonstyle_contentWrapper__1ByEu:nth-child(4) {
  background-size: 100% 100%; /* Stretch the gradient to fill the card */
  background-position: top center; /* Ensures proper alignment */
  
}
}
/* Desktop & Tablet */

.HeroSection_heroSection__2fIfx {
  display: flex;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
  margin-top: 50px; /* Adjust this value as needed */
  margin-bottom: 0; /* Remove bottom margin to connect to OurStory */
  padding: 30px;
  padding-top: 20px;
  width: 100%;
  max-width: 1353px;
  margin: 0 auto;
  position: relative;
}

.HeroSection_separatorWrapper__1Y4si {
  position: absolute;
  bottom: -10px; /* Slight overlap with OurStory section */
  left: 0;
  width: 100vw; /* Full viewport width to avoid gaps */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden; /* Hide any overflow to avoid scroll issues */
}

.HeroSection_separator__3XZfy {
  width: 100vw; /* Full width of the viewport */
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
 
}

.HeroSection_imageWrapper__KZvGG {
  flex: 1 1;
  max-width: 600px;
}

.HeroSection_heroImage__2PAcj {
  width: 100%;
  border-radius: 0 160px 0 160px;
  object-fit: cover;
  transition: transform 0.3s ease; /* Smooth zoom on hover */
}

.HeroSection_heroImage__2PAcj:hover {
  transform: scale(1.02); /* Slight zoom effect */
}

.HeroSection_content__3hYf8 {
  flex: 1 1;
  color: #1e1e1e;
  padding: 0 30px;
  margin-left: 50px;
}

.HeroSection_title__3EJSI {
  font: 500 66px/69px Montserrat, sans-serif;
}

.HeroSection_description__2j7e6 {
  margin-top: 31px;
  font: 400 22px/32px Inter, sans-serif;
  max-width: 500px;
}

.HeroSection_contactButton__2CjXn {
  border-radius: 64px;
  background-color: #3b5a91;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  color: #fff;
  padding: 10px 20px; /* Adjusted padding */
  max-width: 160px; /* Set a max width to shorten the button */
  font: 700 20px/4px "Darker Grotesque", sans-serif;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  overflow: hidden;
  position: relative;
  text-decoration: none !important;
}

.HeroSection_contactButton__2CjXn:hover {
  background-color: #2c4770;
}

.HeroSection_scrolled__1mEOo .HeroSection_contactButton__2CjXn {
  background-color: #fff;
  color: #3b5a91;
}

.HeroSection_contactButton__2CjXn:hover .HeroSection_arrowIcon__39vA9 {
  transform: translateY(-3px); 
}
.HeroSection_arrowIcon__39vA9 {
  width: 39px;
  height: 39px;
  transition: transform 0.3s ease;
}


.HeroSection_scrolled__1mEOo .HeroSection_arrowIcon__39vA9 {
  width: 30px;
  height: 30px;
}

.HeroSection_contactButton__2CjXn:hover .HeroSection_arrowIcon__39vA9 {
  transform: translateX(10px); /* Moves the icon slightly to the right on hover */
}

.HeroSection_strongText__2XaYf {
  color: #3b5a91; /* Button color */
  font-style: italic; /* Italic style */
}


@media (max-width: 991px) {
  .HeroSection_heroSection__2fIfx {
    flex-direction: column;
    margin: 0 auto; /* Center the section */
    padding: 30px; /* Equal padding on all sides */
    grid-gap: 20px;
    gap: 20px; /* Consistent spacing between elements */
    align-items: center;
    text-align: center;
  }

  .HeroSection_content__3hYf8 {
    margin: 0 auto; /* Center content horizontally */
    padding: 0 30px; /* Add equal padding on both sides */
    text-align: center; /* Center-align text */
    max-width: 800px; /* Restrict content width for readability */
    box-sizing: border-box;
  }

  .HeroSection_title__3EJSI {
    font-size: 48px;
    line-height: 54px;
    text-align: left;
    margin: 0 auto 30px; /* Center title and add bottom margin */
max-width: 88%;
  }

  .HeroSection_description__2j7e6 {
    margin: 0 auto 15px; /* Center description and add bottom margin */
    font-size: 20px;
    line-height: 30px;
    max-width: 600px; /* Restrict width for readability */
    text-align: left;
  }

  .HeroSection_imageWrapper__KZvGG {
    margin: 0 auto; /* Remove left margin and center image */
    padding: 0; /* Reset padding */
    max-width: 500px; /* Restrict image size */
  }

  .HeroSection_contactButton__2CjXn {
    margin: 20px auto 0; /* Center button with top margin */
    padding: 10px 20px;
    font-size: 18px;
  }
}


/* Mobile adjustments */
@media (max-width: 600px) {
  .HeroSection_heroSection__2fIfx {
    padding: 15px 5px;
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-right: 20px;
  }

  .HeroSection_imageWrapper__KZvGG {
    width: 100%;
    max-width: 100%; /* Ensures image takes full width on mobile */
    margin-bottom: 10px;
  }

  .HeroSection_heroImage__2PAcj {
    border-radius: 0; /* Remove rounded corners for better fit */
    width: 90%;
    height: auto;
    margin-right: 10px;
  }

  .HeroSection_content__3hYf8 {
    padding: 0 15px;
    margin: 0;
  }

  .HeroSection_title__3EJSI {
    font-size: 34px;
    line-height: 42px;
    text-align: left;
  }

  .HeroSection_description__2j7e6 {
    margin-top: 15px;
    font-size: 18px;
    line-height: 26px;
    text-align: left;
  }

  .HeroSection_contactButton__2CjXn {
    padding: 8px 14px;
    font-size: 18px;
    margin-top: 20px;
    margin-left: 60px;
    border-radius: 50px;
  }

  .HeroSection_arrowIcon__39vA9 {
    width: 28px;
  }

  .HeroSection_separatorWrapper__1Y4si {
    width: 100vw;
    left: 0;
    bottom: -10px;
  }

  .HeroSection_separator__3XZfy {
    width: 100vw;
    max-width: 100%;
    height: auto;
  }
}
.OurStory_ourStory__3TQd7 {
  background-color: #e3ecf6;
  border-radius: 10px;
  padding: 82px 80px;
  margin-top: -10px; /* Use negative margin to overlap the separator */
  margin-bottom: -10px;
  width: 100%;
  max-width: 1360px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 40px;
  gap: 40px;
  position: relative;
}

.OurStory_title__6vfkt {
  color: #3b5a91;
  font: 500 64px/1 Montserrat, sans-serif;
  flex: 1 1 40%;
  max-width: 400px;
  padding-right: -10px; /* Reduced padding */
  margin-bottom: 5px; /* Optionally, add a small bottom margin */
}

.OurStory_description__2OCur {
  color: #3b5a91;
  font: 400 22px/31px Inter, sans-serif;
  flex: 1 1 60%;
  max-width: 700px;
  padding-left: 0; /* Removed padding to reduce space */
  margin-top: 0; /* Reduced margin to close the gap */
}

.OurStory_subtitle__15B2s {
  color: #4e87c7;
  font: 500 40px/1 Montserrat, sans-serif;
  margin-top: 40px;
  margin-bottom: 0px;
  text-align: center;
  width: 100%;
}

.OurStory_videoWrapper__22OjK {
  position: relative;
  border-radius: 10px;
  min-height: 600px;
  width: 100%;
  max-width: 1204px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
 margin-right: 40px;
}

.OurStory_youtubeVideo__KIO8H {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 40px); /* Subtract 40px for 20px padding on each side */
  height: calc(100% - 40px); /* Subtract 40px for 20px padding on top and bottom */
  padding: 20px; /* Padding around the video */
  border: none;
  box-sizing: border-box; /* Ensures padding is included within dimensions */
}


.OurStory_gradientOverlay__2uvMJ {
  position: absolute;
  top: -80px;
  left: -60px;
  width: 400px;
  height: 400px;
  background-image: url(/static/media/Vector.25493852.png);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.8;
  border-radius: 20px;
  z-index: -1;
}

/* Media Queries */
@media (max-width: 991px) {

  .OurStory_title__6vfkt, .OurStory_description__2OCur {
    max-width: 100%;
    padding: 0;
    text-align: center;
  }

  .OurStory_title__6vfkt {
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 15px;
  }

  .OurStory_description__2OCur {
    font-size: 18px;
    line-height: 1.5;
    margin-top: 15px;
  }

  .OurStory_subtitle__15B2s {
    font-size: 28px;
    margin-top: 30px;
    text-align: center;
  }

  .OurStory_ourStory__3TQd7 {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align content */
    justify-content: center; /* Center-align vertically */
    padding: 30px 10px;
    max-width: 100%; /* Full-width */
    grid-gap: 20px;
    gap: 20px;
  }

  .OurStory_videoWrapper__22OjK {
    display: flex;
    align-items: center; /* Center-align content vertically */
    justify-content: center; /* Center-align content horizontally */
    width: 100%; /* Occupy full width */
    max-width: 90%; /* Optional: Limit max width */
    min-height: 350px; /* Ensure minimum height */
    box-sizing: border-box; /* Include padding and borders in width */
  }

  .OurStory_youtubeVideo__KIO8H {
    width: 100%; /* Adjust video to fill container */
    height: 100%; /* Adjust height */
  }

  .OurStory_gradientOverlay__2uvMJ {
    top: -20px;
    left: -20px;
    width: 200px;
    height: 200px;
    opacity: 0.7;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .OurStory_ourStory__3TQd7 {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align content horizontally */
    justify-content: center; /* Center-align content vertically */
    padding: 0; /* Remove extra paddings */
    grid-gap: 30px;
    gap: 30px; /* Consistent spacing between items */
    max-width: 100%; /* Ensure it spans the full width */
  }

  .OurStory_videoWrapper__22OjK {
    display: flex;
    align-items: center; /* Vertically center the video */
    justify-content: center; /* Horizontally center the video */
    width: 100%; /* Use full width */
    max-width: 80%; /* Limit the maximum width for better aesthetics */
    min-height: 450px; /* Slightly increase the height for iPad Pro */
    box-sizing: border-box; /* Ensure padding and borders are included */
  }

  .OurStory_youtubeVideo__KIO8H {
    width: 100%; /* Stretch video to fill container */
    height: 100%; /* Stretch height to match container */
    border-radius: 10px; /* Add rounding for a polished look */
  }
}


@media (max-width: 600px) {
  .OurStory_ourStory__3TQd7 {
    padding: 20px 15px;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 0px;
    align-items: center; /* Center the content in .ourStory */
  }

  .OurStory_title__6vfkt {
    font-size: 32px;
    line-height: 1.2;
    margin-bottom: 10px;
    text-align: center;
  }

  .OurStory_description__2OCur {
    font-size: 16px;
    line-height: 1.4;
    margin-top: 10px;
    text-align: left;

  }

  .OurStory_subtitle__15B2s {
    font-size: 24px;
    margin-top: 25px;
    text-align: center;
  }

  .OurStory_videoWrapper__22OjK {
    min-height: 250px;
    width: 100%;
    max-width: 90%; /* Limit width for better alignment */
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    margin: 0 auto; /* Center the .videoWrapper */
  }

  .OurStory_youtubeVideo__KIO8H {
    width: 100%;
    height: 100%;
  }

  .OurStory_gradientOverlay__2uvMJ {
    top: -10px;
    left: -10px;
    width: 120px;
    height: 120px;
    border-radius: 10px;
  }
}

/* Tablet Mode (up to 991px) */
@media (max-width: 991px) {
  .OurStory_ourStory__3TQd7 {
    padding: 30px 10px; /* Reduced padding */
    max-width: 100%; /* Occupies full screen width */
    margin-top: -10px;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
  }

  .OurStory_title__6vfkt, .OurStory_description__2OCur {
    max-width: 90%;
    padding: 0;
    text-align: left;
  }

  .OurStory_title__6vfkt {
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 15px;
  }

  .OurStory_description__2OCur {
    font-size: 18px;
    line-height: 1.5;
    margin-top: 15px;
  }

  .OurStory_subtitle__15B2s {
    font-size: 28px;
    margin-top: 30px;
    text-align: center;
  }

  .OurStory_videoWrapper__22OjK {
    min-height: 350px;
    max-width: 90%; /* Full width on tablet */
  }

  .OurStory_gradientOverlay__2uvMJ {
    top: -20px;
    left: -20px;
    width: 200px;
    height: 200px;
    opacity: 0.7;
  }
}

/* Large Screen Mode (1200px and above) */
@media (min-width: 1200px) {
  .OurStory_ourStory__3TQd7 {
    padding: 60px 40px; /* Adjusted padding for large screens */
    max-width: 100%; /* Occupies full screen width */
    margin-top: -10px;
    grid-gap: 40px;
    gap: 40px;
    align-items: flex-start; /* Align content to the start */
    justify-content: center;
  }

  .OurStory_title__6vfkt {
    font-size: 64px;
    line-height: 1;
    margin-bottom: 20px;
  }

  .OurStory_description__2OCur {
    font-size: 24px;
    line-height: 1.6;
    max-width: 800px; /* Allows some width restriction on larger screens */
    padding-left: 0; /* No padding on left */
  }

  .OurStory_subtitle__15B2s {
    font-size: 32px;
    margin-top: 40px;
    text-align: center;
  }

  .OurStory_videoWrapper__22OjK {
    min-height: 600px;
    max-width: 100%; /* Occupies full width on large screens */
  }

  .OurStory_gradientOverlay__2uvMJ {
    top: -80px;
    left: -60px;
    width: 400px;
    height: 400px;
    opacity: 0.8;
  }
}

.AllianceStandard_allianceStandard__2kZWs {
  margin-top: 109px;
  width: 100%;
  max-width: 1281px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
}

.AllianceStandard_allianceStandardContent__2zBfd {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 60px;
}

.AllianceStandard_title__3m4X3 {
  color: #3b5a91;
  font-size: 48px;
  line-height: 54px;
  font-weight: 500;
  margin-right: 20px;
  flex: 1 1;
}

.AllianceStandard_description__2e5Kt {
  color: #3b5a91;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  flex: 2 1;
}

.AllianceStandard_standardsAndImage__2EBzK {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 40px;
  gap: 40px;
  margin-bottom: 60px;
}

.AllianceStandard_standardsContainer__2fkr_ {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
  flex: 1 1;
}

.AllianceStandard_standardItem__2TeOa {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}

.AllianceStandard_standardHeader__1tFFX {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AllianceStandard_standardTitle__nohnw {
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
  color: #1e1e1e;
  margin-right: 20px;
}

.AllianceStandard_standardIcon__ZyNp_ {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.AllianceStandard_standardDescription__3tyt0 {
  font-size: 18px;
  line-height: 25px;
  font-weight: 300;
  color: #1e1e1e;
  margin-top: 20px;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.AllianceStandard_teamImage__3zsYj {
  width: 100%;
  max-width: 500px;
  border-radius: 0 160px 0 160px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  flex: 1 1;
  margin-left: 40px;
}

/* MOBILE VIEW STYLES */
@media (max-width: 991px) {
  .AllianceStandard_allianceStandard__2kZWs {
    margin-top: 60px;
    padding: 0 10px;
  }

  .AllianceStandard_allianceStandardContent__2zBfd {
    flex-direction: column;
    align-items: center;
  }

  .AllianceStandard_title__3m4X3 {
    font-size: 30px;
    line-height: 42px;
    margin-right: 0;
    max-width: 95%;
    text-align: center;
  }

  .AllianceStandard_description__2e5Kt {
    font-size: 18px;
    line-height: 26px;
    margin-top: 20px;
    max-width: 90%;
    text-align: left;
  }

  .AllianceStandard_standardsAndImage__2EBzK {
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    max-width: 90%;
    margin-left: 10px;
  }

  .AllianceStandard_standardsContainer__2fkr_ {
    margin-right: 0;
    margin-bottom: 40px;
  }

  .AllianceStandard_standardTitle__nohnw {
    font-size: 24px; /* Decreased font size for mobile */
    line-height: 30px;
    margin-right: 10px;
  }

  .AllianceStandard_standardIcon__ZyNp_ {
    width: 30px; /* Reduced size for icons */
    height: 30px;
  }

  .AllianceStandard_standardDescription__3tyt0 {
    font-size: 16px; /* Adjusted font size for readability */
    line-height: 22px;
    padding: 10px 0; /* Added padding for better spacing */
  }

  .AllianceStandard_teamImage__3zsYj {
    max-width: 100%;
    margin-left: 0;
    margin-top: 40px;
    width: 90%; /* Ensure the image fits within the screen */
  }

  .AllianceStandard_standardItem__2TeOa {
    padding-bottom: 15px; /* Adjust padding to reduce spacing */
    border-bottom: 1px solid #eee; /* Subtle separation */
  }
}

.OurValues_ourValues__1TRIh {
  margin-top: 100px;
  width: 100%;
  max-width: 1200px; /* Limit max-width for centered alignment */
  padding: 0 20px; /* Consistent padding on both sides */
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box; /* Include padding in the width */
}

.OurValues_title__3t25v {
  color: #1e1e1e;
  font: 350 70px/1 Montserrat, sans-serif;
  text-align: center;
  margin-bottom: 50px; /* Add space between title and grid */
}

.OurValues_valuesContainer__3HMZT {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3-column grid for large screens */
  grid-gap: 20px;
  gap: 20px; /* Consistent gap between cards */
  margin-top: 20px;
}

.OurValues_valueCard__2nIrW {
  background-color: #eff1f7;
  border-radius: 20px; /* More subtle rounding */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.OurValues_valueCard__2nIrW:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.OurValues_valueIcon__eu7yY {
  margin-top: 15px;
  margin-bottom: 20px;
  width: 50px;
  height: 50px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.OurValues_valueCard__2nIrW:hover .OurValues_valueIcon__eu7yY {
  transform: scale(1.1);
}

.OurValues_valueTitle__2_4rc {
  color: #1e1e1e;
  font: 600 24px/1.2 Montserrat, sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}

.OurValues_valueDescription__2lXa7 {
  color: #1e1e1e;
  font: 400 16px/1.6 Inter, sans-serif;
  text-align: left;
  margin-top: 10px;
}

/* Tablet Adjustments */
@media (max-width: 991px) {
  .OurValues_ourValues__1TRIh {
    margin-top: 50px;
    padding: 0 15px;
  }

  .OurValues_title__3t25v {
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 30px;
  }

  .OurValues_valuesContainer__3HMZT {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablet */
    grid-gap: 20px;
    gap: 20px;
  }

  .OurValues_valueCard__2nIrW {
    padding: 20px;
  }

  .OurValues_valueIcon__eu7yY {
    width: 40px;
    height: 40px;
  }

  .OurValues_valueTitle__2_4rc {
    font-size: 20px;
  }

  .OurValues_valueDescription__2lXa7 {
    font-size: 14px;
  }
}

/* Mobile Adjustments */
@media (max-width: 600px) {
  .OurValues_ourValues__1TRIh {
    padding: 0 10px;
  }

  .OurValues_title__3t25v {
    font-size: 32px;
  }

  .OurValues_valuesContainer__3HMZT {
    grid-template-columns: 1fr; /* 1 column on mobile */
    grid-gap: 15px;
    gap: 15px;
  }

  .OurValues_valueCard__2nIrW {
    padding: 15px;
  }

  .OurValues_valueIcon__eu7yY {
    width: 30px;
    height: 30px;
  }

  .OurValues_valueTitle__2_4rc {
    font-size: 18px;
  }

  .OurValues_valueDescription__2lXa7 {
    font-size: 14px;
    margin: 5px 10px;
  }
}

.TeamMembers_teamSection__1I6lM {
  margin-top: 30px;
  padding: 20px;
}

.TeamMembers_sectionTitle__2ZlZX {
  text-align: center;
  
  margin-bottom: 120px;
  font-family: 'Montserrat', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 70px;
line-height: 100%;
}

.TeamMembers_teamGrid__V-MZ- {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  grid-gap: 20px;
  gap: 20px; /* Adjusted for better fit */
  max-width: 1200px; /* Adjusted max-width */
  margin: 0 auto;
  position: relative;
}

/* Column Styling */
.TeamMembers_column1__1P68a,
.TeamMembers_column2__1iTop,
.TeamMembers_column3__1f72b,
.TeamMembers_column4__Ilpby {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px; /* Consistent gap between cards */
}

.TeamMembers_column2__1iTop {
  margin-top: -60px; /* Adjusted stagger effect */
}

.TeamMembers_column4__Ilpby {
  margin-top: -60px; /* Adjusted stagger effect */
}

/* Team Member Styling */
.TeamMembers_teamMember__2iq7E {
  width: 260px; /* Slightly reduced width */
  height: 360px; /* Slightly reduced height */
  background-color: #f0f0f0;
  border-radius: 20px;
  display: flex;
  align-items: flex-end; /* Align content to the bottom */
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 4px 12px rgba(246, 242, 242, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition added */
  margin: 0 auto;
  margin-bottom: 20px; /* Consistent vertical spacing */
  background-size: cover; /* Fit the image to the card */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent image repeat */
}

.TeamMembers_teamMember__2iq7E:hover {
  transform: translateY(-5px) scale(1.02); /* Slight zoom and lift effect */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Enhance box-shadow on hover */
}

.TeamMembers_teamMember1__-K6vo {
  background-image: url('/images/Jatin.png');
}

.TeamMembers_teamMember2__2dVzx {
  background-image: url('https://cdn.builder.io/api/v1/image/assets/TEMP/3f8d28cb226cc5e123d7569419962ab465c58ee696e3f3d606f4f9a9a0f45dd0?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f');
}

.TeamMembers_teamMember3__1RhQs {
  background-image: url('/images/Sukhpreet.png');
}

.TeamMembers_teamMember4__1rE-m {
  background-image: url('https://cdn.builder.io/api/v1/image/assets/TEMP/65d705b5d221becd47c7f197cb1c2649733dc1acf5d39b7f6fdd88bd4115294c');
}

.TeamMembers_teamMember5__2f56_ {
  background-image: url('/images/Nestor.png');
}

.TeamMembers_teamMember6__1QAW9 {
  background-image: url('https://cdn.builder.io/api/v1/image/assets/TEMP/cd29c35a12cca4d43987286bfa671ae895816ded44b564b87113800f66c847a4');
}

.TeamMembers_teamMember7__1rS6A {
  background-image: url('/images/LuisHR.png');
}

/* Text Overlay */
.TeamMembers_overlay__xLItx {
  position: absolute;
  bottom: 0;
  width: 90%;
  color: #fff;
  text-align: left;
  padding: 20px 0;
}

.TeamMembers_serviceIcon__3splC {
  width: 24px; /* Set icon width */
  height: 24px; /* Set icon height */
  object-fit: contain; /* Maintain aspect ratio */
}

.TeamMembers_name__3UMTo {
  font-size: 18px;
  font-weight: 600;
  margin: 15px 10px 10px;

}

.TeamMembers_position__OWlNT {
  font-size: 16px;
  margin: 15px 10px 10px;
}

/* Testimonial Styling */
.TeamMembers_testimonial__3szeo {
  width: 260px;
  height: 360px;
  background-color: #d4f8c4;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition added */
  margin: 0 auto;
  margin-bottom: 20px;
}

.TeamMembers_testimonial__3szeo:hover {
  transform: translateY(-5px) scale(1.02); /* Slight zoom and lift effect */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Enhance box-shadow on hover */
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .TeamMembers_teamGrid__V-MZ- {
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-gap: 15px;
    gap: 15px; /* Reduced gap for smaller screens */
  }

  .TeamMembers_column1__1P68a,
  .TeamMembers_column2__1iTop,
  .TeamMembers_column3__1f72b,
  .TeamMembers_column4__Ilpby {
    grid-gap: 15px;
    gap: 15px; /* Adjusted gap for smaller screens */
  }

  .TeamMembers_teamMember__2iq7E,
  .TeamMembers_testimonial__3szeo{
    width: 100%;
    height: auto;
    margin-top: 0; /* Remove stagger on smaller screens */
  }
}

@media (max-width: 767px) {
  .TeamMembers_teamGrid__V-MZ- {
    grid-template-columns: 1fr; /* 1 column */
    grid-gap: 10px;
    gap: 10px;
  }

  .TeamMembers_teamMember__2iq7E,
  .TeamMembers_testimonial__3szeo{
    width: 100%;
    height: auto;
  }

  .TeamMembers_sectionTitle__2ZlZX {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.TeamMembers_teamSection__1I6lM {
  margin-top: 30px;
  padding: 20px;
}

.TeamMembers_sectionTitle__2ZlZX {
  text-align: center;
  margin-bottom: 120px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  line-height: 100%;
}

.TeamMembers_teamGrid__V-MZ- {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default for desktop: 4 columns */
  grid-gap: 20px;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Team Member Styling */
.TeamMembers_teamMember__2iq7E {
  width: 100%;
  max-width: 260px;
  height: 360px;
  background-color: #f0f0f0;
  border-radius: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 4px 12px rgba(246, 242, 242, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 0 auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.TeamMembers_teamMember__2iq7E:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .TeamMembers_teamSection__1I6lM {
    padding: 10px;
  }

  .TeamMembers_sectionTitle__2ZlZX {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .TeamMembers_teamGrid__V-MZ- {
    display: flex;
    flex-direction: column; /* Ensure single-column layout */
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    width: 100%; /* Full width */
  }

  .TeamMembers_teamMember__2iq7E {
    width: 100%; /* Full width on mobile */
    max-width: 280px;
    margin: 0 auto; /* Center align each card */
  }

  .TeamMembers_column1__1P68a,
  .TeamMembers_column2__1iTop,
  .TeamMembers_column3__1f72b,
  .TeamMembers_column4__Ilpby {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px; /* Consistent gap between cards */
    align-items: center; /* Center align all columns */
    width: 100%; /* Full width on mobile */
    margin: 0; /* Remove any offset margin */
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .TeamMembers_teamSection__1I6lM {
    padding: 20px 15px; /* Equal padding on both sides */
    margin-top: 30px; /* Add margin on top */
  }

  .TeamMembers_sectionTitle__2ZlZX {
    font-size: 36px; /* Adjust title font size */
    margin-bottom: 80px; /* Space below the title */
    text-align: center;
  }

  .TeamMembers_teamGrid__V-MZ- {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    grid-gap: 10px;
    gap: 10px; /* Reduce or set to 0 to remove space between columns */
    width: calc(100% - 30px); /* Subtract padding to make space equal on both sides */
    max-width: 900px; /* Limit the grid's width */
    margin: 0 auto; /* Center the grid */
  }

  .TeamMembers_teamMember__2iq7E {
    width: 100%; /* Let the grid define the width */
    height: auto; /* Maintain the card's height */
    aspect-ratio: 3 / 4; /* Keep consistent proportions */
    background-color: #f0f0f0; /* Background for cards */
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    overflow: hidden; /* Clip overflowing content */
  }

  .TeamMembers_teamMember__2iq7E img {
    width: 100%; /* Ensure images fit the card width */
    height: 100%; /* Stretch to cover height */
    object-fit: cover; /* Maintain aspect ratio for images */
    border-radius: 15px; /* Match the card's corners */
  }

  .TeamMembers_hiringBox__2Qm44 {
    grid-column: span 2; /* Span across both columns */
    margin-top: 20px; /* Add space above the hiring box */
    width: calc(100% - 30px); /* Ensure hiring box aligns with grid */
  }
}

.HiringBox_hiringBox__2pjds {
  border-radius: 10px;
  background-color: #3b5a91; /* Background color for the box */
  display: flex;
  flex-direction: column;
  padding: 35px 20px;
  color: #fff; /* White text for contrast */
  align-items: flex-start; /* Align items to the left */
  width: 100%; /* Full width for responsiveness */
  max-width: 800px; /* Restrict maximum width for balance */
  margin: 20px auto; /* Center the box with vertical spacing */
  box-sizing: border-box; /* Ensure padding doesn't add to the width */
}

.HiringBox_hiringTitle__2Ehfp {
  font-size: 28px; /* Adjust font size for better readability */
  font-weight: 600; /* Bold for emphasis */
  line-height: 1.2; /* Improved line height */
  text-align: left;
  margin-bottom: 10px; /* Add spacing below the title */
}

.HiringBox_divider__2_6sE {
  margin-top: 16px;
  height: 1px;
  border: 1px solid #fff; /* Subtle white divider */
  width: 90%; /* Restrict width for balance */
}

.HiringBox_hiringText__3TPvz {
  font-size: 16px; /* Slightly smaller text for readability */
  font-weight: 400;
  line-height: 1.6; /* Improved line height for better readability */
  margin-top: 20px; /* Spacing above the text */
}

.HiringBox_hiringButton__24aiy {
  border-radius: 44px;
  background-color: #fff; /* White button background */
  align-self: stretch; /* Stretch to match the container width */
  display: inline-flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; /* Space between text and icon */
  padding: 10px 20px;
  color: #3b5a91; /* Blue text for button */
  font-size: 16px; /* Button text size */
  font-weight: bold;
  text-decoration: none;
  white-space: nowrap; /* Prevent text wrapping */
  margin-top: 20px; /* Spacing above the button */
  justify-content: center; /* Center-align button content */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effect */
  overflow: hidden; /* Prevent content overflow */
}

.HiringBox_hiringButton__24aiy:hover {
  background-color: #2c4770; /* Slightly darker blue on hover */
  color: #fff; /* White text on hover */
}

.HiringBox_buttonIcon__3a805 {
  width: 24px; /* Icon size */
  height: 24px;
  object-fit: contain;
}

.HiringBox_hiringButton__24aiy:hover .HiringBox_buttonIcon__3a805 {
  transform: translateX(5px); /* Subtle icon movement on hover */
  transition: transform 0.3s ease; /* Smooth transition */
}

/* Tablet Adjustments */
@media (max-width: 991px) {
  .HiringBox_hiringBox__2pjds {
    padding: 30px 15px; /* Reduce padding for smaller screens */
    margin: 20px auto; /* Center the box */
  }

  .HiringBox_hiringTitle__2Ehfp {
    font-size: 24px; /* Adjust title size for tablets */
  }

  .HiringBox_hiringText__3TPvz {
    font-size: 15px; /* Slightly smaller text for readability */
  }

  .HiringBox_hiringButton__24aiy {
    font-size: 14px; /* Adjust button text size */
    padding: 10px 20px; /* Ensure comfortable padding */
    align-self: center;
    max-width: 200px; /* Prevent the button from stretching */
    width: 100%; /* Make it responsive to container width */
    border-radius: 36px; /* Slightly reduce border radius */
  }
}

/* Mobile (Same as Tablet) */
@media (max-width: 600px) {
  .HiringBox_hiringBox__2pjds {
    padding: 30px 15px; /* Match tablet padding */
    margin: 20px auto; /* Center the box */
  }

  .HiringBox_hiringTitle__2Ehfp {
    font-size: 24px; /* Match tablet title size */
    text-align: left; /* Align text to the left */
  }

  .HiringBox_hiringText__3TPvz {
    font-size: 15px; /* Match tablet text size */
    line-height: 1.6; /* Match tablet line height */
    text-align: left; /* Align text to the left */
  }

  .HiringBox_hiringButton__24aiy {
    font-size: 14px; /* Match tablet button text size */
    padding: 10px 20px; /* Match tablet button padding */
    max-width: 250px; /* Match tablet max width */
    width: 100%; /* Ensure button spans full width */
    border-radius: 36px; /* Match tablet border radius */
  }

  .HiringBox_buttonIcon__3a805 {
    width: 20px; /* Match tablet icon size */
    height: 20px;
  }
}
/* Join Team Section Container */
.JoinTeam_joinTeam__dY80d {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1247px;
  margin: 144px auto 180px;
  position: relative;
  margin: 160px auto 80px; /* Consistent top and bottom margin */
  grid-gap: 80px;
  gap: 80px; /* Space between content and testimonial */
  padding: 0 20px;
  transition: padding 0.3s ease;
}

/* Content Container */
.JoinTeam_content__2aESV {
  flex: 1 1;
  position: relative;
  z-index: 1;
  padding-right: 20px;
}

.JoinTeam_title__1qc21 {
  color: #1e1e1e;
  font: 500 50px/1.2 'Inter', sans-serif;
  margin-bottom: 20px;
}

.JoinTeam_description__3DnxA {
  color: #1e1e1e;
  font: 400 20px/1.5 'Inter', sans-serif;
  margin-top: 32px;
  max-width: 80%;
}

.JoinTeam_ellipseBackground__24fG9 {
  position: absolute;
  width: 425px;
  height: 425px;
  left: calc(50% - 212.5px); 
  top: -30px; 
  background: #ECFF9E;
  filter: blur(47.5px);
  border-radius: 50%;
  z-index: -1;
  transition: padding 0.3s ease; /* Adds smooth padding transition */
}


.JoinTeam_testimonialSection__1p_Mq {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1247px;
  margin: 144px auto 0;
  position: relative;
  grid-gap: 100px;
  gap: 100px; /* Adds space between content and card */
  transition: padding 0.3s ease; /* Adds smooth padding transition */
  padding: 0 20px; /* Adds side padding */
}

.JoinTeam_testimonialContent__1ZGYm {
  width: 50%;
  position: relative;
  z-index: 1;
  padding-right: 20px; /* Adds right padding */
}

.JoinTeam_testimonialTitle__22WqK{
  max-width: 80%;
}

.JoinTeam_ellipseBackground__24fG9 {
  position: absolute;
  width: 425px;
  height: 425px;
  left: calc(60% - 350.5px); 
  top: -30px; 
  background: #ECFF9E;
  filter: blur(47.5px);
  border-radius: 50%;
  z-index: -1;
  transition: padding 0.3s ease; /* Adds smooth padding transition */
}

.JoinTeam_testimonialContent__1ZGYm h2 {
  color: #1e1e1e;
  font: 500 50px/1.2 'Inter', sans-serif; /* Slightly smaller font */
}

.JoinTeam_testimonialContent__1ZGYm p {
  color: #1e1e1e;
  font: 400 20px/1.5 'Inter', sans-serif; /* Slightly smaller font */
  margin-top: 32px; /* Reduced top margin */
}

.JoinTeam_testimonialCard__1VHWr {
  width: 50%;
  border-radius: 10px;
  background: linear-gradient(135deg, #e5ff9b, #d4e77e);
  padding: 46px 50px 19px;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, padding 0.3s ease;
}

.JoinTeam_testimonialCard__1VHWr:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
}

.JoinTeam_quoteOpenIcon__2vXQ7,
.JoinTeam_quoteCloseIcon__3VpIM {
  width: 52px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.JoinTeam_quoteOpenIcon__2vXQ7:hover,
.JoinTeam_quoteCloseIcon__3VpIM:hover {
  opacity: 1;
}

.JoinTeam_quoteCloseIcon__3VpIM {
  position: absolute;
  right: 20px;
  bottom: 100px;
}

blockquote {
  color: #1e1e1e;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 22px; /* Adjusted font size for better readability */
  line-height: 1.6;
  margin: 11px 0 0;
}

.JoinTeam_testimonialCard__1VHWr footer {
  margin-top: 69px;
}

.JoinTeam_testimonialCard__1VHWr cite {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #333;
  font-size: 20px;
}

.JoinTeam_testimonialCard__1VHWr footer p {
  margin-top: 9px;
  font-family: 'Inter', sans-serif;
  color: #777;
  font-size: 18px;
}

.JoinTeam_testimonialNav__2indc {
  display: flex;
  grid-gap: 14px;
  gap: 14px;
  margin-top: 35px;
}

.JoinTeam_navDot__4LfdY {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #1e1e1e;
  background-color: transparent;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.JoinTeam_navDot__4LfdY:hover {
  background-color: #c5ce5b;
  transform: scale(1.2);
}

.JoinTeam_navDot__4LfdY.JoinTeam_active__1GbLr {
  background-color: #1e1e1e;
}

@media (max-width: 991px) {
  .JoinTeam_testimonialSection__1p_Mq {
    flex-direction: column; /* Stack elements vertically */
    margin-top: 40px;
    padding: 30px; /* Adds consistent padding */
    grid-gap: 100px;
    gap: 100px; /* Adds more space between elements */

  }

  .JoinTeam_testimonialContent__1ZGYm,
  .JoinTeam_testimonialCard__1VHWr {
    width: 80%; /* Full width for mobile */
  }

  .JoinTeam_testimonialContent__1ZGYm {
    padding: 0 15px; /* Adds side padding for better spacing */
    margin-left: 30px;
    max-width: 70%;
  }

  .JoinTeam_testimonialContent__1ZGYm h2 {
    font-size: 32px; /* Reduced font size for mobile */
    line-height: 1.3;
  }

  .JoinTeam_testimonialContent__1ZGYm p {
    font-size: 16px; /* Reduced font size for mobile */
    margin-top: 20px; /* Adjusts spacing */
  }

  .JoinTeam_ellipseBackground__24fG9 {
    width: 300px; /* Adjusted size for mobile */
    height: 300px; 
    left: 40%; /* Centers background */
    top: 0; /* Aligns with content */
    transform: translateX(-50%);
  }

  .JoinTeam_testimonialCard__1VHWr {
    margin-top: 20px; /* Adds spacing between content and card */
    padding: 25px; /* Adjust padding for a more compact layout */
  }

  blockquote {
    font-size: 18px; /* Further reduced font size for better fit */
    line-height: 1.4;
  }

  .JoinTeam_testimonialCard__1VHWr footer {
    margin-top: 40px; /* Adjusts spacing for mobile */
  }

  .JoinTeam_testimonialCard__1VHWr cite {
    font-size: 18px; /* Adjusted font size for mobile */
  }

  .JoinTeam_testimonialCard__1VHWr footer p {
    font-size: 16px; /* Adjusted font size for mobile */
    margin-top: 8px;
  }

  .JoinTeam_navDot__4LfdY {
    width: 10px; /* Smaller dot size for mobile */
    height: 10px;
    margin-top: 20px; /* Adds more spacing */
  }
}

@media (max-width: 600px) {
  .JoinTeam_testimonialSection__1p_Mq {
    padding: 15px; /* Reduces padding for smaller screens */
  }

  .JoinTeam_testimonialContent__1ZGYm h2 {
    font-size: 28px; /* Further reduced font size */
  }

  .JoinTeam_testimonialContent__1ZGYm p {
    font-size: 14px; /* Smaller font for better fit */
  }

  .JoinTeam_testimonialCard__1VHWr {
    padding: 20px; /* Adjusted padding */
  }

  blockquote {
    font-size: 16px; /* Further reduced font size */
  }

  .JoinTeam_navDot__4LfdY {
    width: 8px; /* Smaller dot size */
    height: 8px;
  }
}

.AboutUs_aboutUsContainer__3qV8E {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .AboutUs_separator__8aqYS,
  .AboutUs_downSeparator__3XEHU {
      width: 100vw; /* Full width of the viewport */
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      object-fit: cover;
      margin: 0; /* Remove any margin */
  }
  
  .AboutUs_downSeparator__3XEHU {
    margin-top: 0;
      margin-bottom: -1px; /* Negative margin to eliminate any gap */
  }
 
  @media (max-width: 991px) {
    .AboutUs_aboutUsContainer__3qV8E {
      max-width: 100%;
    }
  }
.HeroSection_heroSection__3T3vg {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 121px;
  padding: 0 30px;
}

.HeroSection_imageContainer__3-VA4 {
  flex: 1 1;
  margin-top: 30px; 
  margin-left: 30px;
}

.HeroSection_heroImage__2jEzE {
  width: 90%;
  border-radius: 160px 0 160px 0;
  object-fit: cover;
  transition: transform 0.3s ease; /* Smooth zoom on hover */
}

.HeroSection_heroImage__2jEzE:hover {
  transform: scale(1.02); /* Slight zoom effect */
}

.HeroSection_contentContainer__Ua9Qh {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -15px;

}

.HeroSection_title__k1WMa {
  font: 60px/60px Montserrat, sans-serif;
  color: #1e1e1e;
  margin-bottom: 19px;
  max-width: 50%;
}

.HeroSection_description__3cMkz {
  font: 18px/26px Inter, sans-serif;
  color: #1e1e1e;
  margin-bottom: 33px;
  max-width: 95%;
}

/* Style for italic text within description */
.HeroSection_description__3cMkz em {
  color: #3b5a91;
}

.HeroSection_ctaButton__dlqv4 {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  background-color: #3b5a91;
  border: none;
  border-radius: 64px;
  color: #fff;
  font: 700 20px/4px 'Darker Grotesque', sans-serif;
  padding: 10px 20px;
  cursor: pointer;
  align-self: flex-start;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth hover effect */
}

.HeroSection_ctaButton__dlqv4:hover {
  background-color: #2a4a78; /* Darker on hover */
  
}

.HeroSection_buttonIcon__2-rbp {
  width: 39px;
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.HeroSection_ctaButton__dlqv4:hover .HeroSection_buttonIcon__2-rbp {
  transform: translateX(5px); /* Move icon to the right on hover */
}

@media (max-width: 991px) {
  .HeroSection_heroSection__3T3vg {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    padding: 0 60px;
    grid-gap: 20px;
    gap: 20px; /* Consistent gap for mobile */
  }

  .HeroSection_imageContainer__3-VA4 {
    
    max-width: 100%;
  }

  .HeroSection_heroImage__2jEzE {
    width: 100%;
    height: auto; /* Preserve aspect ratio */
    border-radius: 80px 0 80px 0; /* Maintain border-radius style */
  }

  .HeroSection_contentContainer__Ua9Qh {
    margin-top: 0px;
    width: 90%;
    text-align: center; /* Center align content */
  }

  .HeroSection_title__k1WMa {
    font-size: 25px; /* Adjusted for better readability */
    line-height: 42px;
    margin-bottom: 15px; /* Reduce bottom margin */
    max-width: 100%;
    text-align: left;
  }

  .HeroSection_description__3cMkz {
    font-size: 16px; /* Adjusted for mobile */
    line-height: 24px;
    margin-bottom: 30px; /* Reduce bottom margin */
    text-align: left;
  }

  .HeroSection_ctaButton__dlqv4 {
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 50px;
    align-self: center; /* Center align button */
  }

  .HeroSection_buttonIcon__2-rbp {
    width: 30px; /* Adjusted for mobile */
  }
}

@media (max-width: 600px) {
  .HeroSection_heroSection__3T3vg {
    padding: 0 15px;
    margin-top: 50px;
  }

  .HeroSection_imageContainer__3-VA4 {
    max-width: 350px; /* Further adjust for smaller screens */
  }

  .HeroSection_title__k1WMa {
    font-size: 30px;
    line-height: 36px;
  }

  .HeroSection_description__3cMkz {
    font-size: 14px;
    line-height: 20px;
  }

  .HeroSection_ctaButton__dlqv4 {
    padding: 8px 14px;
    font-size: 14px;
    border-radius: 44px; /* Slightly reduce border-radius */
  }

  .HeroSection_buttonIcon__2-rbp {
    width: 24px; /* Smaller icon for compact view */
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .HeroSection_heroSection__3T3vg {
    display: flex;
    flex-direction: row; /* Aligns like desktop */
    grid-gap: 30px;
    gap: 30px; /* Adjust gap between image and text */    
    padding: 0 40px 30px; /* Adjusted padding for better fit */
  }

  .HeroSection_imageContainer__3-VA4 {
    flex: 1 1;
    margin: 0; /* Remove extra margins */
    max-width: 50%; /* Ensure image takes appropriate width */
    margin-top: 40px;
  }

  .HeroSection_heroImage__2jEzE {
    width: 100%; /* Full width for the image */
    height: auto; /* Maintain aspect ratio */
    border-radius: 160px 0 160px 0; /* Keep rounded corners */
    object-fit: cover;
  }

  .HeroSection_contentContainer__Ua9Qh {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left; /* Left align text */
  }

  .HeroSection_title__k1WMa {
    font: 54px/60px Montserrat, sans-serif; /* Slightly reduced font size */
    color: #1e1e1e;
    margin-bottom: 24px; /* Consistent spacing */
    max-width: 90%; /* Ensure the title doesn't overflow */
  }

  .HeroSection_description__3cMkz {
    font: 18px/26px Inter, sans-serif; /* Adjusted font size */
    color: #1e1e1e;
    margin-bottom: 30px; /* Reduce gap below */
    max-width: 100%; /* Align width with text */
  }

  .HeroSection_description__3cMkz em {
    color: #3b5a91; /* Highlighted text color */
  }

  .HeroSection_ctaButton__dlqv4 {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px; /* Space between text and icon */
    background-color: #3b5a91;
    border: none;
    border-radius: 64px;
    color: #fff;
    font: 700 18px/24px 'Darker Grotesque', sans-serif; /* Slightly smaller font */
    padding: 12px 20px; /* Adjusted padding */
    align-self: flex-start;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
  }

  .HeroSection_ctaButton__dlqv4:hover {
    background-color: #2a4a78; /* Darker shade on hover */
  }

  .HeroSection_buttonIcon__2-rbp {
    width: 30px; /* Adjusted icon size */
    transition: transform 0.3s ease; /* Smooth hover effect */
  }

  .HeroSection_ctaButton__dlqv4:hover .HeroSection_buttonIcon__2-rbp {
    transform: translateX(5px); /* Move icon slightly right on hover */
  }
}


.ServicesSection_servicesSection__1iRoz {
  display: flex;
  margin-top: 146px;
  padding: 0 90px; /* Increase padding for more space on both sides */
 }
  
  .ServicesSection_sectionTitle__3nlDW {
    flex: 0 0 30%;
    font: 500 40px/42px Montserrat, sans-serif;
    color: #1e1e1e;
    margin-right: 20px;
    padding: 20px;
    position: relative;
    z-index: 1;
  }
  
  .ServicesSection_sectionTitle__3nlDW::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 80%;
    background-image: url(/static/media/bmellipse.a529d790.png); /* Replace with your gradient PNG path */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50% / 70%; /* Adjust these values to create an ellipse shape */
    z-index: -1;
    transform: scale(1.2); /* Optional: enlarge to better frame the text */
  }
  
  
  .ServicesSection_servicesContainer__3wJpC {
    flex: 0 0 70%;
    background-color: #e5ff9b;
    border-radius: 10px;
    padding: 52px 54px;
    margin-right: 20px; /* Add margin to the right for space */
    margin-left: 20px;
  }
  
  .ServicesSection_servicesSubtitle__CVeF1 {
    font: 700 22px/31px Inter, sans-serif;
    color: #1e1e1e;
    margin-bottom: 19px;
  }
  
  .ServicesSection_servicesList__1tlkJ {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
    grid-gap: 20px;
    gap: 20px;
    list-style-type: none;
    padding: 0;
  }
  
  .ServicesSection_serviceItem__1q-n4 {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    font: 400 18px/28px Inter, sans-serif;
    color: #1e1e1e;
  }
  
  
  .ServicesSection_serviceIcon__W8RDH {
    width: 24px;
    height: 24px;
  }
  
  @media (max-width: 991px) {
    .ServicesSection_sectionTitle__3nlDW::before {
      width: 250px; /* Adjust width for tablets */
      height: 250px; /* Adjust height for tablets */
      transform: translate(-50%, -50%) scale(1.3); /* Slightly smaller scale */
      top: 50%; /* Keep centered vertically */
      left: 40%; /* Keep centered horizontally */
    }
    .ServicesSection_servicesSection__1iRoz {
      flex-direction: column;
      margin-top: 40px;
      padding: 0 20px;
    }
  
    .ServicesSection_sectionTitle__3nlDW {
      margin-bottom: 20px;
    }
  
    .ServicesSection_servicesContainer__3wJpC {
      padding: 30px 20px;
    }
  }

  @media (max-width: 767px) { /* Adjust breakpoint as needed */
  
  .ServicesSection_sectionTitle__3nlDW {
    text-align: center; /* Center the text */
    margin: 0 auto; /* Center the title container */
    position: relative;
    margin-bottom: 30px;
    margin-top: 30px;
    max-width: 100%;
  }
  .ServicesSection_sectionTitle__3nlDW::before {
    width: 400px; /* Adjust width for mobile */
    height: 400px; /* Adjust height for mobile */
    transform: translate(-50%, -50%) scale(1); /* Keep original size */
    top: 40%; /* Keep centered vertically */
    left: 50%; /* Keep centered horizontally */
  }
    .ServicesSection_servicesList__1tlkJ {
      grid-template-columns: 1fr; /* Single column on smaller screens */
    }
  }
  
.GreenCleaningSection_greenCleaningSection__16JPk {
  display: flex;
  grid-gap: 30px;
  gap: 30px; /* Consistent gap with About Us */
  margin: 60px auto; /* Centering container with reduced top margin */
  padding: 0 40px; /* Adjusted padding */
  max-width: 1200px; /* Limit width for better alignment */
  align-items: stretch; /* Ensures even height of columns */
}

.GreenCleaningSection_imageContainer__3PIUD {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.GreenCleaningSection_greenCleaningImage__3a7qd {
  width: 100%;
  border-radius: 150px 10px 10px 10px; /* Consistent rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  height: 100%; /* Ensure it fills the container */
}

.GreenCleaningSection_contentContainer__1SJkX {
  flex: 1 1;
  background-color: #fff;
  background-image: url(/static/media/AboutusGradient.6aec8d2a.png); /* Add gradient PNG */
  background-repeat: no-repeat; /* Prevents repeating of the gradient */
  background-position: top; /* Positions the gradient at the top */
  border-radius: 10px 10px 150px 10px; /* Consistent with the About Us design */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  padding: 50px 28px; /* Reduced padding for smaller container */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content vertically */
  transition: padding 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.GreenCleaningSection_title__11Z4A {
  font: 500 36px/1.2 'Montserrat', sans-serif; /* Adjusted font size for consistency */
  color: #1e1e1e;
  margin-bottom: 20px;
  transition: font-size 0.3s ease; /* Smooth transition */
}

.GreenCleaningSection_description__1RETh {
  font: 400 16px/1.6 'Inter', sans-serif; /* Consistent font size */
  color: #1e1e1e;
  transition: font-size 0.3s ease; /* Smooth transition */
}

/* Tablet View (max-width: 991px) */
@media (max-width: 991px) {
  .GreenCleaningSection_sectionGrid__1ZxnC {
    grid-template-columns: repeat(2, 1fr); /* Two columns for tablet */
    grid-gap: 20px;
    gap: 20px; /* Spacing between cards */
    padding: 20px; /* Adjusted padding for tablet */
  }

  .GreenCleaningSection_contentWrapper__R7VxZ {
    padding: 20px; /* Reduced padding for compact spacing */
    background-color: #fff;
    background-image: url(/static/media/AboutusGradient.6aec8d2a.png); /* Ensure gradient path */
    background-repeat: no-repeat;
    background-size: cover; /* Stretch gradient to fill the container */
    background-position: center; /* Center-align gradient */
    border-radius: 10px; /* Consistent rounded corners */
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    height: auto; /* Adjust height based on content */
    overflow: hidden; /* Prevents overflow issues */
  }

  .GreenCleaningSection_sectionImage__3mfNt {
    width: 100%; /* Full width for each column */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Match contentWrapper corners */
    object-fit: cover; /* Ensure images fit properly */
  }

  .GreenCleaningSection_sectionTitle__oUP4x {
    font-size: 24px; /* Adjust font size for tablet */
    margin-bottom: 15px; /* Reduce spacing below title */
    text-align: left; /* Align text to the left */
  }

  .GreenCleaningSection_description__1RETh {
    font-size: 16px; /* Adjust text size for better readability */
    line-height: 1.5; /* Maintain comfortable spacing */
    text-align: justify; /* Improve text alignment */
  }

  .GreenCleaningSection_sectionGrid__1ZxnC .GreenCleaningSection_contentWrapper__R7VxZ:nth-child(1) {
    border-radius: 60px 10px 10px 10px; /* Rounded corners */
  }

  .GreenCleaningSection_sectionGrid__1ZxnC .GreenCleaningSection_contentWrapper__R7VxZ:nth-child(2) {
    border-radius: 10px 60px 10px 10px; /* Rounded corners */
  }

  .GreenCleaningSection_sectionGrid__1ZxnC .GreenCleaningSection_contentWrapper__R7VxZ:nth-child(4) {
    border-radius: 10px 10px 60px 10px; /* Rounded corners */
  }
}

@media (max-width: 767px) {
     .GreenCleaningSection_greenCleaningSection__16JPk {
      flex-direction: column; /* Stack vertically */
      margin-top: 40px;
      padding: 0 20px;
    }
  
    .GreenCleaningSection_contentContainer__1SJkX {
      padding: 30px 20px; /* Reduced padding for mobile */
    }
  
    .GreenCleaningSection_title__11Z4A {
      font-size: 28px; /* Adjusted for smaller screens */
    }
  
    .GreenCleaningSection_description__1RETh {
      font-size: 14px; /* Adjusted for smaller screens */
    }
  }
  
.BuildingManagement_servicePage__2dpAK {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .BuildingManagement_tailoredService__MVj_u {
    position: relative;
    color: #1e1e1e;
    text-align: center;
    font: 400 60px/72px Montserrat, sans-serif;
    margin: 80px auto;
    max-width: 68%;
    padding: 60px 40px;
    z-index: 2;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .BuildingManagement_tailoredService__MVj_u::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    height: 500px;
    background: url(/static/media/CareersGradientVector.25493852.png) no-repeat center center;
    background-size: contain;
    opacity: 0.8;
    z-index: 1;
    border-radius: 120px;
    transition: opacity 0.3s ease;
  }
  
  .BuildingManagement_tailoredService__MVj_u:hover {
    transform: scale(1.02);
    opacity: 0.95;
  }
  
  .BuildingManagement_tailoredService__MVj_u:hover::before {
    opacity: 1;
  }
  
  @media (max-width: 991px) {
    .BuildingManagement_tailoredService__MVj_u {
      max-width: 100%;
      margin: 40px auto;
      font-size: 40px;
      line-height: 53px;
      padding: 40px 20px;
    }
    
    .BuildingManagement_tailoredService__MVj_u::before {
      width: 500px;
      height: 400px;
      border-radius: 80px;
    }
  }
  
  
  
  @media (max-width: 991px) {
    .BuildingManagement_tailoredService__MVj_u {
      margin: 40px auto;
      font-size: 40px;
      line-height: 53px;
    }
  }
/* Desktop View */
.ContactForm_contactForm__1vu2D {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 440px;
  margin-top: 28px;
}

.ContactForm_label__2HQi8 {
  color: #1e1e1e;
  font: 600 16px/1.4 Inter, sans-serif;
  margin-bottom: 4px;
}

.ContactForm_input__Kw2w0,
.ContactForm_select__1WLNc {
  border-radius: 10px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 18px;
  margin-bottom: 18px;
  font: 400 16px/1.4 Inter, sans-serif;
  color: rgba(30, 30, 30, 0.75);
  width: 100%;
  border: none;
  -webkit-appearance: none;
  appearance: none;
}

.ContactForm_selectWrapper__21lio {
  position: relative;
  width: 100%;
}

.ContactForm_selectWrapper__21lio::after {
  content: '';
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background-image: url('https://cdn.builder.io/api/v1/image/assets/TEMP/c8768b4c2120bbfd9b87d50356d9017596c598c93a9bbb181047fe3b74b21e99?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f');
  background-size: contain;
  pointer-events: none;
}

.ContactForm_submitButton__3jKuF {
  display: flex;
  align-items: center;
  justify-content: center;  
  padding: 12px 20px; /* Adjust padding for a balanced look */
  max-width: 300px; /* Limit the button width */
  background-color: #3b5a91; /* Button background color */
  color: #ffffff; /* Text color */
  font: 22 'Darker Grotesque', -apple-system, Roboto, Helvetica, sans-serif;
  border: none;
  border-radius: 14px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-decoration: none; /* Remove underline for links */
}

.ContactForm_loader__DKZWl {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.ContactForm_spinner__34QxV {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #4caf50;
  animation: ContactForm_spin__2o2Vv 1s ease infinite;
}

@keyframes ContactForm_spin__2o2Vv {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Tablet Adjustments */
@media (max-width: 991px) {
  .ContactForm_contactForm__1vu2D {
    padding: 0 20px;
    max-width: 95%;
  }

  .ContactForm_label__2HQi8 {
    font-size: 15px;
    margin-bottom: 3px;
  }

  .ContactForm_input__Kw2w0,
  .ContactForm_select__1WLNc {
    padding: 16px;
    font-size: 15px;
    margin-bottom: 16px;
    width: 100%;
    box-sizing: border-box;
    -webkit-appearance: none;
    appearance: none;
  }

  .ContactForm_selectWrapper__21lio {
    width: 100%;
    position: relative;
  }

  .ContactForm_submitButton__3jKuF {
    padding: 14px 16px;
    font-size: 18px;
    width: 100%;
    box-sizing: border-box;
  }

  .ContactForm_loader__DKZWl {
    margin-top: 15px;
  }

  .ContactForm_spinner__34QxV {
    width: 30px;
    height: 30px;
  }
}

/* Mobile Adjustments */
@media (max-width: 600px) {
  .ContactForm_contactForm__1vu2D {
    padding: 0 15px;
    max-width: 95%;
  }

  .ContactForm_label__2HQi8 {
    font-size: 14px;
    margin-bottom: 3px;
  }

  .ContactForm_input__Kw2w0,
  .ContactForm_select__1WLNc {
    padding: 10px;
    font-size: 14px;
    margin-bottom: 16px;
    width: 100%;
    box-sizing: border-box;
    -webkit-appearance: none;
    appearance: none;
  }

  .ContactForm_selectWrapper__21lio {
    width: 100%;
    position: relative;
  }

  .ContactForm_selectWrapper__21lio::after {
    right: 10px;
    width: 16px;
    height: 16px;
    top: 50%;
    transform: translateY(-50%);
    background-size: contain;
  }

  .ContactForm_submitButton__3jKuF {
    padding: 14px 16px;
    font-size: 18px;
    width: 100%;
    box-sizing: border-box;
  }

  .ContactForm_loader__DKZWl {
    margin-top: 15px;
  }

  .ContactForm_spinner__34QxV {
    width: 30px;
    height: 30px;
  }
}

/* Large Screen Adjustments */
@media (min-width: 1200px) {
  .ContactForm_contactForm__1vu2D {
    max-width: 600px; /* Increase max-width for larger screens */
    margin-top: 32px;
    padding: 0 30px; /* Increase padding for larger screens */
  }

  .ContactForm_label__2HQi8 {
    font-size: 18px; /* Slightly larger font for labels */
    margin-bottom: 6px;
  }

  .ContactForm_input__Kw2w0,
  .ContactForm_select__1WLNc {
    padding: 20px; /* Add more padding for larger screens */
    font-size: 18px; /* Increase font size for better readability */
    margin-bottom: 20px; /* Increase spacing between fields */
  }

  .ContactForm_selectWrapper__21lio::after {
    right: 20px; /* Adjust icon position for larger screen */
    width: 26px; /* Larger icon for better visual balance */
    height: 26px;
  }

  .ContactForm_submitButton__3jKuF {
    padding: 18px 20px; /* Increase padding for button */
    font-size: 24px; /* Increase font size for the button text */
  }

  .ContactForm_loader__DKZWl {
    margin-top: 25px; /* Adjust margin for better alignment */
  }

  .ContactForm_spinner__34QxV {
    width: 40px; /* Increase spinner size */
    height: 40px;
  }
}


.ContactInfo_contactInfoContainer__s0XGF {
  margin-top: 48px;
  width: 100%;
  max-width: 445px;
}

.ContactInfo_infoGrid__2gr-- {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.ContactInfo_infoLabels__3St3e,
.ContactInfo_infoValues__Oqx-s {
  font: 400 20px/1.6 Inter, sans-serif;
}

.ContactInfo_infoLabels__3St3e {
  color: #1e1e1e;
  font-weight: 700;
  text-align: right;
  min-width: 100px; /* Ensure labels align consistently */
}

.ContactInfo_infoValues__Oqx-s {
  color: #1e1e1e;
  word-break: break-word;
}

.ContactInfo_email__3n6n7 {
  color: #3b5a91;
}

@media (max-width: 991px) {
  .ContactInfo_contactInfoContainer__s0XGF {
    margin-top: 40px;
    max-width: 100%;
  }

  .ContactInfo_infoLabels__3St3e,
  .ContactInfo_infoValues__Oqx-s {
    font-size: 18px;
  }
}

.SocialLinks_socialLinksContainer__1wAHb {
  margin-top: 51px;
}

.SocialLinks_followText__3TxAy {
  color: #1e1e1e;
  font: 400 18px 'Inter', sans-serif;
}

.SocialLinks_iconWrapper__3d9Q2 {
  display: flex;
  margin-top: 16px;
  grid-gap: 38px;
  gap: 38px;
}

.SocialLinks_socialIcon__39u9X {
  width: 36px;
  height: 36px;
  object-fit: contain;
  cursor: pointer; /* Make the icon cursor indicate clickability */
  z-index: 1; /* Ensure the image layer is above other potential elements */
}

@media (max-width: 991px) {
  .SocialLinks_socialLinksContainer__1wAHb {
    margin-top: 40px;
  }
}

html {
  scroll-behavior: smooth;
}


/* Main container adjustments */
.ContactUs_ContactUsContainer__3XhWh {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  overflow: hidden;
  margin-top: 5px; /* Removes default margin for cleaner layout */
}

/* Responsive padding adjustments */
@media (max-width: 991px) {
  .ContactUs_ContactUsContainer__3XhWh {
    max-width: 100%;
  }
}

/* Contact Section adjustments */
.ContactUs_contactSection__2_o-_ {
  border-radius: 10px;
  background-color: #eff1f7;
  display: flex;
  width: 100%;
  padding: 97px 70px;
  box-sizing: border-box;
  margin-top: 10px; /* Adds spacing from the top */
}

/* Center content within wrapper */
.ContactUs_contentWrapper__1Ulmt {
  width: 100%;
  max-width: 1139px;
  display: flex;
  grid-gap: 30px;
  gap: 30px; /* Slightly increased gap for better spacing */
  margin: 0 auto;
}

/* Column adjustments */
.ContactUs_infoColumn__3USqS {
  flex: 1 1;
}

.ContactUs_formColumn__1FISk {
  flex: 1 1;
}

/* Title and text styling */
.ContactUs_title__P1uJF {
  color: #1e1e1e;
  font: 400 60px/1.2 Montserrat, sans-serif; /* Adjusted for better line height */
  margin-bottom: 10px; /* Adds space below the title */
}

.ContactUs_subtitle__2yLMt {
  color: #3b5a91;
  margin-top: 10px; /* Consistent margin for better spacing */
  font: 700 22px/1.4 Inter, sans-serif;
}

.ContactUs_description__16pFM {
  color: #1e1e1e;
  margin-top: 16px; /* Reduced for better flow */
  font: 400 18px/1.6 Inter, sans-serif;
}


/* Separator adjustments */
/* Separator adjustments */
.ContactUs_separator__3c7t_ {
  width: 100vw; /* Full width of the viewport */
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
  margin-bottom: -1px; /* Remove small gap consistently */
  display: block; /* Ensure it behaves consistently on all screens */
  padding: 0; /* Remove any padding that could introduce spacing */
  border: none; /* Remove any potential borders */
}



/* Mobile responsiveness */
@media (max-width: 991px) {
  .ContactUs_contactSection__2_o-_ {
    padding: 40px 20px;
    margin-top: 10px; /* Reduced top margin for mobile */
  }

  .ContactUs_contentWrapper__1Ulmt {
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px; /* Consistent gap for stacked layout */
  }

  .ContactUs_infoColumn__3USqS,
  .ContactUs_formColumn__1FISk {
    width: 100%;
  }

  .ContactUs_title__P1uJF {
    font-size: 36px; /* Adjusted for smaller screens */
    margin-bottom: 8px; /* Reduced bottom margin */
  }

  .ContactUs_description__16pFM {
    font-size: 16px; /* Reduced font size for better readability */
    max-width: 100%;
  }

  .ContactUs_separator__3c7t_ {
    width: 100%;
    height: auto; /* Ensures proper scaling on mobile */
  
  }
}



/* Container for the Hiring Process Section */
.HiringProcess_hiringProcessContainer__1MLeb {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 1024px;
    margin: 50px auto;
    grid-gap: 30px;
    gap: 30px; /* Space between image and text container */
  }
  
  /* Image Container on the Left */
  .HiringProcess_imageContainer__2f_np {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    position: relative;
    min-height: 450px;
    overflow: hidden;
    transition: transform 0.3s; /* Smooth hover effect */
  }
  
  .HiringProcess_imageContainer__2f_np:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  
  /* Image Styling */
  .HiringProcess_processImage__2CrpC {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
  
  /* Text Content Container on the Right */
  .HiringProcess_infoContent__E-u1K {
    flex: 1 1;
    background-color: #f9f9f9;
    background-image: url(/static/media/AboutusGradient.6aec8d2a.png); /* Add gradient PNG */
    background-repeat: no-repeat;
    background-position: top;
    border-radius: 0 0 90px 0; /* Rounds only the bottom-right corner */
    padding: 30px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden; /* Keeps content within the rounded border */
  }
  
  .HiringProcess_infoContent__E-u1K:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2); /* Shadow increases on hover */
  }
  
  /* Title in the Text Container */
  .HiringProcess_title__1Ok9s {
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-weight: 500;
    color: #1e1e1e;
    margin-bottom: 20px;
  }
  
  /* Description in the Text Container */
  .HiringProcess_description__3Fz5I {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 25px;
  }
  
  /* Apply Now Button Styling */
  .HiringProcess_applyButton__z80Ia {
    background-color: #3b5a91;
    color: #fff;
    border-radius: 25px;
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    grid-gap: 8px;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .HiringProcess_applyButton__z80Ia:hover {
    background-color: #2c4770;
    transform: translateY(-3px); /* Slight upward movement on hover */
  }
  
  .HiringProcess_buttonIcon__3K3Fg {
    width: 20px;
    transition: transform 0.3s;
  }
  
  .HiringProcess_applyButton__z80Ia:hover .HiringProcess_buttonIcon__3K3Fg {
    transform: translateX(10px); /* Moves the icon slightly to the right on hover */
  }

  /* Remove bullet points from the list */
.HiringProcess_description__3Fz5I ul {
    list-style-type: none; /* Removes the bullets */
    padding-left: 0; /* Removes default padding */
    margin-left: 0; /* Aligns list items with the text */
  }
  
  /* Optional: Add spacing between list items */
  .HiringProcess_description__3Fz5I li {
    margin-bottom: 10px; /* Adds spacing between list items */
  }
  
  
  @media (max-width: 991px) {
    .HiringProcess_hiringProcessContainer__1MLeb {
      flex-direction: column;
      align-items: center;
      grid-gap: 20px;
      gap: 20px;
      margin-top: 40px;
    }
  
    .HiringProcess_imageContainer__2f_np,
    .HiringProcess_infoContent__E-u1K {
      width: 95%;
    }
  
    .HiringProcess_infoContent__E-u1K {
      padding: 20px;
    }
  
    .HiringProcess_title__1Ok9s {
      font-size: 24px;
    }
  }
  
  @media (max-width: 991px) {
    .HiringProcess_infoContent__E-u1K {
      background-size: 100% 150%; /* Resize gradient for tablet screens */
      background-position: center top; /* Center-align gradient for tablet */
      border-radius: 0 0 60px 0; /* Slightly smaller rounding for better fit */
    }
  }
  
  @media (max-width: 600px) {
  .HiringProcess_hiringProcessContainer__1MLeb {
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin: 20px auto; /* Reduce margin for compact layout */
    width: 100%; /* Full width for mobile */
  }

  .HiringProcess_imageContainer__2f_np {
    width: 90%; /* Full width for the image container */
    min-height: 300px; /* Reduce height for smaller screens */
  }

  .HiringProcess_infoContent__E-u1K {
    width: 80%; /* Reduce width for better fit */
    

    background-position: center top; /* Ensure gradient alignment */
    
  }

  .HiringProcess_title__1Ok9s {
    font-size: 20px; /* Reduce font size for better readability */
    margin-bottom: 15px; /* Adjust spacing below the title */
  }

  .HiringProcess_description__3Fz5I {
    font-size: 12px; /* Smaller font size for description */
    line-height: 1.4; /* Adjust line height */
    margin-bottom: 20px; /* Reduce bottom margin */
  }

  .HiringProcess_description__3Fz5I ul {
    margin-left: 10px; /* Add slight indentation for lists */
  }

  .HiringProcess_applyButton__z80Ia {
    font-size: 12px; /* Reduce button font size */
    padding: 6px 12px; /* Adjust padding for mobile */
    border-radius: 20px; /* Smaller button radius */
  }

  .HiringProcess_buttonIcon__3K3Fg {
    width: 16px; /* Smaller icon for button */
  }

  .HiringProcess_applyButton__z80Ia:hover .HiringProcess_buttonIcon__3K3Fg {
    transform: translateX(5px); /* Slightly smaller movement on hover */
  }

  .HiringProcess_imageContainer__2f_np:hover {
    transform: none; /* Remove hover zoom effect for mobile to avoid layout shifting */
  }
}

.featured-container {
  border: 0px solid red;
  display: flex;
  flex-direction: column;
  background-color: #EFF1F7;
  padding: 70px;
}

.featured-top {
  border: 0px solid red;
  display: flex;
  flex-direction: row;
}

.featured-image {
  display: flex;
  flex-direction: row;
  width: 50%;
  border: 0px solid red;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.featured-image img {
  border-radius: 0px 160px 0px 160px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.featured-details {
  display: flex;
  flex-direction: column;
  align-items: start;
  grid-row-gap: 10px;
  row-gap: 10px;
  padding: 70px;
  width: 50%;
}

.featured-details > * {
  margin: 0;
}

.featured-text {
  font-family: Inter, sans-serif;
  font-size: 19px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  color: #0074AD;
}

.featured-title {
  font-family: Montserrat, sans-serif;;
  font-size: 44px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  color: #1E1E1E;
}

.featured-date {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  color: #484848;
}

.featured-description {
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 31.2px;
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  color: #1E1E1E;
}

.read-btn-container {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 25px;
}

.read-btn {
  font: Inter, sans-serif;;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: #FFFFFF;
  background-color: #3B5A91;
  border-radius: 64px;
  border-color: white;
  border: none;
  padding: 7px 18px 2px 18px;
  text-align: center;
  box-shadow: none;
  transform: translateX(-50%);
  font-size: 18px;
  width: min-content;
}

.read-btn:hover {
  cursor: pointer;
}

.read-btn p {
  line-height: 0;
  height: min-content;
  width: min-content;
}

.read-btn span {
  margin-left: 10px;
  height: min-content;
  width: min-content;
}

.filter-container {
  border: 0px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin-top: 120px;
  grid-column-gap: 25px;
  column-gap: 25px;
}

.filter-container select {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  border-color: rgb(194, 194, 194);
  border-radius: 7px;
  padding: 20px;
  width: 250px;
}

.featured-curve {
  background-color: white;
  width: 100%;
  height: 45px;
  border-radius: 90%/50px 50px 0 0;
  margin-top: -45px;
}

.blog-list-container {
  border: 0px solid red;
  padding: 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: center;
  grid-row-gap: 50px;
  row-gap: 50px;
  grid-column-gap: 50px;
  column-gap: 50px;
}

.blog-card {
  border: 0px solid red;
  height: min-content;
  width: 30%;
  background-color: #EFF1F7;
  box-shadow: 0px 4px 10px 0px #00000026;
  width: 400px;
  height: 449px;
  border-radius: 60px 12px 60px 12px;

}

.blog-card .blog-img img {
  width: 399px;
  height: 242px;
  border-top-left-radius: 60px;
  object-fit: cover;
}

.blog-card .blog-category-date {
  font-family: Inter, sans-serif;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}

.blog-card .blog-category-date .card-category {
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  color: #0074AD;
  margin-left: 20px;
}

.blog-card .blog-category-date .card-date {
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  color:#484848;
  margin-right: 20px;
}

.blog-card .blog-title {
  font-family: Montserrat, sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 25.46px;
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  color: #1E1E1E;
  padding: 0px 20px 0px 20px;
}

.blog-card .blog-title p {
  padding: 0;
  margin-top: -8px;
}

.blog-description {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 18.08px;
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  color: #272932;
  padding: 0px 20px 0px 20px;
  margin-top: -7px;
  display: -webkit-box; /* Enable the flexbox for clamping */
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical; /* Set the orientation to vertical */
  overflow: hidden; /* Hide the overflowed content */
  text-overflow: ellipsis; /* Add "..." if the text is cut off */
}

@media screen and (max-width: 900px) {
  .featured-container {
    padding: 10px;
    margin-top: -30px;
  }

  .featured-top {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .featured-image {
    width: 100%;
  }

  .featured-image img {
    height: 40vh;
    width: 80vw;
    object-fit: cover;
  }
  .featured-title {
    text-align: center;
  }

  .featured-details {
    margin-top: 25px;
    padding: 10px;
    text-align: center;
    align-items: center;
    width: 90%;
  }

  .featured-text, .featured-date {
    font-size: 13px;
  }

  .featured-title {
    font-size: 35px;
  }

  .read-btn {
    padding: 3px 12px 7px 12px;
    font-size: 17px;
    transform: translateX(-30%);
  }

  .read-btn p {
    font:  Inter, sans-serif;
    margin-top: 22px;
  }

  .read-btn span img {
    width: 24px;
    height: 24px;
    margin-top: 9px;
  }

  .filter-container {
    width: 90%;
    max-width: 100vw;
    flex-direction: column;
    grid-row-gap: 20px;
    row-gap: 20px;
    margin-top: 20px;
  }

  .blog-list-container {
    padding: 10px;
  }

  .blog-card {
    width: 80vw;
    height: -moz-fit-content;
    height: fit-content;
  }

  .blog-card .blog-img img {
    height: 40vh;
    width: 80vw;
  }
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.page-button {
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

.page-button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.page-button:hover {
  background-color: #f0f0f0;
}

body {
    overflow-x: hidden;
}

.info-section {
    border: 0px solid red;
    display: flex;
    flex-direction: row;
    min-height: 70vh;
    max-width: 100vw;
    background-color: #EFF1F7;
    padding: 70px 90px 70px 90px;
}

.info-section .info-left {
    border: 0px solid red;
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: left;
    justify-content: space-center;
    grid-row-gap: 20px;
    row-gap: 20px;
    padding-top: 50px;
}

.info-section .info-left > * {
    margin: 0;
}

.info-left .info-category {
    border: 0px solid red;
    font-family: Inter, sans-serif;
    font-size: 19px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    color: #0074AD;
}

.info-left .info-title {
    border: 0px solid red;
    font-family: Montserrat, sans-serif;
    font-size: 56px;
    font-weight: 400;
    line-height: 56px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    color: #1E1E1E;
}

.info-left .info-date {
    border: 0px solid red;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    color: #484848;
}

.info-left .info-description {
    border: 0px solid red;
    font-family: Inter, sans-serif;
    font-size: 22px;
    font-weight: 300;
    line-height: 40.56px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    color: #1E1E1E;
    margin-top: 25px;
}

.info-section .info-right {
    border: 0px solid red;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.info-section .info-right img {
    width: 680px;
    height: 536px;
    top: 170px;
    left: 721px;
    grid-gap: 0px;
    gap: 0px;
    border-radius: 0px 160px 0px 160px; 
}

.content-section {
    font-family: Inter, sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 30.8px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    background-color: #EFF1F7;
    margin-top: -22px;
    padding: 0px 450px 100px 450px;
}

.related-section h1 {
    font-family: Inter, sans-serif;
    font-size: 65px;
    font-weight: 300;
    line-height: 70px;
    text-align: center;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.content-section img {
    max-width: 100%;
    object-fit: contain;
}

@media screen and (max-width: 1300px) {
    header {
        max-width: 100vw;
    }
    .info-section {
        flex-direction: column;
        width: 100vw;
        grid-row-gap: 25px;
        row-gap: 25px;
        grid-row-gap: 20px;
        row-gap: 20px;
        margin-top: -30px;
        padding: unset;
        padding-top: 40px;
    }

    .info-section .info-left {
        border: 0px solid red;
        align-items: center;
        grid-row-gap: 0px;
        row-gap: 0px;
        width: 100vw;
        padding: unset;
        margin: unset;
        padding: 25px 35px;
        box-sizing: border-box;
    }

    .info-left .info-category {
        font-size: 17px;
    }

    .info-left .info-title {
        font-size: 24px;
        text-align: center;
        line-height: 40px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .info-left .info-date {
        font-size: 17px;
    }

    .info-left .info-description {
        font-size: 22px;
    }

    .info-section .info-right {
        width: 100vw;
    }

    .info-section .info-right img {
        width: 90vw;
        height: 40vh;
        object-fit: cover;
    }

    .content-section {
        display: flex;
        flex-direction: column;
        margin: 0;
        max-width: 100vw;
        padding: unset;
        padding: 25px 35px 70px 35px;
        box-sizing: border-box;
        margin-top: -35px;
    }

    .related-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 40px;
    }

    .related-section h1 {
        font-size: 40px;
    }

    .blog-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 25px;
    }

    .blog-card > * {
        margin: 0;
        padding: 0;
        height: min-content;
    }

    .blog-card .blog-title {
        font-size: 20px;
        margin: 0;
        height: unset;
    }

    .blog-card .blog-description {
        width: 93%;
        font-size: 20px;
        padding: unset;
    }
}
.PrivacypolicyPage_PrivacyPolicyPage__ztM5M {
    max-width: none !important;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 55px;
  }
.PrivacyPolicy_privacyPolicyContainer__2xrA1 {
  max-width: 800px; /* Reduce width for better centering */
  margin: 0 auto;
  padding: 40px 20px; /* Add consistent left and right padding */
  font-family: 'Inter', sans-serif;
  color: #1e1e1e;
  position: relative;
}

.PrivacyPolicy_title__3x2d2 {
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
  font-family: 'Montserrat', sans-serif;
}

.PrivacyPolicy_section__3k2ge {
  margin-bottom: 40px;
}

.PrivacyPolicy_subtitle__1EOi4 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 20px;
}

.PrivacyPolicy_text__3WJmG {
  font-size: 18px;
  line-height: 1.8; /* Slightly increased for readability */
  margin-bottom: 20px;
}

.PrivacyPolicy_backToTopButton__AafCU {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 12px 18px;
  background-color: #3b5a91;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, transform 0.3s;
}

.PrivacyPolicy_backToTopButton__AafCU.PrivacyPolicy_show__25vcx {
  opacity: 1;
  visibility: visible;
}

.PrivacyPolicy_backToTopButton__AafCU:hover {
  background-color: #2c4770;
  transform: translateY(-3px);
}

.PrivacyPolicy_footer__2W_Dn {
  height: 200px;
  background-color: #e3ecf6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  font-size: 18px;
  color: #1e1e1e;
}

/* Tablet and Mobile Adjustments */
@media (max-width: 991px) {
  .PrivacyPolicy_privacyPolicyContainer__2xrA1 {
    max-width: 95%; /* Ensure content is well-contained */
    padding: 30px 25px; /* Consistent padding for better spacing */
    margin-left: 20px;
    margin-right: 20px;
  }

  .PrivacyPolicy_title__3x2d2 {
    font-size: 36px;
    margin-bottom: 30px;
  }

  .PrivacyPolicy_subtitle__1EOi4 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .PrivacyPolicy_text__3WJmG {
    font-size: 16px;
    line-height: 1.6; /* Adjusted for readability */
    margin-bottom: 15px;
  }

  .PrivacyPolicy_backToTopButton__AafCU {
    bottom: 10px;
    right: 10px;
    padding: 10px 15px;
  }
}

@media (max-width: 600px) {
  .PrivacyPolicy_privacyPolicyContainer__2xrA1 {
    max-width: 100%; /* Occupy full width for mobile */
    padding: 20px 10px; /* Add padding for better spacing */
  }

  .PrivacyPolicy_title__3x2d2 {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .PrivacyPolicy_subtitle__1EOi4 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .PrivacyPolicy_text__3WJmG {
    font-size: 14px;
    line-height: 1.5; /* Adjusted for smaller screens */
    margin-bottom: 10px;
  }

  .PrivacyPolicy_backToTopButton__AafCU {
    bottom: 8px;
    right: 8px;
    padding: 8px 12px;
    font-size: 14px; /* Smaller font size for the button */
  }
}

.JobPosting_container__3Pm5k {
  background: rgba(239, 241, 247, 1);
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 113px 80px;
}

@media (max-width: 991px) {
  .JobPosting_container__3Pm5k {
    max-width: 100%;
    padding: 100px 20px;
  }
}

.JobPosting_contentWrapper__2MYis {
  display: flex;
  width: 828px;
  max-width: 100%;
  flex-direction: column;
  margin: 0 40px -24px;
}

@media (max-width: 991px) {
  .JobPosting_contentWrapper__2MYis {
    margin: 0 10px 10px;
    width: 100%;
  }
}

.JobPosting_headerContainer__2-LDU {
  display: flex;
  width: 100%;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .JobPosting_headerContainer__2-LDU {
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    max-width: 100%;
  }
}

.JobPosting_jobTitle__1To62 {
  color: rgba(30, 30, 30, 1);
  font: 400 50px/1 Montserrat, -apple-system, Roboto, Helvetica, sans-serif; /* Reduced font size */
  margin: 0;
}

@media (max-width: 991px) {
  .JobPosting_jobTitle__1To62 {
    font-size: 36px; /* Smaller font for mobile */
    text-align: center;
  }
}

.JobPosting_applyButton__3k5X1 {
  border-radius: 64px;
  background-color: #3b5a91;
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px; /* Space between text and icon */
  color: #fff;
  padding: 12px 24px; /* Ensure even padding around */
  font: 700 20px "Darker Grotesque", sans-serif;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  overflow: hidden;
  text-decoration: none !important;
  line-height: 1; /* Ensures consistent vertical alignment */
}

.JobPosting_applyButton__3k5X1:hover {
  background-color: #2c4770;
}

.JobPosting_scrolled__22lNz .JobPosting_applyButton__3k5X1 {
  background-color: #fff;
  color: #3b5a91;
}

.JobPosting_buttonIcon__1hE9a {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px; 
}

.JobPosting_applyButton__3k5X1:hover .JobPosting_buttonIcon__1hE9a {
  transform: translateX(5px);
}

.JobPosting_scrolled__22lNz .JobPosting_buttonIcon__1hE9a {
  width: 22px;
  height: 22px;
}


.JobPosting_mainContent__3Wnf8 {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: flex-start;
  color: rgba(30, 30, 30, 1);
  padding: 0 80px 0 10px;
  font: 400 18px/1.5 Inter, sans-serif; /* Reduced font size */
}

@media (max-width: 991px) {
  .JobPosting_mainContent__3Wnf8 {
    max-width: 100%;
    padding: 0 20px;
    margin-top: 40px;
  }
}

.JobPosting_sectionTitle__25_SM {
  margin-top: 38px;
  font: 700 24px Montserrat, -apple-system, Roboto, Helvetica, sans-serif; /* Reduced font size */
}

.JobPosting_sectionContent__29kMr {
  line-height: 28px; /* Adjusted line height */
  margin-top: 12px;
  font-size: 16px; /* Reduced font size */
}

.JobPosting_boldText__2u_aQ {
  font-weight: 700;
}

@media (max-width: 991px) {
  .JobPosting_sectionContent__29kMr {
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .JobPosting_headerContainer__2-LDU {
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
  }

  .JobPosting_jobTitle__1To62 {
    font-size: 30px; /* Further reduced for mobile */
    text-align: center;
  }

  .JobPosting_applyButton__3k5X1 {
    padding: 8px 16px; /* Reduced padding */
    font-size: 16px; /* Smaller font size */
  }

  .JobPosting_mainContent__3Wnf8 {
    font-size: 14px;
    line-height: 1.4;
    padding: 0 15px;
  }

  .JobPosting_sectionTitle__25_SM {
    font-size: 20px;
    margin-top: 20px;
  }
  .JobPosting_sectionContent__29kMr {
    max-width: 90%;
   }}

.BuildingManagerJobPosting_servicePage__3CCiN {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  

  @media (max-width: 991px) {
    .BuildingManagerJobPosting_decorativeImage__1UD_O {
      max-width: 100%;
      margin-top: 40px;
    }
  }

  .BuildingManagerJobPosting_separator__1VBzy {
    width: 100vw; /* Full width of the viewport */
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    object-fit: cover;
   
  }
.JobPosting_container__11lZC {
    background: rgba(239, 241, 247, 1);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 113px 80px;
  }
  
  @media (max-width: 991px) {
    .JobPosting_container__11lZC {
      max-width: 100%;
      padding: 100px 20px;
    }
  }
  
  .JobPosting_contentWrapper__fMrGP {
    display: flex;
    width: 828px;
    max-width: 100%;
    flex-direction: column;
    margin: 0 40px -24px;
  }
  
  @media (max-width: 991px) {
    .JobPosting_contentWrapper__fMrGP {
      margin: 0 10px 10px;
      width: 100%;
    }
  }
  
  .JobPosting_headerContainer__2lZbr {
    display: flex;
    width: 100%;
    grid-gap: 30px;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  @media (max-width: 991px) {
    .JobPosting_headerContainer__2lZbr {
      flex-direction: column;
      grid-gap: 20px;
      gap: 20px;
      max-width: 100%;
    }
  }
  
  .JobPosting_jobTitle__9u-NV {
    color: rgba(30, 30, 30, 1);
    font: 400 50px/1 Montserrat, -apple-system, Roboto, Helvetica, sans-serif; /* Reduced font size */
    margin: 0;
  }
  
  @media (max-width: 991px) {
    .JobPosting_jobTitle__9u-NV {
      font-size: 36px; /* Smaller font for mobile */
      text-align: center;
    }
  }
  
  .JobPosting_applyButton__2qEUg {
    border-radius: 64px;
    background-color: #3b5a91;
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px; /* Space between text and icon */
    color: #fff;
    padding: 12px 24px; /* Ensure even padding around */
    font: 700 20px "Darker Grotesque", sans-serif;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    overflow: hidden;
    text-decoration: none !important;
    line-height: 1; /* Ensures consistent vertical alignment */
}

.JobPosting_applyButton__2qEUg:hover {
    background-color: #2c4770;
}

.JobPosting_scrolled__2iFe0 .JobPosting_applyButton__2qEUg {
    background-color: #fff;
    color: #3b5a91;
}

.JobPosting_buttonIcon__368sT {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
    display: inline-block;
    vertical-align: middle;
    margin-left: 12px; 
}

.JobPosting_applyButton__2qEUg:hover .JobPosting_buttonIcon__368sT {
    transform: translateX(5px);
}

.JobPosting_scrolled__2iFe0 .JobPosting_buttonIcon__368sT {
    width: 22px;
    height: 22px;
}

  
  .JobPosting_mainContent__2t-Lk {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    align-items: flex-start;
    color: rgba(30, 30, 30, 1);
    padding: 0 80px 0 10px;
    font: 400 18px/1.5 Inter, sans-serif; /* Reduced font size */
  }
  
  @media (max-width: 991px) {
    .JobPosting_mainContent__2t-Lk {
      max-width: 90%;
      padding: 0 10px;
      margin-top: 40px;
    }
  }
  
  .JobPosting_sectionTitle__KDDXv {
    margin-top: 38px;
    font: 700 24px Montserrat, -apple-system, Roboto, Helvetica, sans-serif; /* Reduced font size */
  }
  
  .JobPosting_sectionContent__3E3O5 {
    line-height: 28px; /* Adjusted line height */
    margin-top: 12px;
    font-size: 16px; /* Reduced font size */
  }
  
  .JobPosting_boldText__37i6w {
    font-weight: 700;
  }
  
  @media (max-width: 991px) {
    .JobPosting_sectionContent__3E3O5 {
      max-width: 100%;
    }
  }
  
  @media (max-width: 600px) {
    .JobPosting_headerContainer__2lZbr {
      flex-direction: column;
      align-items: center;
      grid-gap: 20px;
      gap: 20px;
    }
  
    .JobPosting_jobTitle__9u-NV {
      font-size: 30px; /* Further reduced for mobile */
      text-align: center;
    }
  
    .JobPosting_applyButton__2qEUg {
      padding: 8px 16px; /* Reduced padding */
      font-size: 16px; /* Smaller font size */
    }
  
    .JobPosting_mainContent__2t-Lk {
      font-size: 14px;
      line-height: 1.4;
      padding: 0 15px;
    }
  
    .JobPosting_sectionTitle__KDDXv {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  
.CleanerJobPosting_servicePage__3bTI_ {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  

  @media (max-width: 991px) {
    .CleanerJobPosting_decorativeImage__2_vl3 {
      max-width: 100%;
      margin-top: 40px;
    }
  }

  .CleanerJobPosting_separator__1P6rS {
    width: 100vw; /* Full width of the viewport */
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    object-fit: cover;
   
  }
.JobPosting_container__fhN_u {
    background: rgba(239, 241, 247, 1);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 113px 80px;
  }
  
  @media (max-width: 991px) {
    .JobPosting_container__fhN_u {
      max-width: 100%;
      padding: 100px 20px;
    }
  }
  
  .JobPosting_contentWrapper__1y0x0 {
    display: flex;
    width: 828px;
    max-width: 100%;
    flex-direction: column;
    margin: 0 40px -24px;
  }
  
  @media (max-width: 991px) {
    .JobPosting_contentWrapper__1y0x0 {
      margin: 0 10px 10px;
      width: 100%;
    }
  }
  
  .JobPosting_headerContainer__14h4h {
    display: flex;
    width: 100%;
    grid-gap: 30px;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  @media (max-width: 991px) {
    .JobPosting_headerContainer__14h4h {
      flex-direction: column;
      grid-gap: 20px;
      gap: 20px;
      max-width: 100%;
    }
  }
  
  .JobPosting_jobTitle__2vIBZ {
    color: rgba(30, 30, 30, 1);
    font: 400 50px/1 Montserrat, -apple-system, Roboto, Helvetica, sans-serif; /* Reduced font size */
    margin: 0;
  }
  
  @media (max-width: 991px) {
    .JobPosting_jobTitle__2vIBZ {
      font-size: 36px; /* Smaller font for mobile */
      text-align: center;
    }
  }
  
  .JobPosting_applyButton__1gj_Y {
    border-radius: 64px;
    background-color: #3b5a91;
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px; /* Space between text and icon */
    color: #fff;
    padding: 12px 24px; /* Ensure even padding around */
    font: 700 20px "Darker Grotesque", sans-serif;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    overflow: hidden;
    text-decoration: none !important;
    line-height: 1; /* Ensures consistent vertical alignment */
}

.JobPosting_applyButton__1gj_Y:hover {
    background-color: #2c4770;
}

.JobPosting_scrolled__1xete .JobPosting_applyButton__1gj_Y {
    background-color: #fff;
    color: #3b5a91;
}

.JobPosting_buttonIcon__3oJNl {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
    display: inline-block;
    vertical-align: middle;
    margin-left: 12px; 
}

.JobPosting_applyButton__1gj_Y:hover .JobPosting_buttonIcon__3oJNl {
    transform: translateX(5px);
}

.JobPosting_scrolled__1xete .JobPosting_buttonIcon__3oJNl {
    width: 22px;
    height: 22px;
}



  .JobPosting_mainContent__2TNxr {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    align-items: flex-start;
    color: rgba(30, 30, 30, 1);
    padding: 0 80px 0 10px;
    font: 400 18px/1.5 Inter, sans-serif; /* Reduced font size */
  }
  
  @media (max-width: 991px) {
    .JobPosting_mainContent__2TNxr {
      max-width: 100%;
      padding: 0 20px;
      margin-top: 40px;
    }
  }
  
  .JobPosting_sectionTitle__3zq4U {
    margin-top: 38px;
    font: 700 24px Montserrat, -apple-system, Roboto, Helvetica, sans-serif; /* Reduced font size */
  }
  
  .JobPosting_sectionContent__2Wl2L {
    line-height: 28px; /* Adjusted line height */
    margin-top: 12px;
    font-size: 16px; /* Reduced font size */
  }
  
  .JobPosting_boldText__3Mvu4 {
    font-weight: 700;
  }
  
  @media (max-width: 991px) {
    .JobPosting_sectionContent__2Wl2L {
      max-width: 100%;
    }
  }
  
  @media (max-width: 600px) {
    .JobPosting_headerContainer__14h4h {
      flex-direction: column;
      align-items: center;
      grid-gap: 20px;
      gap: 20px;
    }
  
    .JobPosting_jobTitle__2vIBZ {
      font-size: 30px; /* Further reduced for mobile */
      text-align: center;
    }
  
    .JobPosting_applyButton__1gj_Y {
      padding: 8px 16px; /* Reduced padding */
      font-size: 16px; /* Smaller font size */
    }
  
    .JobPosting_mainContent__2TNxr {
      font-size: 14px;
      line-height: 1.4;
      padding: 0 15px;
    }
  
    .JobPosting_sectionTitle__3zq4U {
      font-size: 20px;
      margin-top: 20px;
    }

    .JobPosting_sectionContent__2Wl2L {
    max-width: 94%;
    }

  }
  
.CaretakerPosting_servicePage__2g1MP {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  

  @media (max-width: 991px) {
    .CaretakerPosting_decorativeImage__2S6Kf {
      max-width: 100%;
      margin-top: 40px;
    }
  }

  .CaretakerPosting_separator__1ARVU {
    width: 100vw; /* Full width of the viewport */
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    object-fit: cover;
   
  }
.JobPosting_container__ctswa {
    background: rgba(239, 241, 247, 1);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 113px 80px;
  }
  
  @media (max-width: 991px) {
    .JobPosting_container__ctswa {
      max-width: 100%;
      padding: 100px 20px;
    }
  }
  
  .JobPosting_contentWrapper__qIes_ {
    display: flex;
    width: 828px;
    max-width: 100%;
    flex-direction: column;
    margin: 0 40px -24px;
  }
  
  @media (max-width: 991px) {
    .JobPosting_contentWrapper__qIes_ {
      margin: 0 10px 10px;
      width: 100%;
    }
  }
  
  .JobPosting_headerContainer__2GRJU {
    display: flex;
    width: 100%;
    grid-gap: 30px;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  @media (max-width: 991px) {
    .JobPosting_headerContainer__2GRJU {
      flex-direction: column;
      grid-gap: 20px;
      gap: 20px;
      max-width: 100%;
    }
  }
  
  .JobPosting_jobTitle__3NXFU {
    color: rgba(30, 30, 30, 1);
    font: 400 50px/1 Montserrat, -apple-system, Roboto, Helvetica, sans-serif; /* Reduced font size */
    margin: 0;
  }
  
  @media (max-width: 991px) {
    .JobPosting_jobTitle__3NXFU {
      font-size: 36px; /* Smaller font for mobile */
      text-align: center;
    }
  }
  
  .JobPosting_applyButton__2Vdf5 {
    border-radius: 64px;
    background-color: #3b5a91;
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px; /* Space between text and icon */
    color: #fff;
    padding: 12px 24px; /* Ensure even padding around */
    font: 700 20px "Darker Grotesque", sans-serif;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    overflow: hidden;
    text-decoration: none !important;
    line-height: 1; /* Ensures consistent vertical alignment */
}

.JobPosting_applyButton__2Vdf5:hover {
    background-color: #2c4770;
}

.JobPosting_scrolled__1lg8W .JobPosting_applyButton__2Vdf5 {
    background-color: #fff;
    color: #3b5a91;
}

.JobPosting_buttonIcon__1l38c {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
    display: inline-block;
    vertical-align: middle;
    margin-left: 12px; 
}

.JobPosting_applyButton__2Vdf5:hover .JobPosting_buttonIcon__1l38c {
    transform: translateX(5px);
}

.JobPosting_scrolled__1lg8W .JobPosting_buttonIcon__1l38c {
    width: 22px;
    height: 22px;
}

  
  .JobPosting_mainContent__4RiTr {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    align-items: flex-start;
    color: rgba(30, 30, 30, 1);
    padding: 0 80px 0 10px;
    font: 400 18px/1.5 Inter, sans-serif; /* Reduced font size */
  }
  
  @media (max-width: 991px) {
    .JobPosting_mainContent__4RiTr {
      max-width: 85%;
      padding: 0 15px;
      margin-top: 40px;
    }
  }
  
  .JobPosting_sectionTitle__V_y1h {
    margin-top: 38px;
    font: 700 24px Montserrat, -apple-system, Roboto, Helvetica, sans-serif; /* Reduced font size */
  }
  
  .JobPosting_sectionContent__3dBFL {
    line-height: 28px; /* Adjusted line height */
    margin-top: 12px;
    font-size: 16px; /* Reduced font size */
  }
  
  .JobPosting_boldText__1SmpK {
    font-weight: 700;
  }
  
  @media (max-width: 991px) {
    .JobPosting_sectionContent__3dBFL {
      max-width: 100%;
    }
  }
  
  @media (max-width: 600px) {
    .JobPosting_headerContainer__2GRJU {
      flex-direction: column;
      align-items: center;
      grid-gap: 20px;
      gap: 20px;
    }
  
    .JobPosting_jobTitle__3NXFU {
      font-size: 30px; /* Further reduced for mobile */
      text-align: center;
    }
  
    .JobPosting_applyButton__2Vdf5 {
      padding: 8px 16px; /* Reduced padding */
      font-size: 16px; /* Smaller font size */
    }
  
    .JobPosting_mainContent__4RiTr {
      font-size: 14px;
      line-height: 1.4;
      padding: 0 15px;
    }
  
    .JobPosting_sectionTitle__V_y1h {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  
.ConciergePosting_servicePage__3R0bU {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  

  @media (max-width: 991px) {
    .ConciergePosting_decorativeImage__1a1Lr {
      max-width: 100%;
      margin-top: 40px;
    }
  }

  .ConciergePosting_separator__b3GRM {
    width: 100vw; /* Full width of the viewport */
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    object-fit: cover;
   
  }
/* Desktop Styles */
body {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  
.LeadMagnetLandingPage_landingPage__wb4id {
    padding-top: 0 !important;
    margin-top: 0 !important;
    position: relative;
    top: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 100vh; /* Fills the full viewport height */
  }
  
  .LeadMagnetLandingPage_contentWrapper__36MQL {
    border-radius: 10px;
    background-color: #eff1f7;
    background-image: url(/static/media/CareersGradientVector.25493852.png); /* Update with the correct path */
    background-size: contain; /* Makes the gradient cover the entire section */
    background-position: center; /* Centers the gradient */
    background-repeat: no-repeat; /* Ensures the image doesn’t repeat */
    background-position: calc(0% + 30px) calc(70% + 150px);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    flex-grow: 1; /* Allows it to grow and fill remaining space */
  }
  
  .LeadMagnetLandingPage_innerContent__1Gltf {
    display: flex;
    margin-bottom: -12px;
    width: 100%;
    max-width: 1139px;
    flex-direction: column;
    flex-grow: 1; /* Ensures it stretches vertically */
  }
  
  .LeadMagnetLandingPage_logo__16pc8 {
    aspect-ratio: 4.39;
    object-fit: contain;
    object-position: center;
    width: 250px;
    max-width: 100%;
  }
  
  .LeadMagnetLandingPage_mainContent__3KNhi {
    margin-top: 30px;
  }
  
  .LeadMagnetLandingPage_contentColumns__GODrC {
    grid-gap: 20px;
    gap: 20px;
    display: flex;
  }
  
  .LeadMagnetLandingPage_leftColumn__2d_hc {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 56%;
    margin-left: 0px;
  }
  
  .LeadMagnetLandingPage_textContent__2AIJE {
    display: flex;
    min-height: 517px;
    flex-grow: 1;
    flex-direction: column;
    color: #1e1e1e;
    justify-content: start;
  }
  
  .LeadMagnetLandingPage_mainHeading__3lN_c {
    font: 450 48px Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
    max-width: 85%;
  }
  
  .LeadMagnetLandingPage_descriptionWrapper__2lzvg {
    border-radius: 0;
    display: flex;
    margin-top: 10px;
    max-width: 100%;
    width: 571px;
    flex-direction: column;
    font-family: Inter, sans-serif;
    font-weight: 400;
  }
  
  .LeadMagnetLandingPage_description__2BS_S {
    font-size: 20px;
    line-height: 32px;
  }
  
  .LeadMagnetLandingPage_disclaimer__35pY- {
    font-size: 12px;
    line-height: 19px;
    margin: 96px 29px 0 0;
  }
  
  .LeadMagnetLandingPage_rightColumn__VumpL {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 44%;
    margin-left: 20px;
  }
  
  .LeadMagnetLandingPage_formWrapper__z1AS0 {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    font: 16px/1.4 Inter, sans-serif;
  }
  
  .LeadMagnetLandingPage_formGroup__16A2g {
    border-radius: 10px;
    
    display: flex;
    width: 100%;
    max-width: 440px;
    flex-direction: column;
    margin-bottom: 32px;
  }
  
  .LeadMagnetLandingPage_formLabel__3twqi {
    color: #1e1e1e;
    font-weight: 600;
    align-self: start;
  }
  
  .LeadMagnetLandingPage_formInput__1kbdt {
    align-self: stretch;
    border-radius: 10px;
    background-color: #fff;
    margin-top: 4px;
    grid-gap: 10px;
    gap: 10px;
    overflow: hidden;
    color: rgba(30, 30, 30, 0.75);
    font-weight: 400;
    padding: 18px;
    border: none;
  }
  
  .LeadMagnetLandingPage_checkboxWrapper__1UW1m {
    display: flex;
    margin-top: 32px;
    width: 100%;
    align-items: start;
    grid-gap: 24px;
    gap: 24px;
    font-size: 12px;
    color: #1e1e1e;
    font-weight: 400;
    line-height: 17px;
    justify-content: start;
  }
  
  .LeadMagnetLandingPage_checkbox__20Pgz {
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    width: 32px;
    height: 32px;
  }
  
  .LeadMagnetLandingPage_checkboxLabel__3GgTp {
    flex: 1 1;
    flex-basis: 0%;
  }
  
  .LeadMagnetLandingPage_submitButton__2SLNd {
    margin-top: 20px;
    background-color: #3b5a91;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    padding: 12px 24px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
    text-align: center;
  }
  
  .LeadMagnetLandingPage_submitButton__2SLNd:hover {
    background-color: #2f4774;
    transform: translateY(-2px);
  }
  
  .LeadMagnetLandingPage_submitButton__2SLNd:active {
    background-color: #2f4774;
    transform: translateY(0);
  }
  
  .LeadMagnetLandingPage_visually-hidden__28pdE {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }


  .LeadMagnetLandingPage_loader__9-Vbw {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .LeadMagnetLandingPage_spinner__-vGpg {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #4caf50;
    animation: LeadMagnetLandingPage_spin__2TV0n 1s ease infinite;
  }
  
  @keyframes LeadMagnetLandingPage_spin__2TV0n {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Mobile Styles */
  @media (max-width: 991px) {
    .LeadMagnetLandingPage_body__2cn1J{
        padding-top: 0px;
    }
    .LeadMagnetLandingPage_contentWrapper__36MQL {
      max-width: 100%;
      padding: 10px 20px;
      background-position: right calc(40% - 50px); /* Moves background up by 50px */
      background-size: 150% 100%; /* Creates a semi-circle effect on the right side */
      
    }
  
    .LeadMagnetLandingPage_innerContent__1Gltf {
      max-width: 100%;
      margin-bottom: 10px;
    }
  
    .LeadMagnetLandingPage_mainContent__3KNhi {
      max-width: 100%;
    }
  
    .LeadMagnetLandingPage_contentColumns__GODrC {
      flex-direction: column;
      align-items: stretch;
      grid-gap: 0px;
      gap: 0px;
    }
  
    .LeadMagnetLandingPage_leftColumn__2d_hc,
    .LeadMagnetLandingPage_rightColumn__VumpL {
      width: 90%;
    }
  
    .LeadMagnetLandingPage_textContent__2AIJE {
      max-width: 100%;
      margin-top: 40px;
    }
  
    .LeadMagnetLandingPage_mainHeading__3lN_c {
      max-width: 100%;
      font-size: 40px;
      line-height: 49px;
    }
  
    .LeadMagnetLandingPage_descriptionWrapper__2lzvg {
      margin-top: 40px;
    }
  
    .LeadMagnetLandingPage_description__2BS_S {
      max-width: 100%;
    }
  
    .LeadMagnetLandingPage_disclaimer__35pY- {
      max-width: 100%;
      margin: 40px 10px 0 0;
    }
  
    .LeadMagnetLandingPage_formWrapper__z1AS0 {
      max-width: 90%;
      margin-top: 40px;
    }
  
    .LeadMagnetLandingPage_formGroup__16A2g {
      max-width: 100%;
    }
  
    .LeadMagnetLandingPage_checkboxWrapper__1UW1m {
      max-width: 100%;
    }

    .LeadMagnetLandingPage_checkboxLabel__3GgTp{
        margin-left: -10px;
    }
  
    .LeadMagnetLandingPage_submitButton__2SLNd {
        white-space: initial;
        width: 100%; /* Sets button width to match form inputs */
        box-sizing: border-box; /* Ensures padding doesn’t affect width */
      }
  }
  
