.teamSection {
  margin-top: 30px;
  padding: 20px;
}

.sectionTitle {
  text-align: center;
  
  margin-bottom: 120px;
  font-family: 'Montserrat', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 70px;
line-height: 100%;
}

.teamGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  gap: 20px; /* Adjusted for better fit */
  max-width: 1200px; /* Adjusted max-width */
  margin: 0 auto;
  position: relative;
}

/* Column Styling */
.column1,
.column2,
.column3,
.column4 {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Consistent gap between cards */
}

.column2 {
  margin-top: -60px; /* Adjusted stagger effect */
}

.column4 {
  margin-top: -60px; /* Adjusted stagger effect */
}

/* Team Member Styling */
.teamMember {
  width: 260px; /* Slightly reduced width */
  height: 360px; /* Slightly reduced height */
  background-color: #f0f0f0;
  border-radius: 20px;
  display: flex;
  align-items: flex-end; /* Align content to the bottom */
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 4px 12px rgba(246, 242, 242, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition added */
  margin: 0 auto;
  margin-bottom: 20px; /* Consistent vertical spacing */
  background-size: cover; /* Fit the image to the card */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent image repeat */
}

.teamMember:hover {
  transform: translateY(-5px) scale(1.02); /* Slight zoom and lift effect */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Enhance box-shadow on hover */
}

.teamMember1 {
  background-image: url('/images/Jatin.png');
}

.teamMember2 {
  background-image: url('https://cdn.builder.io/api/v1/image/assets/TEMP/3f8d28cb226cc5e123d7569419962ab465c58ee696e3f3d606f4f9a9a0f45dd0?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f');
}

.teamMember3 {
  background-image: url('/images/Sukhpreet.png');
}

.teamMember4 {
  background-image: url('https://cdn.builder.io/api/v1/image/assets/TEMP/65d705b5d221becd47c7f197cb1c2649733dc1acf5d39b7f6fdd88bd4115294c');
}

.teamMember5 {
  background-image: url('/images/Nestor.png');
}

.teamMember6 {
  background-image: url('https://cdn.builder.io/api/v1/image/assets/TEMP/cd29c35a12cca4d43987286bfa671ae895816ded44b564b87113800f66c847a4');
}

.teamMember7 {
  background-image: url('/images/LuisHR.png');
}

/* Text Overlay */
.overlay {
  position: absolute;
  bottom: 0;
  width: 90%;
  color: #fff;
  text-align: left;
  padding: 20px 0;
}

.serviceIcon {
  width: 24px; /* Set icon width */
  height: 24px; /* Set icon height */
  object-fit: contain; /* Maintain aspect ratio */
}

.name {
  font-size: 18px;
  font-weight: 600;
  margin: 15px 10px 10px;

}

.position {
  font-size: 16px;
  margin: 15px 10px 10px;
}

/* Testimonial Styling */
.testimonial {
  width: 260px;
  height: 360px;
  background-color: #d4f8c4;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition added */
  margin: 0 auto;
  margin-bottom: 20px;
}

.testimonial:hover {
  transform: translateY(-5px) scale(1.02); /* Slight zoom and lift effect */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Enhance box-shadow on hover */
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .teamGrid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    gap: 15px; /* Reduced gap for smaller screens */
  }

  .column1,
  .column2,
  .column3,
  .column4 {
    gap: 15px; /* Adjusted gap for smaller screens */
  }

  .teamMember,
  .testimonial{
    width: 100%;
    height: auto;
    margin-top: 0; /* Remove stagger on smaller screens */
  }
}

@media (max-width: 767px) {
  .teamGrid {
    grid-template-columns: 1fr; /* 1 column */
    gap: 10px;
  }

  .teamMember,
  .testimonial{
    width: 100%;
    height: auto;
  }

  .sectionTitle {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.teamSection {
  margin-top: 30px;
  padding: 20px;
}

.sectionTitle {
  text-align: center;
  margin-bottom: 120px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  line-height: 100%;
}

.teamGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default for desktop: 4 columns */
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Team Member Styling */
.teamMember {
  width: 100%;
  max-width: 260px;
  height: 360px;
  background-color: #f0f0f0;
  border-radius: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 4px 12px rgba(246, 242, 242, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 0 auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.teamMember:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .teamSection {
    padding: 10px;
  }

  .sectionTitle {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .teamGrid {
    display: flex;
    flex-direction: column; /* Ensure single-column layout */
    align-items: center;
    gap: 15px;
    width: 100%; /* Full width */
  }

  .teamMember {
    width: 100%; /* Full width on mobile */
    max-width: 280px;
    margin: 0 auto; /* Center align each card */
  }

  .column1,
  .column2,
  .column3,
  .column4 {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Consistent gap between cards */
    align-items: center; /* Center align all columns */
    width: 100%; /* Full width on mobile */
    margin: 0; /* Remove any offset margin */
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .teamSection {
    padding: 20px 15px; /* Equal padding on both sides */
    margin-top: 30px; /* Add margin on top */
  }

  .sectionTitle {
    font-size: 36px; /* Adjust title font size */
    margin-bottom: 80px; /* Space below the title */
    text-align: center;
  }

  .teamGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    gap: 10px; /* Reduce or set to 0 to remove space between columns */
    width: calc(100% - 30px); /* Subtract padding to make space equal on both sides */
    max-width: 900px; /* Limit the grid's width */
    margin: 0 auto; /* Center the grid */
  }

  .teamMember {
    width: 100%; /* Let the grid define the width */
    height: auto; /* Maintain the card's height */
    aspect-ratio: 3 / 4; /* Keep consistent proportions */
    background-color: #f0f0f0; /* Background for cards */
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    overflow: hidden; /* Clip overflowing content */
  }

  .teamMember img {
    width: 100%; /* Ensure images fit the card width */
    height: 100%; /* Stretch to cover height */
    object-fit: cover; /* Maintain aspect ratio for images */
    border-radius: 15px; /* Match the card's corners */
  }

  .hiringBox {
    grid-column: span 2; /* Span across both columns */
    margin-top: 20px; /* Add space above the hiring box */
    width: calc(100% - 30px); /* Ensure hiring box aligns with grid */
  }
}
