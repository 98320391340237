.missionStatement {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  position: relative;
}

.missionTitle {
  color: #1e1e1e;
  text-align: center;
  max-width: 60%;
  font: 400 48px/56px Montserrat, sans-serif;
  position: relative;
  z-index: 1;
  padding: 20px;
}

.missionStatement::before {
  content: "";
  position: absolute;
  top: 25;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 500px;
  height: 400px;
  background: url('../../Assets/Gradients/CareersGradientVector.png') no-repeat center;
  background-size: contain;
  z-index: 0;
  opacity: 0.8;
  margin-bottom: 40px;
}

.missionVideoContainer {
  position: relative;
  min-height: 600px;
  width: 100%;
  max-width: 1200px;
  margin-top: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.missionVideo {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
}

@media (max-width: 991px) {
  .missionTitle {
    font-size: 40px;
    line-height: 52px;
  }

  .missionVideoContainer {
    min-height: 400px;
    margin-top: 40px;
  }
}

@media (max-width: 600px) {
  .missionStatement {
    padding: 40px 0; /* Reduce vertical padding */
    margin-bottom: 20px;
  }

  .missionTitle {
    font-size: 28px; /* Smaller font size for mobile */
    line-height: 36px;
    padding: 10px; /* Reduce padding */
    max-width: 90%; /* Use more of the screen width */
    
  }

  .missionStatement::before {
    width: 300px; /* Reduce the background size */
    height: 300px;
    top: 5%; /* Adjust positioning */
    opacity: 0.7; /* Slightly reduce opacity for mobile */
  }

  .missionVideoContainer {
    min-height: 250px; /* Reduce video container height */
    margin-top: 50px; /* Adjust top margin */
  }

  .missionVideo {
    width: 100%;
    height: 100%; /* Ensure video scales properly */
    object-fit: cover; /* Maintain proper aspect ratio */
  }
}

