.servicePage {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  

  @media (max-width: 991px) {
    .decorativeImage {
      max-width: 100%;
      margin-top: 40px;
    }
  }

  .separator {
    width: 100vw; /* Full width of the viewport */
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    object-fit: cover;
   
  }