/* Grid Container for the Combined Section */
.sectionGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 30px; /* Space between columns */
  width: 90%;
  max-width: 1200px;
  margin: 50px auto; /* Centering container */
  margin-bottom: 20px;
  align-items: stretch; /* Ensures even height */
  overflow: hidden; /* Prevents overflow */
}

/* Content Wrapper for Each Section */
.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 30px;
  background-color: #fff;
  background-image: url('../../Assets/AboutusGradient.png'); /* Gradient PNG background */
  background-repeat: no-repeat;
  background-position: top; /* Align gradient to the top */
  border-radius: 10px 10px 150px 10px; /* Consistent rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  color: #1e1e1e;
  height: 400px; /* Fixed height for uniform blocks */
  box-sizing: border-box;
  overflow: hidden; /* Keeps content within the rounded border */
  transition: padding 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

/* Image Styling for Each Section */
.sectionImage {
  width: 100%;
 
  
  height: 400px; /* Fixed height for uniformity */
  overflow: hidden; /* Keeps rounded corners intact */
}

/* Section Title Styling */
.sectionTitle {
  font: 500 36px/1.2 'Montserrat', sans-serif; /* Consistent font size */
  color: #1e1e1e;
  margin-bottom: 20px;
}

/* Description Styling */
.description {
  font: 400 16px/1.6 'Inter', sans-serif; /* Consistent font size */
  color: #4a4a4a;
}

/* Define borders for each card */
.sectionGrid .contentWrapper:nth-child(1) {
  border-radius: 150px 10px 10px 10px; /* Consistent rounded corners */
}

.sectionGrid .contentWrapper:nth-child(2) {
  border-radius: 0px 10px 10px 150px; /* Consistent rounded corners */
}

.sectionGrid .contentWrapper:nth-child(3) {
  border-radius: 10px 10px 150px 10px; /* Consistent rounded corners */
}

.sectionGrid .contentWrapper:nth-child(4) {
  border-radius: 10px 10px 150px 10px; /* Consistent rounded corners */
}

/* Responsive Design */
/* Responsive Design for Tablet */
/* Responsive Design for Tablet */
@media (max-width: 991px) {
  .sectionGrid {
    grid-template-columns: repeat(2, 1fr); /* Two columns for tablet */
    gap: 20px; /* Spacing between columns */
    padding: 20px 30px; /* Add consistent padding */
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px; /* Reduced padding for compact spacing */
    background-color: #fff;
    background-image: url('../../Assets/AboutusGradient.png'); /* Gradient path */
    background-repeat: no-repeat;
    background-size: cover; /* Ensures gradient fills container */
    background-position: center; /* Center-align gradient */
    border-radius: 10px; /* Consistent rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    color: #1e1e1e;
    height: auto; /* Adjust height to fit content */
    box-sizing: border-box;
    overflow: hidden; /* Prevent overflow issues */
    transition: padding 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  }

  .sectionImage {
    width: 100%; /* Full width for each column */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Match contentWrapper */
    object-fit: cover; /* Ensure images fit properly */
  }

  .sectionTitle {
    font-size: 24px; /* Adjust font size for tablet */
    margin-bottom: 15px;
    line-height: 1.4;
    text-align: left; /* Align titles left for better readability */
  }

  .description {
    font-size: 16px; /* Adjust text size */
    line-height: 1.5;
    color: #4a4a4a;
    text-align: justify; /* Improve readability */
  }

  .gradientBackground {
    position: absolute;
    left: -15px; /* Adjust position for alignment */
    width: 180px; /* Resize gradient for tablet */
    height: 180px;
    z-index: -1; /* Ensure gradient stays in the background */
    filter: blur(25px); /* Adjust blur for clarity */
  }

  .sectionGrid .contentWrapper:nth-child(1) {
    background-size: 100% 100%; /* Stretch gradient to fill the card */
    background-position: top center; /* Proper alignment */
    border-radius: 60px 0 0 0 ; /* Uniform rounding for tablet */
  }

  .sectionGrid .contentWrapper:nth-child(2) {
       border-radius:  0 0  60px 0; /* Uniform rounding for tablet */
       border-style: none;
  }

  .sectionGrid .contentWrapper:nth-child(3) {
    border-radius:  60px 0 0 0 ; /* Uniform rounding for tablet */

}
  .sectionGrid .contentWrapper:nth-child(4) {
    background-size: 100% 100%; /* Stretch gradient to fill the card */
    background-position: top center; /* Proper alignment */
    border-radius:  0 0  60px 0; /* Uniform rounding for tablet */
  }
}


/* Responsive Design for Mobile */
@media (max-width: 767px) {
  .sectionGrid {
    grid-template-columns: 1fr; /* Single column for mobile */
    gap: 20px; /* Add spacing between sections */
    padding: 10px 15px; /* Ensure padding for better spacing */
  }
  
  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 15px; /* Reduced padding for consistent spacing */
    background-color: #fff;
    background-image: url('../../Assets/AboutusGradient.png'); /* Ensure the gradient path is correct */
    background-repeat: no-repeat;
    background-size: cover; /* Ensures the gradient fills the entire container */
    background-position: center; /* Center-aligns the gradient */
    border-radius: 10px; /* Consistent rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    color: #1e1e1e;
    height: auto; /* Adjust height to fit the content */
    box-sizing: border-box;
    overflow: hidden; /* Ensures no overflow issues */
    transition: padding 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
  }
  
  .sectionImage {
    width: 100%; /* Full width for mobile */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Match contentWrapper */
    object-fit: cover; /* Ensure images fit well */
  }
  
  .sectionTitle {
    font-size: 20px; /* Smaller font size for titles */
    margin-bottom: 10px;
    line-height: 1.3;
    text-align: center; /* Center-align text */
  }
  
  .description {
    font-size: 14px; /* Adjust text size */
    line-height: 1.5;
    color: #4a4a4a;
    text-align: justify; /* Improve readability on smaller screens */
  }
  
  .gradientBackground {
    position: absolute;
    
    left: -20px; /* Align properly */
    width: 150px; /* Resize gradient for mobile */
    height: 150px;
    z-index: -1; /* Ensure gradient stays in the background */
    filter: blur(20px); /* Reduce blur for clarity */
  }
  
  .sectionGrid .contentWrapper:nth-child(1) {
    border-radius: 10px; /* Uniform rounding for mobile */
    border-radius: 40px 0 0 0  ; /* Uniform rounding */
  }
  
  .sectionGrid .contentWrapper:nth-child(2) {
    border-radius: 10px; /* Uniform rounding for mobile */
  }

.sectionGrid .contentWrapper:nth-child(3){
  background-size: 100% 100%; /* Stretch the gradient to fill the card */
  background-position: top center; /* Ensures proper alignment */
  border-radius:  0 0 0 40px ; /* Uniform rounding */
}
.sectionGrid .contentWrapper:nth-child(4) {
  background-size: 100% 100%; /* Stretch the gradient to fill the card */
  background-position: top center; /* Ensures proper alignment */
  
}
}