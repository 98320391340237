.servicesSection {
  display: flex;
  margin-top: 146px; /* Adjust based on your layout */
  padding: 0 80px; /* Increase padding for more space on both sides */
}

.sectionTitle {
  flex: 0 0 30%; /* Width for the title */
  font: 500 44px/46px Montserrat, sans-serif; /* Title font */
  color: #1e1e1e; /* Title color */
  margin-right: 20px; /* Space between title and content */
  padding: 20px; /* Padding around the title */
  position: relative; /* Positioning for the pseudo-element */
  z-index: 1; /* Bring title above background */
}

.sectionTitle::before {
  content: "";
  position: absolute;
  top: -20%;
  left: 0;
  width: 80%; /* Width of the gradient background */
  height: 80%; /* Height of the gradient background */
  background-image: url('../../Assets/Gradients/bm/bmellipse.png'); /* Replace with your gradient PNG path */
  background-size: cover; /* Cover the area */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-position: center; /* Center the image */
  border-radius: 50% / 70%; /* Ellipse shape */
  z-index: -1; /* Send to back */
  transform: scale(1.2); /* Optional: enlarge to better frame the text */
}

.servicesContainer {
  flex: 0 0 70%; /* Width for the content */
  border-radius: 10px; /* Rounded corners */
  background-color: #e5ff9b; /* Background color to match your design */
  padding: 52px 68px; /* Inner padding */
  overflow: hidden; /* Hide overflow to avoid scrollbar issues */
  max-width: calc(100% - 20px); /* Prevent overflow outside of the container */
}

.servicesIntro {
  color: #1e1e1e; /* Intro text color */
  font: 700 22px/31px Inter, sans-serif; /* Intro text style */
  margin-bottom: 19px; /* Space below intro */
}

.servicesList {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns layout */
  gap: 10px; /* Increased gap for better spacing */
}

.serviceItem {
  display: flex;
  align-items: first baseline;
  gap: 10px;
  font: 400 18px/28px Inter, sans-serif;
  color: #1e1e1e;
}

.serviceIcon {
  width: 24px; /* Set icon width */
  height: 24px; /* Set icon height */
  object-fit: contain; /* Maintain aspect ratio */
}

@media (max-width: 991px) {
  .sectionTitle::before {
    width: 250px; /* Adjust width for tablets */
    height: 250px; /* Adjust height for tablets */
    transform: translate(-50%, -50%) scale(1.3); /* Slightly smaller scale */
    top: 50%; /* Keep centered vertically */
    left: 40%; /* Keep centered horizontally */
  }
  .servicesSection {
    flex-direction: column;
    margin-top: 40px;
    padding: 0 20px;
  }

  .sectionTitle {
    margin-bottom: 20px;
    max-width: 50%;
  }

  .servicesContainer {
    padding: 30px 20px;
  }
}

@media (max-width: 767px) { /* Adjust breakpoint as needed */

  .sectionTitle {
    text-align: center; /* Center the text */
    margin: 0 auto; /* Center the title container */
    position: relative;
    margin-bottom: 30px;
    margin-top: 30px;
    max-width: 100%;
  }
  .sectionTitle::before {
    width: 400px; /* Adjust width for mobile */
    height: 400px; /* Adjust height for mobile */
    transform: translate(-50%, -50%) scale(1); /* Keep original size */
    top: 40%; /* Keep centered vertically */
    left: 50%; /* Keep centered horizontally */
  }  
  .servicesList {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
}
