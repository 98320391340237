.quoteRequestSection {
    position: relative;
    border-radius: 50px;
    background-color: #e3ecf6;
    width: 100%;
    max-width: 1360px;
    margin: 160px auto 60px; /* Consistent top and bottom margin */
    padding: 40px 40px 60px; /* Consistent padding with homepage */
    display: flex;
    gap: 40px; /* Consistent gap */
    align-items: stretch;
    overflow: hidden;
  }
  
  .quoteRequestSection::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 250px; /* Adjusted for consistency */
    background-image: url('../../Assets/clippathgroup.png');
    background-size: cover;
    background-position: center;
    z-index: 1;
  }
  
  .contentContainer {
    flex: 0 0 58%;
    color: #1e1e1e;
    z-index: 2;
    max-width: 55%;
  }
  
  .title {
    font: 500 50px/1.1 'Montserrat', sans-serif;
    color: #1e1e1e;
    margin-bottom: 10px;
    margin-left: 60px;
    max-width: 58%;
  }
  
  .description {
    font: 400 20px/1.5 'Inter', sans-serif;
    color: #1e1e1e;
    margin-left: 60px;
    margin-top: 30px;
    max-width: 65%;
  }
  
  .quoteForm {
    flex: 0 0 42%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 440px;
    z-index: 2;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
  }
  
  .label {
    font: 600 16px/1.4 'Inter', sans-serif;
    color: #1e1e1e;
    margin-bottom: 4px;
  }
  
  .input,
  .select {
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 18px;
    border: none;
    font: 400 16px/1.4 'Inter', sans-serif;
    color: #1e1e1e;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease;
    appearance: none;
  }
  
  .input:focus,
  .select:focus {
    outline: none;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .submitButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #3b5a91;
    border: none;
    border-radius: 64px;
    color: #fff;
    font: 700 18px/1 'Darker Grotesque', sans-serif;
    padding: 12px 20px;
    max-width: 300px; /* Set a maximum width */
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .submitButton:hover {
    background-color: #2d4a74;
    transform: translateY(-2px);
  }
  
  .submitButton:active {
    transform: translateY(0);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  }
  
  .buttonIcon {
    width: 20px;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .submitButton:hover .buttonIcon {
    transform: translateX(4px);
  }
  
  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #4caf50;
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Tablet View: max-width 991px */
  @media (max-width: 950px) and (min-width: 601px) {
    .quoteRequestSection {
      flex-direction: column; /* Stack content and form vertically */
      padding: 40px 20px; /* Equal padding on both sides */
      margin: 40px auto; /* Center the section */
      max-width: 90%; /* Restrict width for better alignment */
      align-items: center; /* Center-align the section */
      box-sizing: border-box; /* Include padding in width calculations */
    }
    .contentContainer {
      width: 100%;
      max-width: 100%;   
      padding: 30px 70px 20px;  
      text-align: left; /* Align text to the left */
    }
  
    .title {
      font-size: 36px; /* Keeps the title readable */
      line-height: 1.3; /* Adjusted for spacing between lines */
      margin: 0 0 15px; /* No margin on sides, only bottom spacing */
      padding: 0 5px; /* 5px gap on both sides */
      max-width: 100%; /* Fully utilizes the container width */
      text-align: center; /* Aligns the text */
    }
  
    .description {
      font-size: 18px; /* Readable font size */
      line-height: 1.6; /* Comfortable line height */
      margin: 0 auto; /* Centers the description */
      padding: 0 25px; /* 5px gap on both sides */
      max-width: 100%; /* Fully utilizes the container width */
      text-align: justify; /* Aligns text evenly on both sides */
    }
  
    .quoteForm {
      width: 100%; /* Full width for the form */
      max-width: 500px; /* Restrict maximum width */
      margin-top: -10px;
      margin: 0 auto; /* Center the form */
    }
  
    .formGroup {
      width: 95%; /* Restrict width for better alignment */
      margin: 0 auto;
    }
  
    .input,
    .select {
      width: 100%; /* Full width for inputs */
      padding: 14px 16px; /* Comfortable padding */
      margin-bottom: 16px; /* Spacing between fields */
    }
  
    .submitButton {
      width: auto; /* Dynamic width for button */
      padding: 12px 24px; /* Comfortable padding */
      font-size: 18px; /* Match desktop font size */
      margin: 20px auto 0; /* Center the button */
      display: flex; /* Flexbox for alignment */
      justify-content: center; /* Center-align content */
      align-items: center;
      border-radius: 64px; /* Rounded button */
    }
  
    .buttonIcon {
      width: 20px; /* Maintain icon size */
      height: auto; /* Maintain aspect ratio */
      margin-left: 10px; /* Spacing between text and icon */
    }
  }
  
  /* Mobile View: max-width 600px */
  @media (max-width: 600px) {
    .quoteRequestSection {
      flex-direction: column; /* Stack content and form vertically */
      padding: 30px 15px; /* Equal padding */
      margin: 20px auto; /* Center the section */
      max-width: 90%; /* Limit width for alignment */
      align-items: center; /* Center-align the container */
      box-sizing: border-box; /* Include padding in width calculations */
    }
  
    .contentContainer {
      width: 100%;
      max-width: 100%;   
      margin: 20px 0 auto;    
      text-align: left; /* Align text to the left */
    }
  
    .title {
      font-size: 28px; /* Mobile-friendly size */
      line-height: 1.3; /* Adjusted line height */
      margin: 0 0 10px; /* Only bottom spacing */
      padding: 0; /* No additional padding */
      max-width: 100%; /* Utilize full width */
      text-align: center;
    }
  
    .description {
      font-size: 16px; /* Slightly smaller font for mobile */
      line-height: 1.5; /* Comfortable line height */
      margin: 0; /* Remove all margins */
      padding: 0; /* Remove additional padding */
      max-width: 100%; /* Utilize full width */
      text-align: left; /* Align text to the left */
    }
  
    .quoteForm {
      width: 100%; /* Full width for the form */
      max-width: 400px; /* Restrict maximum width */
      margin: 0 auto; /* Center the form */
    }
  
    .formGroup {
      width: 95%; /* Restrict width for better alignment */
      margin: 0 auto;
    }
  
    .input,
    .select {
      width: 100%; /* Full width for inputs */
      padding: 12px 14px; /* Comfortable padding */
      margin-bottom: 14px; /* Spacing between fields */
    }
  
    .submitButton {
      width: auto; /* Dynamic width for button */
      padding: 10px 20px; /* Comfortable padding */
      font-size: 16px; /* Match mobile-friendly font size */
      margin: 20px auto 0; /* Center the button */
      display: flex; /* Flexbox for alignment */
      justify-content: center; /* Center-align content */
      align-items: center;
      border-radius: 50px; /* Rounded button */
    }
  
    .buttonIcon {
      width: 18px; /* Slightly smaller icon size for mobile */
      height: auto; /* Maintain aspect ratio */
      margin-left: 8px; /* Spacing between text and icon */
    }
  }
  
  @media (min-width: 951px) and (max-width: 1400px) {
    .quoteRequestSection {
      display: flex;
      flex-direction: row; /* Content and form side-by-side */
      padding: 30px 30px; /* Adjusted padding for alignment */
      margin: 40px auto; /* Center-align the section */
      max-width: 85%; /* Restrict section width */
      gap: 40px; /* Spacing between content and form */
      align-items: center; /* Center-align vertically */
      box-sizing: border-box; /* Include padding in width */
      border-radius: 40px; /* Slight rounding for polished design */
    }
  
    .contentContainer {
      flex: 1; /* Equal space for content */
      text-align: left; /* Align content text to the left */
      margin: 0; /* Remove additional margins */
      margin-top: 10px;
      padding-right: 20px; /* Add spacing between content and form */
    }
  
    .title {
      font-size: 40px; /* Slightly larger font size for better readability */
      line-height: 1.3; /* Comfortable spacing between lines */
      margin-bottom: 15px; /* Add spacing below the title */
      padding: 0; /* No additional padding */
      max-width: 100%; /* Utilize full width of the container */
      word-wrap: break-word; /* Ensure the text wraps neatly */
    }
  
    .description {
      font-size: 18px; /* Adjust font size for readability */
      line-height: 1.6; /* Comfortable line spacing */
      margin-bottom: 20px; /* Add spacing below the description */
      text-align: justify; /* Justify text for a clean layout */
      max-width: 90%; /* Utilize the available width */
    }
  
    .quoteForm {
      flex: 1; /* Equal space for form */
      max-width: 400px; /* Restrict form width */
      margin: 0 auto; /* Center-align the form */
      padding: 0; /* Remove extra padding */
    }
  
    .formGroup {
      width: 100%; /* Full width for form inputs */
      margin-bottom: 0px; /* Spacing between inputs */
    }
  
    .input,
    .select {
      width: 100%; /* Full width for inputs */
      padding: 16px 18px; /* Comfortable padding */
      margin-bottom: 5px; /* Spacing between fields */
      font-size: 16px; /* Adjusted font size */
      border: 1px solid #ddd; /* Subtle border for inputs */
      border-radius: 8px; /* Rounded corners for consistency */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for inputs */
      box-sizing: border-box; /* Prevent overflow */
    }
  
    .input:focus,
    .select:focus {
      outline: none; /* Remove default outline */
      border-color: #3b5a91; /* Highlight input on focus */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Enhanced shadow on focus */
    }
  
    .submitButton {
      display: flex; /* Flex for alignment */
      justify-content: center; /* Center the button text */
      align-items: center; /* Center-align content */
      width: 100%; /* Full width for button */
      padding: 14px 24px; /* Adjusted padding */
      font-size: 18px; /* Match desktop font size */
      margin-top: 20px; /* Space above the button */
      border-radius: 50px; /* Rounded button */
      background-color: #3b5a91; /* Button background color */
      color: #fff; /* Button text color */
      text-align: center; /* Center-align text */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
      cursor: pointer; /* Pointer cursor */
      transition: background-color 0.3s ease, transform 0.2s ease;
    }
  
    .submitButton:hover {
      background-color: #2d4a74; /* Darker button color on hover */
      transform: translateY(-2px); /* Slight upward movement */
    }
  
    .buttonIcon {
      width: 20px; /* Maintain icon size */
      height: auto; /* Maintain aspect ratio */
      margin-left: 10px; /* Spacing between text and icon */
    }
  }
  