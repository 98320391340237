html {
  scroll-behavior: smooth;
}


/* Main container adjustments */
.ContactUsContainer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  overflow: hidden;
  margin-top: 5px; /* Removes default margin for cleaner layout */
}

/* Responsive padding adjustments */
@media (max-width: 991px) {
  .ContactUsContainer {
    max-width: 100%;
  }
}

/* Contact Section adjustments */
.contactSection {
  border-radius: 10px;
  background-color: #eff1f7;
  display: flex;
  width: 100%;
  padding: 97px 70px;
  box-sizing: border-box;
  margin-top: 10px; /* Adds spacing from the top */
}

/* Center content within wrapper */
.contentWrapper {
  width: 100%;
  max-width: 1139px;
  display: flex;
  gap: 30px; /* Slightly increased gap for better spacing */
  margin: 0 auto;
}

/* Column adjustments */
.infoColumn {
  flex: 1;
}

.formColumn {
  flex: 1;
}

/* Title and text styling */
.title {
  color: #1e1e1e;
  font: 400 60px/1.2 Montserrat, sans-serif; /* Adjusted for better line height */
  margin-bottom: 10px; /* Adds space below the title */
}

.subtitle {
  color: #3b5a91;
  margin-top: 10px; /* Consistent margin for better spacing */
  font: 700 22px/1.4 Inter, sans-serif;
}

.description {
  color: #1e1e1e;
  margin-top: 16px; /* Reduced for better flow */
  font: 400 18px/1.6 Inter, sans-serif;
}


/* Separator adjustments */
/* Separator adjustments */
.separator {
  width: 100vw; /* Full width of the viewport */
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
  margin-bottom: -1px; /* Remove small gap consistently */
  display: block; /* Ensure it behaves consistently on all screens */
  padding: 0; /* Remove any padding that could introduce spacing */
  border: none; /* Remove any potential borders */
}



/* Mobile responsiveness */
@media (max-width: 991px) {
  .contactSection {
    padding: 40px 20px;
    margin-top: 10px; /* Reduced top margin for mobile */
  }

  .contentWrapper {
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Consistent gap for stacked layout */
  }

  .infoColumn,
  .formColumn {
    width: 100%;
  }

  .title {
    font-size: 36px; /* Adjusted for smaller screens */
    margin-bottom: 8px; /* Reduced bottom margin */
  }

  .description {
    font-size: 16px; /* Reduced font size for better readability */
    max-width: 100%;
  }

  .separator {
    width: 100%;
    height: auto; /* Ensures proper scaling on mobile */
  
  }
}


