.socialLinksContainer {
  margin-top: 51px;
}

.followText {
  color: #1e1e1e;
  font: 400 18px 'Inter', sans-serif;
}

.iconWrapper {
  display: flex;
  margin-top: 16px;
  gap: 38px;
}

.socialIcon {
  width: 36px;
  height: 36px;
  object-fit: contain;
  cursor: pointer; /* Make the icon cursor indicate clickability */
  z-index: 1; /* Ensure the image layer is above other potential elements */
}

@media (max-width: 991px) {
  .socialLinksContainer {
    margin-top: 40px;
  }
}
