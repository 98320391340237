.trustedBySection {
  text-align: center;
  margin-top: 90px;
  margin-bottom: 70px;
}

.trustedBySection h2 {
  color: #1e1e1e;
  font: 500 57px/1 Montserrat, sans-serif;
  margin-bottom: 30px; /* Increased space below the heading */
}

.trustedLogo {
  max-width: 150px;
  height: 100px; /* Set a consistent height for all logos */
  object-fit: contain; /* Ensures the image scales properly */
  margin: 0 auto;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 120px; /* Ensures consistent vertical alignment */
  padding: 10px; /* Adds spacing around logos for better layout */
}

.slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}


.slick-dots {
  margin-top: 10px; /* Reduced space between the slider and dots */
}

.slick-dots li button:before {
  font-size: 10px;
  color: #1e90ff; /* Alliance logo blue */
}

.slick-dots li.slick-active button:before {
  color: #32cd32; /* Alliance logo green */
}

@media (max-width: 991px) {
  .trustedBySection {
    margin-top: 80px;
  }

  .trustedBySection h2 {
    font-size: 40px;
  }
}
