.contactInfoContainer {
  margin-top: 48px;
  width: 100%;
  max-width: 445px;
}

.infoGrid {
  display: flex;
  gap: 20px;
}

.infoLabels,
.infoValues {
  font: 400 20px/1.6 Inter, sans-serif;
}

.infoLabels {
  color: #1e1e1e;
  font-weight: 700;
  text-align: right;
  min-width: 100px; /* Ensure labels align consistently */
}

.infoValues {
  color: #1e1e1e;
  word-break: break-word;
}

.email {
  color: #3b5a91;
}

@media (max-width: 991px) {
  .contactInfoContainer {
    margin-top: 40px;
    max-width: 100%;
  }

  .infoLabels,
  .infoValues {
    font-size: 18px;
  }
}
