.careerPage {
    max-width: none !important;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .separator {
    width: 100vw; /* Full width of the viewport */
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    object-fit: cover;
   
  }