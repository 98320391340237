.greenCleaningSection {
  display: flex;
  gap: 30px; /* Consistent gap with About Us */
  margin: 60px auto; /* Centering container with reduced top margin */
  padding: 0 40px; /* Adjusted padding */
  max-width: 1200px; /* Limit width for better alignment */
  align-items: stretch; /* Ensures even height of columns */
}

.imageContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

.greenCleaningImage {
  width: 100%;
  border-radius: 150px 10px 10px 10px; /* Consistent rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  height: 100%; /* Ensure it fills the container */
}

.contentContainer {
  flex: 1;
  background-color: #fff;
  background-image: url('../../Assets/AboutusGradient.png'); /* Add gradient PNG */
  background-repeat: no-repeat; /* Prevents repeating of the gradient */
  background-position: top; /* Positions the gradient at the top */
  border-radius: 10px 10px 150px 10px; /* Consistent with the About Us design */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  padding: 50px 28px; /* Reduced padding for smaller container */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content vertically */
  transition: padding 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.title {
  font: 500 36px/1.2 'Montserrat', sans-serif; /* Adjusted font size for consistency */
  color: #1e1e1e;
  margin-bottom: 20px;
  transition: font-size 0.3s ease; /* Smooth transition */
}

.description {
  font: 400 16px/1.6 'Inter', sans-serif; /* Consistent font size */
  color: #1e1e1e;
  transition: font-size 0.3s ease; /* Smooth transition */
}

/* Tablet View (max-width: 991px) */
@media (max-width: 991px) {
  .sectionGrid {
    grid-template-columns: repeat(2, 1fr); /* Two columns for tablet */
    gap: 20px; /* Spacing between cards */
    padding: 20px; /* Adjusted padding for tablet */
  }

  .contentWrapper {
    padding: 20px; /* Reduced padding for compact spacing */
    background-color: #fff;
    background-image: url('../../Assets/AboutusGradient.png'); /* Ensure gradient path */
    background-repeat: no-repeat;
    background-size: cover; /* Stretch gradient to fill the container */
    background-position: center; /* Center-align gradient */
    border-radius: 10px; /* Consistent rounded corners */
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    height: auto; /* Adjust height based on content */
    overflow: hidden; /* Prevents overflow issues */
  }

  .sectionImage {
    width: 100%; /* Full width for each column */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Match contentWrapper corners */
    object-fit: cover; /* Ensure images fit properly */
  }

  .sectionTitle {
    font-size: 24px; /* Adjust font size for tablet */
    margin-bottom: 15px; /* Reduce spacing below title */
    text-align: left; /* Align text to the left */
  }

  .description {
    font-size: 16px; /* Adjust text size for better readability */
    line-height: 1.5; /* Maintain comfortable spacing */
    text-align: justify; /* Improve text alignment */
  }

  .sectionGrid .contentWrapper:nth-child(1) {
    border-radius: 60px 10px 10px 10px; /* Rounded corners */
  }

  .sectionGrid .contentWrapper:nth-child(2) {
    border-radius: 10px 60px 10px 10px; /* Rounded corners */
  }

  .sectionGrid .contentWrapper:nth-child(4) {
    border-radius: 10px 10px 60px 10px; /* Rounded corners */
  }
}

@media (max-width: 767px) {
     .greenCleaningSection {
      flex-direction: column; /* Stack vertically */
      margin-top: 40px;
      padding: 0 20px;
    }
  
    .contentContainer {
      padding: 30px 20px; /* Reduced padding for mobile */
    }
  
    .title {
      font-size: 28px; /* Adjusted for smaller screens */
    }
  
    .description {
      font-size: 14px; /* Adjusted for smaller screens */
    }
  }
  