.featured-container {
  border: 0px solid red;
  display: flex;
  flex-direction: column;
  background-color: #EFF1F7;
  padding: 70px;
}

.featured-top {
  border: 0px solid red;
  display: flex;
  flex-direction: row;
}

.featured-image {
  display: flex;
  flex-direction: row;
  width: 50%;
  border: 0px solid red;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.featured-image img {
  border-radius: 0px 160px 0px 160px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.featured-details {
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 10px;
  padding: 70px;
  width: 50%;
}

.featured-details > * {
  margin: 0;
}

.featured-text {
  font-family: Inter, sans-serif;
  font-size: 19px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0074AD;
}

.featured-title {
  font-family: Montserrat, sans-serif;;
  font-size: 44px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1E1E1E;
}

.featured-date {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #484848;
}

.featured-description {
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 31.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1E1E1E;
}

.read-btn-container {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 25px;
}

.read-btn {
  font: Inter, sans-serif;;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: #FFFFFF;
  background-color: #3B5A91;
  border-radius: 64px;
  border-color: white;
  border: none;
  padding: 7px 18px 2px 18px;
  text-align: center;
  box-shadow: none;
  transform: translateX(-50%);
  font-size: 18px;
  width: min-content;
}

.read-btn:hover {
  cursor: pointer;
}

.read-btn p {
  line-height: 0;
  height: min-content;
  width: min-content;
}

.read-btn span {
  margin-left: 10px;
  height: min-content;
  width: min-content;
}

.filter-container {
  border: 0px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin-top: 120px;
  column-gap: 25px;
}

.filter-container select {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-color: rgb(194, 194, 194);
  border-radius: 7px;
  padding: 20px;
  width: 250px;
}

.featured-curve {
  background-color: white;
  width: 100%;
  height: 45px;
  border-radius: 90%/50px 50px 0 0;
  margin-top: -45px;
}

.blog-list-container {
  border: 0px solid red;
  padding: 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: center;
  row-gap: 50px;
  column-gap: 50px;
}

.blog-card {
  border: 0px solid red;
  height: min-content;
  width: 30%;
  background-color: #EFF1F7;
  box-shadow: 0px 4px 10px 0px #00000026;
  width: 400px;
  height: 449px;
  border-radius: 60px 12px 60px 12px;

}

.blog-card .blog-img img {
  width: 399px;
  height: 242px;
  border-top-left-radius: 60px;
  object-fit: cover;
}

.blog-card .blog-category-date {
  font-family: Inter, sans-serif;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}

.blog-card .blog-category-date .card-category {
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0074AD;
  margin-left: 20px;
}

.blog-card .blog-category-date .card-date {
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color:#484848;
  margin-right: 20px;
}

.blog-card .blog-title {
  font-family: Montserrat, sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 25.46px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1E1E1E;
  padding: 0px 20px 0px 20px;
}

.blog-card .blog-title p {
  padding: 0;
  margin-top: -8px;
}

.blog-description {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 18.08px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #272932;
  padding: 0px 20px 0px 20px;
  margin-top: -7px;
  display: -webkit-box; /* Enable the flexbox for clamping */
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical; /* Set the orientation to vertical */
  overflow: hidden; /* Hide the overflowed content */
  text-overflow: ellipsis; /* Add "..." if the text is cut off */
}

@media screen and (max-width: 900px) {
  .featured-container {
    padding: 10px;
    margin-top: -30px;
  }

  .featured-top {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .featured-image {
    width: 100%;
  }

  .featured-image img {
    height: 40vh;
    width: 80vw;
    object-fit: cover;
  }
  .featured-title {
    text-align: center;
  }

  .featured-details {
    margin-top: 25px;
    padding: 10px;
    text-align: center;
    align-items: center;
    width: 90%;
  }

  .featured-text, .featured-date {
    font-size: 13px;
  }

  .featured-title {
    font-size: 35px;
  }

  .read-btn {
    padding: 3px 12px 7px 12px;
    font-size: 17px;
    transform: translateX(-30%);
  }

  .read-btn p {
    font:  Inter, sans-serif;
    margin-top: 22px;
  }

  .read-btn span img {
    width: 24px;
    height: 24px;
    margin-top: 9px;
  }

  .filter-container {
    width: 90%;
    max-width: 100vw;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 20px;
  }

  .blog-list-container {
    padding: 10px;
  }

  .blog-card {
    width: 80vw;
    height: fit-content;
  }

  .blog-card .blog-img img {
    height: 40vh;
    width: 80vw;
  }
}
