.hiringBox {
  border-radius: 10px;
  background-color: #3b5a91; /* Background color for the box */
  display: flex;
  flex-direction: column;
  padding: 35px 20px;
  color: #fff; /* White text for contrast */
  align-items: flex-start; /* Align items to the left */
  width: 100%; /* Full width for responsiveness */
  max-width: 800px; /* Restrict maximum width for balance */
  margin: 20px auto; /* Center the box with vertical spacing */
  box-sizing: border-box; /* Ensure padding doesn't add to the width */
}

.hiringTitle {
  font-size: 28px; /* Adjust font size for better readability */
  font-weight: 600; /* Bold for emphasis */
  line-height: 1.2; /* Improved line height */
  text-align: left;
  margin-bottom: 10px; /* Add spacing below the title */
}

.divider {
  margin-top: 16px;
  height: 1px;
  border: 1px solid #fff; /* Subtle white divider */
  width: 90%; /* Restrict width for balance */
}

.hiringText {
  font-size: 16px; /* Slightly smaller text for readability */
  font-weight: 400;
  line-height: 1.6; /* Improved line height for better readability */
  margin-top: 20px; /* Spacing above the text */
}

.hiringButton {
  border-radius: 44px;
  background-color: #fff; /* White button background */
  align-self: stretch; /* Stretch to match the container width */
  display: inline-flex;
  align-items: center;
  gap: 10px; /* Space between text and icon */
  padding: 10px 20px;
  color: #3b5a91; /* Blue text for button */
  font-size: 16px; /* Button text size */
  font-weight: bold;
  text-decoration: none;
  white-space: nowrap; /* Prevent text wrapping */
  margin-top: 20px; /* Spacing above the button */
  justify-content: center; /* Center-align button content */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effect */
  overflow: hidden; /* Prevent content overflow */
}

.hiringButton:hover {
  background-color: #2c4770; /* Slightly darker blue on hover */
  color: #fff; /* White text on hover */
}

.buttonIcon {
  width: 24px; /* Icon size */
  height: 24px;
  object-fit: contain;
}

.hiringButton:hover .buttonIcon {
  transform: translateX(5px); /* Subtle icon movement on hover */
  transition: transform 0.3s ease; /* Smooth transition */
}

/* Tablet Adjustments */
@media (max-width: 991px) {
  .hiringBox {
    padding: 30px 15px; /* Reduce padding for smaller screens */
    margin: 20px auto; /* Center the box */
  }

  .hiringTitle {
    font-size: 24px; /* Adjust title size for tablets */
  }

  .hiringText {
    font-size: 15px; /* Slightly smaller text for readability */
  }

  .hiringButton {
    font-size: 14px; /* Adjust button text size */
    padding: 10px 20px; /* Ensure comfortable padding */
    align-self: center;
    max-width: 200px; /* Prevent the button from stretching */
    width: 100%; /* Make it responsive to container width */
    border-radius: 36px; /* Slightly reduce border radius */
  }
}

/* Mobile (Same as Tablet) */
@media (max-width: 600px) {
  .hiringBox {
    padding: 30px 15px; /* Match tablet padding */
    margin: 20px auto; /* Center the box */
  }

  .hiringTitle {
    font-size: 24px; /* Match tablet title size */
    text-align: left; /* Align text to the left */
  }

  .hiringText {
    font-size: 15px; /* Match tablet text size */
    line-height: 1.6; /* Match tablet line height */
    text-align: left; /* Align text to the left */
  }

  .hiringButton {
    font-size: 14px; /* Match tablet button text size */
    padding: 10px 20px; /* Match tablet button padding */
    max-width: 250px; /* Match tablet max width */
    width: 100%; /* Ensure button spans full width */
    border-radius: 36px; /* Match tablet border radius */
  }

  .buttonIcon {
    width: 20px; /* Match tablet icon size */
    height: 20px;
  }
}