/* Container for the Hiring Process Section */
.hiringProcessContainer {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 1024px;
    margin: 50px auto;
    gap: 30px; /* Space between image and text container */
  }
  
  /* Image Container on the Left */
  .imageContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    position: relative;
    min-height: 450px;
    overflow: hidden;
    transition: transform 0.3s; /* Smooth hover effect */
  }
  
  .imageContainer:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  
  /* Image Styling */
  .processImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
  
  /* Text Content Container on the Right */
  .infoContent {
    flex: 1;
    background-color: #f9f9f9;
    background-image: url('../../Assets/AboutusGradient.png'); /* Add gradient PNG */
    background-repeat: no-repeat;
    background-position: top;
    border-radius: 0 0 90px 0; /* Rounds only the bottom-right corner */
    padding: 30px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden; /* Keeps content within the rounded border */
  }
  
  .infoContent:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2); /* Shadow increases on hover */
  }
  
  /* Title in the Text Container */
  .title {
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-weight: 500;
    color: #1e1e1e;
    margin-bottom: 20px;
  }
  
  /* Description in the Text Container */
  .description {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 25px;
  }
  
  /* Apply Now Button Styling */
  .applyButton {
    background-color: #3b5a91;
    color: #fff;
    border-radius: 25px;
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .applyButton:hover {
    background-color: #2c4770;
    transform: translateY(-3px); /* Slight upward movement on hover */
  }
  
  .buttonIcon {
    width: 20px;
    transition: transform 0.3s;
  }
  
  .applyButton:hover .buttonIcon {
    transform: translateX(10px); /* Moves the icon slightly to the right on hover */
  }

  /* Remove bullet points from the list */
.description ul {
    list-style-type: none; /* Removes the bullets */
    padding-left: 0; /* Removes default padding */
    margin-left: 0; /* Aligns list items with the text */
  }
  
  /* Optional: Add spacing between list items */
  .description li {
    margin-bottom: 10px; /* Adds spacing between list items */
  }
  
  
  @media (max-width: 991px) {
    .hiringProcessContainer {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin-top: 40px;
    }
  
    .imageContainer,
    .infoContent {
      width: 95%;
    }
  
    .infoContent {
      padding: 20px;
    }
  
    .title {
      font-size: 24px;
    }
  }
  
  @media (max-width: 991px) {
    .infoContent {
      background-size: 100% 150%; /* Resize gradient for tablet screens */
      background-position: center top; /* Center-align gradient for tablet */
      border-radius: 0 0 60px 0; /* Slightly smaller rounding for better fit */
    }
  }
  
  @media (max-width: 600px) {
  .hiringProcessContainer {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 20px auto; /* Reduce margin for compact layout */
    width: 100%; /* Full width for mobile */
  }

  .imageContainer {
    width: 90%; /* Full width for the image container */
    min-height: 300px; /* Reduce height for smaller screens */
  }

  .infoContent {
    width: 80%; /* Reduce width for better fit */
    

    background-position: center top; /* Ensure gradient alignment */
    
  }

  .title {
    font-size: 20px; /* Reduce font size for better readability */
    margin-bottom: 15px; /* Adjust spacing below the title */
  }

  .description {
    font-size: 12px; /* Smaller font size for description */
    line-height: 1.4; /* Adjust line height */
    margin-bottom: 20px; /* Reduce bottom margin */
  }

  .description ul {
    margin-left: 10px; /* Add slight indentation for lists */
  }

  .applyButton {
    font-size: 12px; /* Reduce button font size */
    padding: 6px 12px; /* Adjust padding for mobile */
    border-radius: 20px; /* Smaller button radius */
  }

  .buttonIcon {
    width: 16px; /* Smaller icon for button */
  }

  .applyButton:hover .buttonIcon {
    transform: translateX(5px); /* Slightly smaller movement on hover */
  }

  .imageContainer:hover {
    transform: none; /* Remove hover zoom effect for mobile to avoid layout shifting */
  }
}
