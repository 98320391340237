.servicePage {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .decorativeImage {
    aspect-ratio: 22.73;
    object-fit: contain;
    object-position: center;
    width: 100%;
    z-index: 10;
    margin-top: 120px;
  }
  
  @media (max-width: 991px) {
    .decorativeImage {
      max-width: 100%;
      margin-top: 40px;
    }
  }