/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.page-button {
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

.page-button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.page-button:hover {
  background-color: #f0f0f0;
}
