.ourValues {
  margin-top: 100px;
  width: 100%;
  max-width: 1200px; /* Limit max-width for centered alignment */
  padding: 0 20px; /* Consistent padding on both sides */
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box; /* Include padding in the width */
}

.title {
  color: #1e1e1e;
  font: 350 70px/1 Montserrat, sans-serif;
  text-align: center;
  margin-bottom: 50px; /* Add space between title and grid */
}

.valuesContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3-column grid for large screens */
  gap: 20px; /* Consistent gap between cards */
  margin-top: 20px;
}

.valueCard {
  background-color: #eff1f7;
  border-radius: 20px; /* More subtle rounding */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.valueCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.valueIcon {
  margin-top: 15px;
  margin-bottom: 20px;
  width: 50px;
  height: 50px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.valueCard:hover .valueIcon {
  transform: scale(1.1);
}

.valueTitle {
  color: #1e1e1e;
  font: 600 24px/1.2 Montserrat, sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}

.valueDescription {
  color: #1e1e1e;
  font: 400 16px/1.6 Inter, sans-serif;
  text-align: left;
  margin-top: 10px;
}

/* Tablet Adjustments */
@media (max-width: 991px) {
  .ourValues {
    margin-top: 50px;
    padding: 0 15px;
  }

  .title {
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 30px;
  }

  .valuesContainer {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablet */
    gap: 20px;
  }

  .valueCard {
    padding: 20px;
  }

  .valueIcon {
    width: 40px;
    height: 40px;
  }

  .valueTitle {
    font-size: 20px;
  }

  .valueDescription {
    font-size: 14px;
  }
}

/* Mobile Adjustments */
@media (max-width: 600px) {
  .ourValues {
    padding: 0 10px;
  }

  .title {
    font-size: 32px;
  }

  .valuesContainer {
    grid-template-columns: 1fr; /* 1 column on mobile */
    gap: 15px;
  }

  .valueCard {
    padding: 15px;
  }

  .valueIcon {
    width: 30px;
    height: 30px;
  }

  .valueTitle {
    font-size: 18px;
  }

  .valueDescription {
    font-size: 14px;
    margin: 5px 10px;
  }
}
