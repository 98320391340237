.footer {
  background-color: #b7c233;
  padding: 63px 80px;
  max-width: 100vw;
  /*box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1); /* Soft shadow at the top to separate from body */
}

.footerCurve {
  max-width: 100vw;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.companyInfo {
  max-width: 300px;
}

.footerLogo {
  width: 214px;
  max-width: 100%;
}

.companyDescription {
  color: #1e1e1e;
  margin-top: 34px;
  font: 400 18px Inter, sans-serif;
  line-height: 1.5; /* Set line-height to create space between lines */
}

.footerNav {
  display: flex;
  gap: 55px;
}

.navColumn h3 {
  color: #1e1e1e;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 13px;
}

.navColumn ul {
  list-style-type: none;
  padding: 0;
}

.navColumn li {
  margin-top: 7px;
}

.navColumn a {
  color: #1e1e1e;
  font: 400 18px Inter, sans-serif;
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth color transition */
}

/* Link hover effect */
.navColumn a:hover {
  color: #3b5a91; /* Change color on hover */
}

.hiringBadge {
  border-radius: 5px;
  background-color: #1e1e1e;
  color: #c5ce5b;
  font-size: 12px;
  padding: 3px 6px;
  animation: blink 1s infinite; /* Apply blinking animation */
}

/* Keyframes for blinking effect */
@keyframes blink {
  0%, 100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Fully transparent */
  }
}
.socialConnect {
  margin-top: 40px;
}

.socialTitle {
  color: #1e1e1e;
  font: 400 18px Inter, sans-serif;
  margin-bottom: 16px;
}

.socialIcons {
  display: flex;
  gap: 28px;
}

.socialIcon {
  width: 28px;
  height: 28px;
  transition: transform 0.3s ease; /* Smooth transition for scaling */
}

/* Hover effect for social icons */
.socialIcon:hover {
  transform: scale(1.1); /* Slightly increase size on hover */
}


.copyright {
  color: #1e1e1e;
  margin-top: 23px;
  font: 400 14px Inter, sans-serif;
  text-align: right;
}

@media (max-width: 991px) {
  .horizontalLine {
    width: 100%; /* Full width on smaller screens */
    margin-left: 0;
  }

  .footerNav {
    
    gap: 10px;
  }

}

@media (max-width: 601px) {
  .footer {
    padding: 40px 20px;
  }

  .footerContent {
    flex-direction: column;
  }

  .footerNav {
    flex-direction: column;
    gap: 20px;
  }

  .socialConnect {
    margin-top: 20px;
  }
}

/* Horizontal line above copyright */
.horizontalLine {
  margin-top: 20px;
  width: calc(100% - 50px); /* Adjusts line width to align with "Services" section */
  height: 1px;
  background-color: #1e1e1e;
  margin-left: 10px;
  margin-right: 0px;
}

