.heroSection {
  display: flex;
  gap: 20px;
  margin-top: 121px;
  padding: 0 30px;
}

.imageContainer {
  flex: 1;
  margin-top: 30px; 
  margin-left: 30px;
}

.heroImage {
  width: 90%;
  border-radius: 160px 0 160px 0;
  object-fit: cover;
  transition: transform 0.3s ease; /* Smooth zoom on hover */
}

.heroImage:hover {
  transform: scale(1.02); /* Slight zoom effect */
}

.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -15px;

}

.title {
  font: 60px/60px Montserrat, sans-serif;
  color: #1e1e1e;
  margin-bottom: 19px;
  max-width: 50%;
}

.description {
  font: 18px/26px Inter, sans-serif;
  color: #1e1e1e;
  margin-bottom: 33px;
  max-width: 95%;
}

/* Style for italic text within description */
.description em {
  color: #3b5a91;
}

.ctaButton {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #3b5a91;
  border: none;
  border-radius: 64px;
  color: #fff;
  font: 700 20px/4px 'Darker Grotesque', sans-serif;
  padding: 10px 20px;
  cursor: pointer;
  align-self: flex-start;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth hover effect */
}

.ctaButton:hover {
  background-color: #2a4a78; /* Darker on hover */
  
}

.buttonIcon {
  width: 39px;
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.ctaButton:hover .buttonIcon {
  transform: translateX(5px); /* Move icon to the right on hover */
}

@media (max-width: 991px) {
  .heroSection {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    padding: 0 60px;
    gap: 20px; /* Consistent gap for mobile */
  }

  .imageContainer {
    
    max-width: 100%;
  }

  .heroImage {
    width: 100%;
    height: auto; /* Preserve aspect ratio */
    border-radius: 80px 0 80px 0; /* Maintain border-radius style */
  }

  .contentContainer {
    margin-top: 0px;
    width: 90%;
    text-align: center; /* Center align content */
  }

  .title {
    font-size: 25px; /* Adjusted for better readability */
    line-height: 42px;
    margin-bottom: 15px; /* Reduce bottom margin */
    max-width: 100%;
    text-align: left;
  }

  .description {
    font-size: 16px; /* Adjusted for mobile */
    line-height: 24px;
    margin-bottom: 30px; /* Reduce bottom margin */
    text-align: left;
  }

  .ctaButton {
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 50px;
    align-self: center; /* Center align button */
  }

  .buttonIcon {
    width: 30px; /* Adjusted for mobile */
  }
}

@media (max-width: 600px) {
  .heroSection {
    padding: 0 15px;
    margin-top: 50px;
  }

  .imageContainer {
    max-width: 350px; /* Further adjust for smaller screens */
  }

  .title {
    font-size: 30px;
    line-height: 36px;
  }

  .description {
    font-size: 14px;
    line-height: 20px;
  }

  .ctaButton {
    padding: 8px 14px;
    font-size: 14px;
    border-radius: 44px; /* Slightly reduce border-radius */
  }

  .buttonIcon {
    width: 24px; /* Smaller icon for compact view */
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .heroSection {
    display: flex;
    flex-direction: row; /* Aligns like desktop */
    gap: 30px; /* Adjust gap between image and text */    
    padding: 0 40px 30px; /* Adjusted padding for better fit */
  }

  .imageContainer {
    flex: 1;
    margin: 0; /* Remove extra margins */
    max-width: 50%; /* Ensure image takes appropriate width */
    margin-top: 40px;
  }

  .heroImage {
    width: 100%; /* Full width for the image */
    height: auto; /* Maintain aspect ratio */
    border-radius: 160px 0 160px 0; /* Keep rounded corners */
    object-fit: cover;
  }

  .contentContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left; /* Left align text */
  }

  .title {
    font: 54px/60px Montserrat, sans-serif; /* Slightly reduced font size */
    color: #1e1e1e;
    margin-bottom: 24px; /* Consistent spacing */
    max-width: 90%; /* Ensure the title doesn't overflow */
  }

  .description {
    font: 18px/26px Inter, sans-serif; /* Adjusted font size */
    color: #1e1e1e;
    margin-bottom: 30px; /* Reduce gap below */
    max-width: 100%; /* Align width with text */
  }

  .description em {
    color: #3b5a91; /* Highlighted text color */
  }

  .ctaButton {
    display: flex;
    align-items: center;
    gap: 12px; /* Space between text and icon */
    background-color: #3b5a91;
    border: none;
    border-radius: 64px;
    color: #fff;
    font: 700 18px/24px 'Darker Grotesque', sans-serif; /* Slightly smaller font */
    padding: 12px 20px; /* Adjusted padding */
    align-self: flex-start;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
  }

  .ctaButton:hover {
    background-color: #2a4a78; /* Darker shade on hover */
  }

  .buttonIcon {
    width: 30px; /* Adjusted icon size */
    transition: transform 0.3s ease; /* Smooth hover effect */
  }

  .ctaButton:hover .buttonIcon {
    transform: translateX(5px); /* Move icon slightly right on hover */
  }
}

