/* Desktop View */
.contactForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 440px;
  margin-top: 28px;
}

.label {
  color: #1e1e1e;
  font: 600 16px/1.4 Inter, sans-serif;
  margin-bottom: 4px;
}

.input,
.select {
  border-radius: 10px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 18px;
  margin-bottom: 18px;
  font: 400 16px/1.4 Inter, sans-serif;
  color: rgba(30, 30, 30, 0.75);
  width: 100%;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.selectWrapper {
  position: relative;
  width: 100%;
}

.selectWrapper::after {
  content: '';
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background-image: url('https://cdn.builder.io/api/v1/image/assets/TEMP/c8768b4c2120bbfd9b87d50356d9017596c598c93a9bbb181047fe3b74b21e99?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f');
  background-size: contain;
  pointer-events: none;
}

.submitButton {
  display: flex;
  align-items: center;
  justify-content: center;  
  padding: 12px 20px; /* Adjust padding for a balanced look */
  max-width: 300px; /* Limit the button width */
  background-color: #3b5a91; /* Button background color */
  color: #ffffff; /* Text color */
  font: 22 'Darker Grotesque', -apple-system, Roboto, Helvetica, sans-serif;
  border: none;
  border-radius: 14px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-decoration: none; /* Remove underline for links */
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #4caf50;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Tablet Adjustments */
@media (max-width: 991px) {
  .contactForm {
    padding: 0 20px;
    max-width: 95%;
  }

  .label {
    font-size: 15px;
    margin-bottom: 3px;
  }

  .input,
  .select {
    padding: 16px;
    font-size: 15px;
    margin-bottom: 16px;
    width: 100%;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .selectWrapper {
    width: 100%;
    position: relative;
  }

  .submitButton {
    padding: 14px 16px;
    font-size: 18px;
    width: 100%;
    box-sizing: border-box;
  }

  .loader {
    margin-top: 15px;
  }

  .spinner {
    width: 30px;
    height: 30px;
  }
}

/* Mobile Adjustments */
@media (max-width: 600px) {
  .contactForm {
    padding: 0 15px;
    max-width: 95%;
  }

  .label {
    font-size: 14px;
    margin-bottom: 3px;
  }

  .input,
  .select {
    padding: 10px;
    font-size: 14px;
    margin-bottom: 16px;
    width: 100%;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .selectWrapper {
    width: 100%;
    position: relative;
  }

  .selectWrapper::after {
    right: 10px;
    width: 16px;
    height: 16px;
    top: 50%;
    transform: translateY(-50%);
    background-size: contain;
  }

  .submitButton {
    padding: 14px 16px;
    font-size: 18px;
    width: 100%;
    box-sizing: border-box;
  }

  .loader {
    margin-top: 15px;
  }

  .spinner {
    width: 30px;
    height: 30px;
  }
}

/* Large Screen Adjustments */
@media (min-width: 1200px) {
  .contactForm {
    max-width: 600px; /* Increase max-width for larger screens */
    margin-top: 32px;
    padding: 0 30px; /* Increase padding for larger screens */
  }

  .label {
    font-size: 18px; /* Slightly larger font for labels */
    margin-bottom: 6px;
  }

  .input,
  .select {
    padding: 20px; /* Add more padding for larger screens */
    font-size: 18px; /* Increase font size for better readability */
    margin-bottom: 20px; /* Increase spacing between fields */
  }

  .selectWrapper::after {
    right: 20px; /* Adjust icon position for larger screen */
    width: 26px; /* Larger icon for better visual balance */
    height: 26px;
  }

  .submitButton {
    padding: 18px 20px; /* Increase padding for button */
    font-size: 24px; /* Increase font size for the button text */
  }

  .loader {
    margin-top: 25px; /* Adjust margin for better alignment */
  }

  .spinner {
    width: 40px; /* Increase spinner size */
    height: 40px;
  }
}

