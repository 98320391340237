/* Desktop Styles */
body {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  
.landingPage {
    padding-top: 0 !important;
    margin-top: 0 !important;
    position: relative;
    top: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 100vh; /* Fills the full viewport height */
  }
  
  .contentWrapper {
    border-radius: 10px;
    background-color: #eff1f7;
    background-image: url('../../Assets/Gradients/CareersGradientVector.png'); /* Update with the correct path */
    background-size: contain; /* Makes the gradient cover the entire section */
    background-position: center; /* Centers the gradient */
    background-repeat: no-repeat; /* Ensures the image doesn’t repeat */
    background-position: calc(0% + 30px) calc(70% + 150px);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    flex-grow: 1; /* Allows it to grow and fill remaining space */
  }
  
  .innerContent {
    display: flex;
    margin-bottom: -12px;
    width: 100%;
    max-width: 1139px;
    flex-direction: column;
    flex-grow: 1; /* Ensures it stretches vertically */
  }
  
  .logo {
    aspect-ratio: 4.39;
    object-fit: contain;
    object-position: center;
    width: 250px;
    max-width: 100%;
  }
  
  .mainContent {
    margin-top: 30px;
  }
  
  .contentColumns {
    gap: 20px;
    display: flex;
  }
  
  .leftColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 56%;
    margin-left: 0px;
  }
  
  .textContent {
    display: flex;
    min-height: 517px;
    flex-grow: 1;
    flex-direction: column;
    color: #1e1e1e;
    justify-content: start;
  }
  
  .mainHeading {
    font: 450 48px Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
    max-width: 85%;
  }
  
  .descriptionWrapper {
    border-radius: 0;
    display: flex;
    margin-top: 10px;
    max-width: 100%;
    width: 571px;
    flex-direction: column;
    font-family: Inter, sans-serif;
    font-weight: 400;
  }
  
  .description {
    font-size: 20px;
    line-height: 32px;
  }
  
  .disclaimer {
    font-size: 12px;
    line-height: 19px;
    margin: 96px 29px 0 0;
  }
  
  .rightColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 44%;
    margin-left: 20px;
  }
  
  .formWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    font: 16px/1.4 Inter, sans-serif;
  }
  
  .formGroup {
    border-radius: 10px;
    
    display: flex;
    width: 100%;
    max-width: 440px;
    flex-direction: column;
    margin-bottom: 32px;
  }
  
  .formLabel {
    color: #1e1e1e;
    font-weight: 600;
    align-self: start;
  }
  
  .formInput {
    align-self: stretch;
    border-radius: 10px;
    background-color: #fff;
    margin-top: 4px;
    gap: 10px;
    overflow: hidden;
    color: rgba(30, 30, 30, 0.75);
    font-weight: 400;
    padding: 18px;
    border: none;
  }
  
  .checkboxWrapper {
    display: flex;
    margin-top: 32px;
    width: 100%;
    align-items: start;
    gap: 24px;
    font-size: 12px;
    color: #1e1e1e;
    font-weight: 400;
    line-height: 17px;
    justify-content: start;
  }
  
  .checkbox {
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    width: 32px;
    height: 32px;
  }
  
  .checkboxLabel {
    flex: 1;
    flex-basis: 0%;
  }
  
  .submitButton {
    margin-top: 20px;
    background-color: #3b5a91;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    padding: 12px 24px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
    text-align: center;
  }
  
  .submitButton:hover {
    background-color: #2f4774;
    transform: translateY(-2px);
  }
  
  .submitButton:active {
    background-color: #2f4774;
    transform: translateY(0);
  }
  
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }


  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #4caf50;
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Mobile Styles */
  @media (max-width: 991px) {
    .body{
        padding-top: 0px;
    }
    .contentWrapper {
      max-width: 100%;
      padding: 10px 20px;
      background-position: right calc(40% - 50px); /* Moves background up by 50px */
      background-size: 150% 100%; /* Creates a semi-circle effect on the right side */
      
    }
  
    .innerContent {
      max-width: 100%;
      margin-bottom: 10px;
    }
  
    .mainContent {
      max-width: 100%;
    }
  
    .contentColumns {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  
    .leftColumn,
    .rightColumn {
      width: 90%;
    }
  
    .textContent {
      max-width: 100%;
      margin-top: 40px;
    }
  
    .mainHeading {
      max-width: 100%;
      font-size: 40px;
      line-height: 49px;
    }
  
    .descriptionWrapper {
      margin-top: 40px;
    }
  
    .description {
      max-width: 100%;
    }
  
    .disclaimer {
      max-width: 100%;
      margin: 40px 10px 0 0;
    }
  
    .formWrapper {
      max-width: 90%;
      margin-top: 40px;
    }
  
    .formGroup {
      max-width: 100%;
    }
  
    .checkboxWrapper {
      max-width: 100%;
    }

    .checkboxLabel{
        margin-left: -10px;
    }
  
    .submitButton {
        white-space: initial;
        width: 100%; /* Sets button width to match form inputs */
        box-sizing: border-box; /* Ensures padding doesn’t affect width */
      }
  }
  