.aboutSection {
  display: flex;
  flex-direction: row;
  width: 90%;
  max-width: 1024px;
  margin: 60px auto;
  gap: 30px;
}

.imageContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  position: relative;
  min-height: 450px;
  overflow: hidden;
  transition: transform 0.3s; /* Smooth hover effect */
}

.imageContainer:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.supervisorImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.supervisorInfo {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(197, 206, 91, 0.6); 
  color: #fff;
  border-radius: 8px;
  padding: 10px 20px;
}

.supervisorName {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 600;
}

.supervisorRole {
  font-size: 12px;
  font-weight: 300;
  margin-top: 4px;
}

.aboutContent {
  flex: 1;
  background-color: #f9f9f9;
  background-image: url('../../Assets/AboutusGradient.png'); /* Add gradient PNG */
  background-repeat: no-repeat; /* Prevents repeating of the gradient */
  background-position: top; /* Positions the gradient at the top */
  border-radius: 10px 10px 90px 10px; /* Rounds only the bottom-right corner */
  padding: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative; /* Ensures proper stacking with other elements */
  overflow: hidden; /* Keeps content within the rounded border */
}

.aboutContent:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2); /* Shadow increases on hover */
}


.aboutTitle {
  font-family: 'Montserrat', sans-serif;
  position: relative;
  font-size: 32px;
  font-weight: 500;
  color: #1e1e1e;
  margin-bottom: 20px;
  background-size: cover;
  background-position: center;
}

.aboutTitle::after {
  content: ' Maintenance';
  display: block; /* Forces a line break after "About" */
  font-size: inherit;
  font-weight: inherit;
}


.aboutDescription {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #4a4a4a;
  margin-bottom: 25px;
}

.exploreButton {
  background-color: #3b5a91;
  color: #fff;
  border-radius: 25px;
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s; /* Smooth hover effects */
}

.exploreButton:hover {
  background-color: #2c4770;
  transform: translateY(-3px); /* Slight upward movement on hover */
}

.buttonIcon {
  width: 20px;
  transition: transform 0.3s; /* Smooth hover effect */
}

.exploreButton:hover .buttonIcon {
    transform: translateX(10px);
}

@media (max-width: 991px) {
  /* For Tablets */
  .aboutSection {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align items */
    gap: 30px; /* Increase spacing between the sections */
    margin: 40px 20px; /* Reduce horizontal margins for better fit */
  }

  .imageContainer,
  .aboutContent {
    width: 100%; /* Full width for both sections */
    max-width: 600px; /* Limit the width for better readability */
  }

  .imageContainer {
    min-height: 300px; /* Reduce the height for smaller screens */
  }

  .supervisorInfo {
    bottom: 20px; /* Adjust positioning for better visibility */
    right: 20px;
    padding: 8px 16px; /* Slightly reduce padding */
    font-size: 14px; /* Adjust text size */
  }

  .supervisorName {
    gap: 6px; /* Reduce spacing between icon and text */
    font-size: 16px; /* Smaller font for name */
  }

  .supervisorRole {
    font-size: 12px; /* Keep role text compact */
  }

  .aboutContent {
    padding: 20px; /* Reduce padding */
    background-position: top center; /* Center the gradient */
    background-size: 120%; /* Adjust gradient size for better fit */
    border-radius: 20px; /* Simplify the border-radius for cleaner look */
  }

  .aboutTitle {
    font-size: 28px; /* Slightly reduce font size */
    margin-bottom: 15px;
  }

  .aboutDescription {
    font-size: 14px; /* Keep font size readable */
    line-height: 1.5;
    margin-bottom: 20px;
  }

  .exploreButton {
    padding: 10px 20px; /* Adjust button size */
    font-size: 14px; /* Reduce font size for button text */
    gap: 6px; /* Reduce space between text and icon */
  }

  .buttonIcon {
    width: 16px; /* Reduce icon size */
  }
}

@media (max-width: 600px) {
  /* For Mobile */
  .aboutSection {
    margin: 20px 15px; /* Reduce overall margins */
    gap: 20px; /* Maintain spacing between sections */
  }

  .imageContainer {
    min-height: 250px; /* Further reduce image height */
  }

  .supervisorInfo {
    bottom: 10px; /* Adjust for smaller screen */
    right: 10px;
    padding: 6px 12px; /* Reduce padding */
    font-size: 12px; /* Make text smaller */
  }

  .supervisorName {
    font-size: 14px; /* Adjust name size */
    gap: 4px; /* Reduce gap */
  }

  .supervisorRole {
    font-size: 10px; /* Reduce role text size */
  }

  .aboutContent {
    padding: 5px; /* Reduce padding for smaller screens */
    padding-bottom: 20px;
    background-size: cover; /* Stretch gradient to cover area */
    border-radius: 10px; /* Simplify border-radius */
  }

  .aboutTitle {
    font-size: 22px; /* Further reduce font size */
    margin-bottom: 10px; /* Reduce bottom margin */
  }

  .aboutDescription {
    font-size: 12px; /* Adjust text size */
    line-height: 1.4; /* Slightly reduce line height */
  }

  .exploreButton {
    padding: 8px 16px; /* Adjust button padding */
    font-size: 12px; /* Reduce text size */
    gap: 4px; /* Adjust gap */
  }

  .buttonIcon {
    width: 14px; /* Make icon smaller */
  }
}
