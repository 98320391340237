.cardWrapper {
 
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  position: relative;
  min-height: 300px; /* Reduced min-height for compact layout */
  width: 100%;
  max-width: 450px; /* Reduced max-width for better fit */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease;
  margin: 0px; /* Reduced margin for tighter layout */
}



.cardWrapper:hover {
  transform: translateY(-5px);
}

.cardImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.cardContent {
  position: relative;
  width: 50%; /* Full width for better content fit */
  border-radius: 0 0 10px 10px; /* Rounded bottom corners */
  background-color: rgba(132, 177, 227, 0.8);
  display: flex;
  flex-direction: column;
  font-family: Inter, sans-serif;
  color: #ffffff;
  padding: 20px; /* Reduced padding for a compact design */
  z-index: 1;
}

.cardTitle {
  font-size: 20px; /* Reduced font size */
  font-weight: 600;
  line-height: 1.2;
}

.cardDescription {
  font-size: 14px; /* Reduced font size */
  font-weight: 400;
  line-height: 1.4;
  margin-top: 1px; /* Reduced margin */
}

.learnMoreButton {
  bottom: 20px; /* Move button 20px up from the border */
  left: 20px;
  position: relative; /* Adjusted to stay inside the card content */
  margin-top: auto; /* Pushes the button to the bottom of the card content */
  align-self: flex-start; /* Aligns button to the left */
  border-radius: 44px;
  background-color: #ffffff;
  color: #3b5a91;
  padding: 10px 20px; /* Adjusted padding for better visibility */
  font: 800 16px/1 "Darker Grotesque", -apple-system, Roboto, Helvetica, sans-serif;
  border: none;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.learnMoreButton:hover {
  background-color: #3b5a91;
  color: #ffffff;
}

.learnMoreButton:active {
  transform: translateY(1px);
}





@media (max-width: 991px) {
  .cardWrapper {
    max-width: 100%; /* Let cards take up full width of the column */
    margin: 0; /* Ensure no extra spacing */
    height: auto; /* Adjust height dynamically */
  }

  .cardContent {
    width: 60%;
    border-radius: 10px;
  }

  .learnMoreButton {
    padding: 8px 10px; /* Reduced padding */
  }
}

@media (max-width: 600px) {
  .cardWrapper {
    max-width: 100%;
    margin: 10px 0; /* Adjust margin for mobile view */
  }

  .cardTitle {
    font-size: 18px; /* Reduced font size for mobile */
  }

  .cardDescription {
    font-size: 12px; /* Reduced font size for mobile */
  }

  .learnMoreButton {
    padding: 8px 10px; /* Further reduced padding for mobile */
  }
}
