/* Join Team Section Container */
.joinTeam {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1247px;
  margin: 144px auto 180px;
  position: relative;
  margin: 160px auto 80px; /* Consistent top and bottom margin */
  gap: 80px; /* Space between content and testimonial */
  padding: 0 20px;
  transition: padding 0.3s ease;
}

/* Content Container */
.content {
  flex: 1;
  position: relative;
  z-index: 1;
  padding-right: 20px;
}

.title {
  color: #1e1e1e;
  font: 500 50px/1.2 'Inter', sans-serif;
  margin-bottom: 20px;
}

.description {
  color: #1e1e1e;
  font: 400 20px/1.5 'Inter', sans-serif;
  margin-top: 32px;
  max-width: 80%;
}

.ellipseBackground {
  position: absolute;
  width: 425px;
  height: 425px;
  left: calc(50% - 212.5px); 
  top: -30px; 
  background: #ECFF9E;
  filter: blur(47.5px);
  border-radius: 50%;
  z-index: -1;
  transition: padding 0.3s ease; /* Adds smooth padding transition */
}


.testimonialSection {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1247px;
  margin: 144px auto 0;
  position: relative;
  gap: 100px; /* Adds space between content and card */
  transition: padding 0.3s ease; /* Adds smooth padding transition */
  padding: 0 20px; /* Adds side padding */
}

.testimonialContent {
  width: 50%;
  position: relative;
  z-index: 1;
  padding-right: 20px; /* Adds right padding */
}

.testimonialTitle{
  max-width: 80%;
}

.ellipseBackground {
  position: absolute;
  width: 425px;
  height: 425px;
  left: calc(60% - 350.5px); 
  top: -30px; 
  background: #ECFF9E;
  filter: blur(47.5px);
  border-radius: 50%;
  z-index: -1;
  transition: padding 0.3s ease; /* Adds smooth padding transition */
}

.testimonialContent h2 {
  color: #1e1e1e;
  font: 500 50px/1.2 'Inter', sans-serif; /* Slightly smaller font */
}

.testimonialContent p {
  color: #1e1e1e;
  font: 400 20px/1.5 'Inter', sans-serif; /* Slightly smaller font */
  margin-top: 32px; /* Reduced top margin */
}

.testimonialCard {
  width: 50%;
  border-radius: 10px;
  background: linear-gradient(135deg, #e5ff9b, #d4e77e);
  padding: 46px 50px 19px;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, padding 0.3s ease;
}

.testimonialCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
}

.quoteOpenIcon,
.quoteCloseIcon {
  width: 52px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.quoteOpenIcon:hover,
.quoteCloseIcon:hover {
  opacity: 1;
}

.quoteCloseIcon {
  position: absolute;
  right: 20px;
  bottom: 100px;
}

blockquote {
  color: #1e1e1e;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 22px; /* Adjusted font size for better readability */
  line-height: 1.6;
  margin: 11px 0 0;
}

.testimonialCard footer {
  margin-top: 69px;
}

.testimonialCard cite {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #333;
  font-size: 20px;
}

.testimonialCard footer p {
  margin-top: 9px;
  font-family: 'Inter', sans-serif;
  color: #777;
  font-size: 18px;
}

.testimonialNav {
  display: flex;
  gap: 14px;
  margin-top: 35px;
}

.navDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #1e1e1e;
  background-color: transparent;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.navDot:hover {
  background-color: #c5ce5b;
  transform: scale(1.2);
}

.navDot.active {
  background-color: #1e1e1e;
}

@media (max-width: 991px) {
  .testimonialSection {
    flex-direction: column; /* Stack elements vertically */
    margin-top: 40px;
    padding: 30px; /* Adds consistent padding */
    gap: 100px; /* Adds more space between elements */

  }

  .testimonialContent,
  .testimonialCard {
    width: 80%; /* Full width for mobile */
  }

  .testimonialContent {
    padding: 0 15px; /* Adds side padding for better spacing */
    margin-left: 30px;
    max-width: 70%;
  }

  .testimonialContent h2 {
    font-size: 32px; /* Reduced font size for mobile */
    line-height: 1.3;
  }

  .testimonialContent p {
    font-size: 16px; /* Reduced font size for mobile */
    margin-top: 20px; /* Adjusts spacing */
  }

  .ellipseBackground {
    width: 300px; /* Adjusted size for mobile */
    height: 300px; 
    left: 40%; /* Centers background */
    top: 0; /* Aligns with content */
    transform: translateX(-50%);
  }

  .testimonialCard {
    margin-top: 20px; /* Adds spacing between content and card */
    padding: 25px; /* Adjust padding for a more compact layout */
  }

  blockquote {
    font-size: 18px; /* Further reduced font size for better fit */
    line-height: 1.4;
  }

  .testimonialCard footer {
    margin-top: 40px; /* Adjusts spacing for mobile */
  }

  .testimonialCard cite {
    font-size: 18px; /* Adjusted font size for mobile */
  }

  .testimonialCard footer p {
    font-size: 16px; /* Adjusted font size for mobile */
    margin-top: 8px;
  }

  .navDot {
    width: 10px; /* Smaller dot size for mobile */
    height: 10px;
    margin-top: 20px; /* Adds more spacing */
  }
}

@media (max-width: 600px) {
  .testimonialSection {
    padding: 15px; /* Reduces padding for smaller screens */
  }

  .testimonialContent h2 {
    font-size: 28px; /* Further reduced font size */
  }

  .testimonialContent p {
    font-size: 14px; /* Smaller font for better fit */
  }

  .testimonialCard {
    padding: 20px; /* Adjusted padding */
  }

  blockquote {
    font-size: 16px; /* Further reduced font size */
  }

  .navDot {
    width: 8px; /* Smaller dot size */
    height: 8px;
  }
}
