.allianceStandard {
  margin-top: 109px;
  width: 100%;
  max-width: 1281px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
}

.allianceStandardContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 60px;
}

.title {
  color: #3b5a91;
  font-size: 48px;
  line-height: 54px;
  font-weight: 500;
  margin-right: 20px;
  flex: 1;
}

.description {
  color: #3b5a91;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  flex: 2;
}

.standardsAndImage {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 60px;
}

.standardsContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex: 1;
}

.standardItem {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}

.standardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.standardTitle {
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
  color: #1e1e1e;
  margin-right: 20px;
}

.standardIcon {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.standardDescription {
  font-size: 18px;
  line-height: 25px;
  font-weight: 300;
  color: #1e1e1e;
  margin-top: 20px;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.teamImage {
  width: 100%;
  max-width: 500px;
  border-radius: 0 160px 0 160px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  flex: 1;
  margin-left: 40px;
}

/* MOBILE VIEW STYLES */
@media (max-width: 991px) {
  .allianceStandard {
    margin-top: 60px;
    padding: 0 10px;
  }

  .allianceStandardContent {
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-size: 30px;
    line-height: 42px;
    margin-right: 0;
    max-width: 95%;
    text-align: center;
  }

  .description {
    font-size: 18px;
    line-height: 26px;
    margin-top: 20px;
    max-width: 90%;
    text-align: left;
  }

  .standardsAndImage {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 90%;
    margin-left: 10px;
  }

  .standardsContainer {
    margin-right: 0;
    margin-bottom: 40px;
  }

  .standardTitle {
    font-size: 24px; /* Decreased font size for mobile */
    line-height: 30px;
    margin-right: 10px;
  }

  .standardIcon {
    width: 30px; /* Reduced size for icons */
    height: 30px;
  }

  .standardDescription {
    font-size: 16px; /* Adjusted font size for readability */
    line-height: 22px;
    padding: 10px 0; /* Added padding for better spacing */
  }

  .teamImage {
    max-width: 100%;
    margin-left: 0;
    margin-top: 40px;
    width: 90%; /* Ensure the image fits within the screen */
  }

  .standardItem {
    padding-bottom: 15px; /* Adjust padding to reduce spacing */
    border-bottom: 1px solid #eee; /* Subtle separation */
  }
}
