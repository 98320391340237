.ourStory {
  background-color: #e3ecf6;
  border-radius: 10px;
  padding: 82px 80px;
  margin-top: -10px; /* Use negative margin to overlap the separator */
  margin-bottom: -10px;
  width: 100%;
  max-width: 1360px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  position: relative;
}

.title {
  color: #3b5a91;
  font: 500 64px/1 Montserrat, sans-serif;
  flex: 1 1 40%;
  max-width: 400px;
  padding-right: -10px; /* Reduced padding */
  margin-bottom: 5px; /* Optionally, add a small bottom margin */
}

.description {
  color: #3b5a91;
  font: 400 22px/31px Inter, sans-serif;
  flex: 1 1 60%;
  max-width: 700px;
  padding-left: 0; /* Removed padding to reduce space */
  margin-top: 0; /* Reduced margin to close the gap */
}

.subtitle {
  color: #4e87c7;
  font: 500 40px/1 Montserrat, sans-serif;
  margin-top: 40px;
  margin-bottom: 0px;
  text-align: center;
  width: 100%;
}

.videoWrapper {
  position: relative;
  border-radius: 10px;
  min-height: 600px;
  width: 100%;
  max-width: 1204px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
 margin-right: 40px;
}

.youtubeVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 40px); /* Subtract 40px for 20px padding on each side */
  height: calc(100% - 40px); /* Subtract 40px for 20px padding on top and bottom */
  padding: 20px; /* Padding around the video */
  border: none;
  box-sizing: border-box; /* Ensures padding is included within dimensions */
}


.gradientOverlay {
  position: absolute;
  top: -80px;
  left: -60px;
  width: 400px;
  height: 400px;
  background-image: url('../../Assets/Gradients/Vector.png');
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.8;
  border-radius: 20px;
  z-index: -1;
}

/* Media Queries */
@media (max-width: 991px) {

  .title, .description {
    max-width: 100%;
    padding: 0;
    text-align: center;
  }

  .title {
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 15px;
  }

  .description {
    font-size: 18px;
    line-height: 1.5;
    margin-top: 15px;
  }

  .subtitle {
    font-size: 28px;
    margin-top: 30px;
    text-align: center;
  }

  .ourStory {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align content */
    justify-content: center; /* Center-align vertically */
    padding: 30px 10px;
    max-width: 100%; /* Full-width */
    gap: 20px;
  }

  .videoWrapper {
    display: flex;
    align-items: center; /* Center-align content vertically */
    justify-content: center; /* Center-align content horizontally */
    width: 100%; /* Occupy full width */
    max-width: 90%; /* Optional: Limit max width */
    min-height: 350px; /* Ensure minimum height */
    box-sizing: border-box; /* Include padding and borders in width */
  }

  .youtubeVideo {
    width: 100%; /* Adjust video to fill container */
    height: 100%; /* Adjust height */
  }

  .gradientOverlay {
    top: -20px;
    left: -20px;
    width: 200px;
    height: 200px;
    opacity: 0.7;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .ourStory {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align content horizontally */
    justify-content: center; /* Center-align content vertically */
    padding: 0; /* Remove extra paddings */
    gap: 30px; /* Consistent spacing between items */
    max-width: 100%; /* Ensure it spans the full width */
  }

  .videoWrapper {
    display: flex;
    align-items: center; /* Vertically center the video */
    justify-content: center; /* Horizontally center the video */
    width: 100%; /* Use full width */
    max-width: 80%; /* Limit the maximum width for better aesthetics */
    min-height: 450px; /* Slightly increase the height for iPad Pro */
    box-sizing: border-box; /* Ensure padding and borders are included */
  }

  .youtubeVideo {
    width: 100%; /* Stretch video to fill container */
    height: 100%; /* Stretch height to match container */
    border-radius: 10px; /* Add rounding for a polished look */
  }
}


@media (max-width: 600px) {
  .ourStory {
    padding: 20px 15px;
    gap: 15px;
    margin-top: 0px;
    align-items: center; /* Center the content in .ourStory */
  }

  .title {
    font-size: 32px;
    line-height: 1.2;
    margin-bottom: 10px;
    text-align: center;
  }

  .description {
    font-size: 16px;
    line-height: 1.4;
    margin-top: 10px;
    text-align: left;

  }

  .subtitle {
    font-size: 24px;
    margin-top: 25px;
    text-align: center;
  }

  .videoWrapper {
    min-height: 250px;
    width: 100%;
    max-width: 90%; /* Limit width for better alignment */
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    margin: 0 auto; /* Center the .videoWrapper */
  }

  .youtubeVideo {
    width: 100%;
    height: 100%;
  }

  .gradientOverlay {
    top: -10px;
    left: -10px;
    width: 120px;
    height: 120px;
    border-radius: 10px;
  }
}

/* Tablet Mode (up to 991px) */
@media (max-width: 991px) {
  .ourStory {
    padding: 30px 10px; /* Reduced padding */
    max-width: 100%; /* Occupies full screen width */
    margin-top: -10px;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .title, .description {
    max-width: 90%;
    padding: 0;
    text-align: left;
  }

  .title {
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 15px;
  }

  .description {
    font-size: 18px;
    line-height: 1.5;
    margin-top: 15px;
  }

  .subtitle {
    font-size: 28px;
    margin-top: 30px;
    text-align: center;
  }

  .videoWrapper {
    min-height: 350px;
    max-width: 90%; /* Full width on tablet */
  }

  .gradientOverlay {
    top: -20px;
    left: -20px;
    width: 200px;
    height: 200px;
    opacity: 0.7;
  }
}

/* Large Screen Mode (1200px and above) */
@media (min-width: 1200px) {
  .ourStory {
    padding: 60px 40px; /* Adjusted padding for large screens */
    max-width: 100%; /* Occupies full screen width */
    margin-top: -10px;
    gap: 40px;
    align-items: flex-start; /* Align content to the start */
    justify-content: center;
  }

  .title {
    font-size: 64px;
    line-height: 1;
    margin-bottom: 20px;
  }

  .description {
    font-size: 24px;
    line-height: 1.6;
    max-width: 800px; /* Allows some width restriction on larger screens */
    padding-left: 0; /* No padding on left */
  }

  .subtitle {
    font-size: 32px;
    margin-top: 40px;
    text-align: center;
  }

  .videoWrapper {
    min-height: 600px;
    max-width: 100%; /* Occupies full width on large screens */
  }

  .gradientOverlay {
    top: -80px;
    left: -60px;
    width: 400px;
    height: 400px;
    opacity: 0.8;
  }
}
