.ctaSection {
  text-align: center;
  padding: 80px 20px;
  background-color: #ffffff;
}

.ctaTitle {
  position: relative;
  color: #1e1e1e;
  text-align: center;
  font: 400 60px/72px Montserrat, sans-serif;
  margin: 80px auto;
  max-width: 54%;
  padding: 60px 40px;
  z-index: 2;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.ctaTitle::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 500px;
  background: url('../../Assets/Gradients/CareersGradientVector.png') no-repeat center center;
  background-size: contain;
  opacity: 0.8;
  z-index: 1;
  border-radius: 120px;
  transition: opacity 0.3s ease;
}

.ctaTitle:hover {
  transform: scale(1.02);
  opacity: 0.95;
}

.ctaTitle:hover::before {
  opacity: 1;
}

@media (max-width: 991px) {
  .ctaTitle {
    margin: 40px auto;
    font-size: 40px;
    line-height: 53px;
    padding: 40px 20px;
    max-width: 78%;
  }
  
  .ctaTitle::before {
    width: 500px;
    height: 400px;
    border-radius: 80px;
  }
}



@media (max-width: 991px) {
  .ctaTitle {
    margin: 40px auto;
    font-size: 40px;
    line-height: 53px;
  }
}


@media (max-width: 991px) {
  .ctaSection {
    padding: 40px 20px;
  }

  .ctaTitle {
    font-size: 40px;
    line-height: 52px;
  }
}