/* General Job Listings Styling */
.jobListings {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px;
  max-width: 1360px;
  margin: 0 auto;
}

.jobListingsContent {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns for header and description */
  gap: 60px; /* Proper gap between columns */
  align-items: start;
  width: 100%;
  margin-bottom: 40px;
}

.jobListingsHeader {
  text-align: left;
  max-width: 500px; /* Aligns title and subtitle with the image */
  
}

.jobListingsTitle {
  font-size: 48px;
  line-height: 1.1;
  color: #3b5a91;
  margin-bottom: 20px;
}

.jobListingsSubtitle {
  font-size: 24px;
  color: #1e1e1e;
  margin-bottom: 10px;
}

.jobListingsDescription {
  font-size: 18px;
  line-height: 1.6;
  text-align: justify;
  margin-top: 10px;
  color: #1e1e1e;
  max-width: 500px; /* Matches the width of job postings below */
}

.jobPositions {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns for image and job positions */
  gap: 60px; /* Proper gap between image and positions list */
  align-items: start;
  width: 100%;
}

.teamMemberContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.teamMemberImage {
  width: 100%;
  max-width: 500px;
  border-radius: 0; /* Remove white background shape */ 
  margin-bottom: 40px; /* Ensures equal spacing below the image */
}

/* Positions List */
.positionsList {
  width: 100%;
  max-width: 500px; /* Ensure it aligns with the description width */
  display: flex;
  flex-direction: column;
  gap: 16px; /* Maintain the original gap */
  list-style-type: none;
  padding: 0;
}

.positionItem {
  border-top: 1px solid rgba(30, 30, 30, 0.4);
  padding: 20px 0 10px; /* Consistent padding */
  transition: max-height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
  position: relative;
}

.positionItem:not(.expanded) {
  padding-bottom: 0;
  border-bottom: none;
}

.positionTitle {
  font-size: 26px;
  font-weight: 600;
  color: #1e1e1e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
  max-width: 480px; /* Ensure alignment with the image width */
}

.positionTitle:hover {
  color: #3b5a91;
}

.positionIcon {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.positionIcon:hover {
  transform: rotate(180deg);
  opacity: 0.8;
}

.positionDescription {
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
  color: #1e1e1e;
  margin-top: 10px;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.positionItem.expanded .positionDescription {
  max-height: 300px;
  opacity: 1;
}

.readDetailsButton {
  background-color: #3b5a91;
  color: #fff;
  border: none;
  border-radius: 64px;
  padding: 12px 24px;
  font-size: 18px;
  font-family: 'Darker Grotesque', sans-serif;
  font-weight: 700;
  cursor: pointer;
  display: none; /* Hidden by default */
  align-items: center;
  gap: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 20px;
}

.positionItem.expanded .readDetailsButton {
  display: flex;
}

.readDetailsButton:hover {
  background-color: #2c4770;
}

.buttonIcon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
}

.readDetailsButton:hover .buttonIcon {
  transform: translateX(5px);
}

/* Tablet View (768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .jobListingsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
  }

  .jobPositions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .teamMemberImage {
    max-width: 450px;
    margin: 0 auto 40px; /* Ensures equal spacing above and below */
  }
}

/* iPad Pro View (980px to 1366px) - Matches Desktop */
@media (min-width: 980px) and (max-width: 1366px) {
  .jobListingsContent {
    grid-template-columns: 1fr 1fr; /* Desktop-like layout */
    gap: 60px;
  }

  .jobPositions {
    grid-template-columns: 1fr 1fr;
    gap: 60px;
  }

  .teamMemberImage {
    max-width: 500px;
    margin: 0;
  }

  .jobListingsTitle,
  .jobListingsSubtitle {
    text-align: left;
  }
}

/* Mobile View (Below 768px) */
@media (max-width: 767px) {
  .jobListingsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
  }

  .jobPositions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .teamMemberImage {
    max-width: 300px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 40px; /* Ensures equal spacing above and below */
  }

  .positionsList {
    width: 100%;
    max-width: 90%;
    gap: 16px;
  }

  .positionItem {
    padding: 20px 10px;
    border-top: 1px solid rgba(30, 30, 30, 0.4);
  }

  .positionTitle {
    font-size: 22px; /* Increased for better visibility */
    max-width: 100%; /* Aligns with mobile view width */
  }

  .positionDescription {
    font-size: 16px;
    margin-top: 8px;
  }

  .readDetailsButton {
    margin-top: 10px;
    font-size: 14px;
    padding: 10px 16px;
  }
}
 