.aboutUsContainer {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .separator,
  .downSeparator {
      width: 100vw; /* Full width of the viewport */
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      object-fit: cover;
      margin: 0; /* Remove any margin */
  }
  
  .downSeparator {
    margin-top: 0;
      margin-bottom: -1px; /* Negative margin to eliminate any gap */
  }
 
  @media (max-width: 991px) {
    .aboutUsContainer {
      max-width: 100%;
    }
  }