@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Start a bit lower */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}

.mainContainer {
  background-color: #e3ecf6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 70px 79px;
}

.contentWrapper {
  max-width: 1278px;
  width: 100%;
}

.headerSection {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  padding: 0 10px;
}

.mainTitle {
  color: #3b5a91;
  font: 500 60px/63px Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
  max-width: 45%;
  text-align: left;
  align-self: flex-start;
  animation: fadeIn 1s ease forwards; /* Fade-in animation */
  transition: color 0.3s ease; /* Smooth color change on hover */
}

.mainTitle:hover {
  color: #5f85c4; /* Slight color change on hover */
}

.mainDescription {
  color: #3b5a91;
  font: 400 18px/29px Inter, -apple-system, Roboto, Helvetica, sans-serif;
  max-width: 50%;
  text-align: left;
  align-self: flex-start;
  margin-top: 60px; /* Lowered as specified */
  animation: fadeIn 1.5s ease forwards; /* Fade-in with a slight delay */
  transition: color 0.3s ease; /* Smooth color change on hover */
}

.mainDescription:hover {
  color: #5f85c4; /* Slight color change on hover */
}

.servicesGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  row-gap: 20px; /* Space between rows */
  column-gap: 20px; /* Space between columns */
  max-width:  100%; /* Center within the container if needed */
  margin-top: 40px;
  justify-items: stretch; /* Make items fill their space */
}

.serviceCard {
  background-color: #f8f9fa; /* Adjust background color as needed */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add a shadow for depth */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px; /* Adjust padding for consistent spacing */
  transition: transform 0.3s ease; /* Slight animation on hover */
}

/* Tablet View: Two Columns */
@media (max-width: 991px) and (min-width: 601px) {
  .mainContainer {
    padding: 40px 15px; /* Reduce padding for better fit */
  }

  .headerSection {
    flex-direction: column;
    align-items: center; /* Center align for tablet view */
    text-align: center; /* Center the text */
  }

  .mainTitle,
  .mainDescription {
    max-width: 100%;
    text-align: center; /* Center the text */
    margin-top: 20px; /* Reduce space for tablet */
    margin-bottom: 40px;
  }

  .mainTitle {
    font-size: 34px; /* Adjust font size for tablet */
    line-height: 42px;
  }

  .mainDescription {
    font-size: 16px; /* Adjust font size for better readability */
    line-height: 24px; /* Adjust line height for tablet */
    margin-top: -30px; 
  }

  .servicesGrid {
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 20px; /* Consistent spacing */
    max-width: 1200px; /* Center the grid */
    margin: 0 auto; /* Center the grid */
    justify-content: center; /* Ensure items align in the center */
  }

  .serviceCard {
    max-width: 100%; /* Ensure cards fit the grid */
    margin: 0; /* Remove unnecessary margins */
  }
}

/* Mobile View: One Column */
@media (max-width: 600px) {
  .mainContainer {
    padding: 30px 15px; /* Adjust padding for smaller screens */
  }

  .headerSection {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .mainTitle {
    font-size: 28px; /* Smaller font size for smaller screens */
    line-height: 36px;
    max-width: 100%;
    margin-bottom: -40px;
  }

  .mainDescription {
    font-size: 14px; /* Smaller font size for readability */
    line-height: 20px; /* Adjust line height for smaller screens */
    max-width: 100%;
  }

  .servicesGrid {
    grid-template-columns: 1fr; /* Single column on mobile */
    gap: 20px; /* Consistent spacing */
    margin-top: 20px; /* Adjust top margin */
    width: 100%; /* Ensure full width */
  }
}


@media (max-width: 1366px) and (min-width: 1024px) {


.mainDescription {
  color: #3b5a91;
  font: 400 23px/29px Inter, -apple-system, Roboto, Helvetica, sans-serif;
  max-width: 50%;
  text-align: left;
  align-self: flex-start;
  margin-top: 60px; /* Lowered as specified */
  animation: fadeIn 1.5s ease forwards; /* Fade-in with a slight delay */
  transition: color 0.3s ease; /* Smooth color change on hover */
}
}
