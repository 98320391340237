.privacyPolicyContainer {
  max-width: 800px; /* Reduce width for better centering */
  margin: 0 auto;
  padding: 40px 20px; /* Add consistent left and right padding */
  font-family: 'Inter', sans-serif;
  color: #1e1e1e;
  position: relative;
}

.title {
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
  font-family: 'Montserrat', sans-serif;
}

.section {
  margin-bottom: 40px;
}

.subtitle {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 20px;
}

.text {
  font-size: 18px;
  line-height: 1.8; /* Slightly increased for readability */
  margin-bottom: 20px;
}

.backToTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 12px 18px;
  background-color: #3b5a91;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, transform 0.3s;
}

.backToTopButton.show {
  opacity: 1;
  visibility: visible;
}

.backToTopButton:hover {
  background-color: #2c4770;
  transform: translateY(-3px);
}

.footer {
  height: 200px;
  background-color: #e3ecf6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  font-size: 18px;
  color: #1e1e1e;
}

/* Tablet and Mobile Adjustments */
@media (max-width: 991px) {
  .privacyPolicyContainer {
    max-width: 95%; /* Ensure content is well-contained */
    padding: 30px 25px; /* Consistent padding for better spacing */
    margin-left: 20px;
    margin-right: 20px;
  }

  .title {
    font-size: 36px;
    margin-bottom: 30px;
  }

  .subtitle {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .text {
    font-size: 16px;
    line-height: 1.6; /* Adjusted for readability */
    margin-bottom: 15px;
  }

  .backToTopButton {
    bottom: 10px;
    right: 10px;
    padding: 10px 15px;
  }
}

@media (max-width: 600px) {
  .privacyPolicyContainer {
    max-width: 100%; /* Occupy full width for mobile */
    padding: 20px 10px; /* Add padding for better spacing */
  }

  .title {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .text {
    font-size: 14px;
    line-height: 1.5; /* Adjusted for smaller screens */
    margin-bottom: 10px;
  }

  .backToTopButton {
    bottom: 8px;
    right: 8px;
    padding: 8px 12px;
    font-size: 14px; /* Smaller font size for the button */
  }
}
