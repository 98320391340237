.servicesSection {
  display: flex;
  margin-top: 146px;
  padding: 0 90px; /* Increase padding for more space on both sides */
 }
  
  .sectionTitle {
    flex: 0 0 30%;
    font: 500 40px/42px Montserrat, sans-serif;
    color: #1e1e1e;
    margin-right: 20px;
    padding: 20px;
    position: relative;
    z-index: 1;
  }
  
  .sectionTitle::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 80%;
    background-image: url('../../Assets/Gradients/bm/bmellipse.png'); /* Replace with your gradient PNG path */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50% / 70%; /* Adjust these values to create an ellipse shape */
    z-index: -1;
    transform: scale(1.2); /* Optional: enlarge to better frame the text */
  }
  
  
  .servicesContainer {
    flex: 0 0 70%;
    background-color: #e5ff9b;
    border-radius: 10px;
    padding: 52px 54px;
    margin-right: 20px; /* Add margin to the right for space */
    margin-left: 20px;
  }
  
  .servicesSubtitle {
    font: 700 22px/31px Inter, sans-serif;
    color: #1e1e1e;
    margin-bottom: 19px;
  }
  
  .servicesList {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
    gap: 20px;
    list-style-type: none;
    padding: 0;
  }
  
  .serviceItem {
    display: flex;
    align-items: center;
    gap: 10px;
    font: 400 18px/28px Inter, sans-serif;
    color: #1e1e1e;
  }
  
  
  .serviceIcon {
    width: 24px;
    height: 24px;
  }
  
  @media (max-width: 991px) {
    .sectionTitle::before {
      width: 250px; /* Adjust width for tablets */
      height: 250px; /* Adjust height for tablets */
      transform: translate(-50%, -50%) scale(1.3); /* Slightly smaller scale */
      top: 50%; /* Keep centered vertically */
      left: 40%; /* Keep centered horizontally */
    }
    .servicesSection {
      flex-direction: column;
      margin-top: 40px;
      padding: 0 20px;
    }
  
    .sectionTitle {
      margin-bottom: 20px;
    }
  
    .servicesContainer {
      padding: 30px 20px;
    }
  }

  @media (max-width: 767px) { /* Adjust breakpoint as needed */
  
  .sectionTitle {
    text-align: center; /* Center the text */
    margin: 0 auto; /* Center the title container */
    position: relative;
    margin-bottom: 30px;
    margin-top: 30px;
    max-width: 100%;
  }
  .sectionTitle::before {
    width: 400px; /* Adjust width for mobile */
    height: 400px; /* Adjust height for mobile */
    transform: translate(-50%, -50%) scale(1); /* Keep original size */
    top: 40%; /* Keep centered vertically */
    left: 50%; /* Keep centered horizontally */
  }
    .servicesList {
      grid-template-columns: 1fr; /* Single column on smaller screens */
    }
  }
  