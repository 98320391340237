body {
    overflow-x: hidden;
}

.info-section {
    border: 0px solid red;
    display: flex;
    flex-direction: row;
    min-height: 70vh;
    max-width: 100vw;
    background-color: #EFF1F7;
    padding: 70px 90px 70px 90px;
}

.info-section .info-left {
    border: 0px solid red;
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: left;
    justify-content: space-center;
    row-gap: 20px;
    padding-top: 50px;
}

.info-section .info-left > * {
    margin: 0;
}

.info-left .info-category {
    border: 0px solid red;
    font-family: Inter, sans-serif;
    font-size: 19px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #0074AD;
}

.info-left .info-title {
    border: 0px solid red;
    font-family: Montserrat, sans-serif;
    font-size: 56px;
    font-weight: 400;
    line-height: 56px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1E1E1E;
}

.info-left .info-date {
    border: 0px solid red;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #484848;
}

.info-left .info-description {
    border: 0px solid red;
    font-family: Inter, sans-serif;
    font-size: 22px;
    font-weight: 300;
    line-height: 40.56px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1E1E1E;
    margin-top: 25px;
}

.info-section .info-right {
    border: 0px solid red;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.info-section .info-right img {
    width: 680px;
    height: 536px;
    top: 170px;
    left: 721px;
    gap: 0px;
    border-radius: 0px 160px 0px 160px; 
}

.content-section {
    font-family: Inter, sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 30.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background-color: #EFF1F7;
    margin-top: -22px;
    padding: 0px 450px 100px 450px;
}

.related-section h1 {
    font-family: Inter, sans-serif;
    font-size: 65px;
    font-weight: 300;
    line-height: 70px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.content-section img {
    max-width: 100%;
    object-fit: contain;
}

@media screen and (max-width: 1300px) {
    header {
        max-width: 100vw;
    }
    .info-section {
        flex-direction: column;
        width: 100vw;
        row-gap: 25px;
        row-gap: 20px;
        margin-top: -30px;
        padding: unset;
        padding-top: 40px;
    }

    .info-section .info-left {
        border: 0px solid red;
        align-items: center;
        row-gap: 0px;
        width: 100vw;
        padding: unset;
        margin: unset;
        padding: 25px 35px;
        box-sizing: border-box;
    }

    .info-left .info-category {
        font-size: 17px;
    }

    .info-left .info-title {
        font-size: 24px;
        text-align: center;
        line-height: 40px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .info-left .info-date {
        font-size: 17px;
    }

    .info-left .info-description {
        font-size: 22px;
    }

    .info-section .info-right {
        width: 100vw;
    }

    .info-section .info-right img {
        width: 90vw;
        height: 40vh;
        object-fit: cover;
    }

    .content-section {
        display: flex;
        flex-direction: column;
        margin: 0;
        max-width: 100vw;
        padding: unset;
        padding: 25px 35px 70px 35px;
        box-sizing: border-box;
        margin-top: -35px;
    }

    .related-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 40px;
    }

    .related-section h1 {
        font-size: 40px;
    }

    .blog-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 25px;
    }

    .blog-card > * {
        margin: 0;
        padding: 0;
        height: min-content;
    }

    .blog-card .blog-title {
        font-size: 20px;
        margin: 0;
        height: unset;
    }

    .blog-card .blog-description {
        width: 93%;
        font-size: 20px;
        padding: unset;
    }
}