/* Mobile Header styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px; /* Reduced padding for better fit on smaller screens */
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Montserrat', sans-serif;
}

/* Adjust header background color on scroll */
.scrolled {
    background-color: #3b5a91;
    transition: background-color 0.3s ease;
}

body {
    padding-top: 50px; /* Adjust as needed */
}

/* Container to hold logo and hamburger icon */
.logoAndHamburger {
    display: flex;
    align-items: center;
}

.hamburgerIcon {
    font-size: 32px;
    color: #3b5a91;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.scrolled .hamburgerIcon {
    color: #fff;
}

/* Center logo */
.logo {
    width: 130px; /* Smaller size for better fit on mobile */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

/* Expanded mobile menu logo */
.expandedMenuLogo {
    position: absolute;
    top: 20px;
    left: 15px;
    width: 140px; /* Adjusted for optimal fit */
    cursor: pointer;
    filter: brightness(0) invert(1);
}

/* Mobile full-screen menu */
.mobileMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #3b5a91;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 1000;
    padding-top: 40px; /* Padding to avoid overlapping */
}

/* Mobile navigation styling */
.mobileNav {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    gap: 25px;
    font-size: 2rem; /* Adjusted for smaller screens */
}

.mobileNav li {
    margin: 20px 0;
}

/* Close icon */
.closeIcon {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 45px;
    cursor: pointer;
}

/* Contact button in expanded menu */
.contactButton {
    margin-top: 30%; /* Adjusted for more compact layout */
    border-radius: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;
    gap: 12px;
    color: #3b5a91;
    padding: 6px 15px;
    font: 700 18px/4px "Darker Grotesque", sans-serif;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    overflow: hidden;
    position: relative;
    text-decoration: none !important;
}

.contactButton:hover {
    background-color: #2e4972;
}

.buttonIcon {
    width: 16px;
    height: 16px;
    margin-left: 10px;
}

.phoneNumber {
    margin-top: 5%; /* Reduced for more compact layout */
    font: 700 18px/4px "Darker Grotesque", sans-serif;
    text-align: center;
}

/* Horizontal line styling */
.horizontalLine {
    width: 70%;
    margin: 30px auto 15px auto;
    border: none;
    border-top: 1px solid #ffffff;
    opacity: 0.6;
}

/* Social Icons styling */
.socialIcons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 15px;
}

.socialIcons img {
    width: 20px; /* Smaller for compact layout */
    height: 20px;
    filter: brightness(0) invert(1);
    transition: transform 0.2s ease;
    cursor: pointer;
}

.socialIcons img:hover {
    transform: scale(1.1);
}


.phoneLink {
    color: inherit; /* Keep it the same color as surrounding text */
    text-decoration: none;
}

.phoneLink:hover {
    text-decoration: underline; /* Optional underline effect on hover */
}
