.container {
    background: rgba(239, 241, 247, 1);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 113px 80px;
  }
  
  @media (max-width: 991px) {
    .container {
      max-width: 100%;
      padding: 100px 20px;
    }
  }
  
  .contentWrapper {
    display: flex;
    width: 828px;
    max-width: 100%;
    flex-direction: column;
    margin: 0 40px -24px;
  }
  
  @media (max-width: 991px) {
    .contentWrapper {
      margin: 0 10px 10px;
      width: 100%;
    }
  }
  
  .headerContainer {
    display: flex;
    width: 100%;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  @media (max-width: 991px) {
    .headerContainer {
      flex-direction: column;
      gap: 20px;
      max-width: 100%;
    }
  }
  
  .jobTitle {
    color: rgba(30, 30, 30, 1);
    font: 400 50px/1 Montserrat, -apple-system, Roboto, Helvetica, sans-serif; /* Reduced font size */
    margin: 0;
  }
  
  @media (max-width: 991px) {
    .jobTitle {
      font-size: 36px; /* Smaller font for mobile */
      text-align: center;
    }
  }
  
  .applyButton {
    border-radius: 64px;
    background-color: #3b5a91;
    display: flex;
    align-items: center;
    gap: 16px; /* Space between text and icon */
    color: #fff;
    padding: 12px 24px; /* Ensure even padding around */
    font: 700 20px "Darker Grotesque", sans-serif;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    overflow: hidden;
    text-decoration: none !important;
    line-height: 1; /* Ensures consistent vertical alignment */
}

.applyButton:hover {
    background-color: #2c4770;
}

.scrolled .applyButton {
    background-color: #fff;
    color: #3b5a91;
}

.buttonIcon {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
    display: inline-block;
    vertical-align: middle;
    margin-left: 12px; 
}

.applyButton:hover .buttonIcon {
    transform: translateX(5px);
}

.scrolled .buttonIcon {
    width: 22px;
    height: 22px;
}

  
  .mainContent {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    align-items: flex-start;
    color: rgba(30, 30, 30, 1);
    padding: 0 80px 0 10px;
    font: 400 18px/1.5 Inter, sans-serif; /* Reduced font size */
  }
  
  @media (max-width: 991px) {
    .mainContent {
      max-width: 85%;
      padding: 0 15px;
      margin-top: 40px;
    }
  }
  
  .sectionTitle {
    margin-top: 38px;
    font: 700 24px Montserrat, -apple-system, Roboto, Helvetica, sans-serif; /* Reduced font size */
  }
  
  .sectionContent {
    line-height: 28px; /* Adjusted line height */
    margin-top: 12px;
    font-size: 16px; /* Reduced font size */
  }
  
  .boldText {
    font-weight: 700;
  }
  
  @media (max-width: 991px) {
    .sectionContent {
      max-width: 100%;
    }
  }
  
  @media (max-width: 600px) {
    .headerContainer {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  
    .jobTitle {
      font-size: 30px; /* Further reduced for mobile */
      text-align: center;
    }
  
    .applyButton {
      padding: 8px 16px; /* Reduced padding */
      font-size: 16px; /* Smaller font size */
    }
  
    .mainContent {
      font-size: 14px;
      line-height: 1.4;
      padding: 0 15px;
    }
  
    .sectionTitle {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  